"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base64url_1 = require("base64url");
const cryptoClients_1 = require("./cryptoClients");
const errors_1 = require("./errors");
const sha256_1 = require("./cryptoClients/sha256");
class TokenVerifier {
    constructor(signingAlgorithm, rawPublicKey) {
        if (!(signingAlgorithm && rawPublicKey)) {
            throw new errors_1.MissingParametersError('a signing algorithm and public key are required');
        }
        if (typeof signingAlgorithm !== 'string') {
            throw 'signing algorithm parameter must be a string';
        }
        signingAlgorithm = signingAlgorithm.toUpperCase();
        if (!cryptoClients_1.cryptoClients.hasOwnProperty(signingAlgorithm)) {
            throw 'invalid signing algorithm';
        }
        this.tokenType = 'JWT';
        this.cryptoClient = cryptoClients_1.cryptoClients[signingAlgorithm];
        this.rawPublicKey = rawPublicKey;
    }
    verify(token) {
        if (typeof token === 'string') {
            return this.verifyCompact(token, false);
        }
        else if (typeof token === 'object') {
            return this.verifyExpanded(token, false);
        }
        else {
            false;
        }
    }
    verifyAsync(token) {
        if (typeof token === 'string') {
            return this.verifyCompact(token, true);
        }
        else if (typeof token === 'object') {
            return this.verifyExpanded(token, true);
        }
        else {
            return Promise.resolve(false);
        }
    }
    verifyCompact(token, async) {
        // decompose the token into parts
        const tokenParts = token.split('.');
        // calculate the signing input hash
        const signingInput = tokenParts[0] + '.' + tokenParts[1];
        const performVerify = (signingInputHash) => {
            // extract the signature as a DER array
            const derSignatureBuffer = this.cryptoClient.loadSignature(tokenParts[2]);
            // verify the signed hash
            return this.cryptoClient.verifyHash(signingInputHash, derSignatureBuffer, this.rawPublicKey);
        };
        if (async) {
            return sha256_1.hashSha256Async(signingInput).then(signingInputHash => performVerify(signingInputHash));
        }
        else {
            const signingInputHash = sha256_1.hashSha256(signingInput);
            return performVerify(signingInputHash);
        }
    }
    verifyExpanded(token, async) {
        const signingInput = [
            token['header'].join('.'),
            base64url_1.default.encode(token['payload'])
        ].join('.');
        let verified = true;
        const performVerify = (signingInputHash) => {
            token['signature'].map((signature) => {
                const derSignatureBuffer = this.cryptoClient.loadSignature(signature);
                const signatureVerified = this.cryptoClient.verifyHash(signingInputHash, derSignatureBuffer, this.rawPublicKey);
                if (!signatureVerified) {
                    verified = false;
                }
            });
            return verified;
        };
        if (async) {
            return sha256_1.hashSha256Async(signingInput).then(signingInputHash => performVerify(signingInputHash));
        }
        else {
            const signingInputHash = sha256_1.hashSha256(signingInput);
            return performVerify(signingInputHash);
        }
    }
}
exports.TokenVerifier = TokenVerifier;
