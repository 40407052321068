export type TokenInfo = TokenInfo.SingleTokenInfo | TokenInfo.CombinedTokenInfo

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace TokenInfo {
  export interface SingleTokenInfo {
    type?: "single"

    name: string

    /**
     * @default 0
     */
    precision?: number

    displayName?: string
    description?: string

    iconSrc: string
  }

  export interface CombinedTokenInfo {
    type: "combined"

    name: string

    /**
     * @default 0
     */
    precision?: number

    displayName?: string
    description?: string

    combinedTokens: [SingleTokenInfo, SingleTokenInfo]
  }

  export const getPrecision = (token: TokenInfo): number => {
    return token.precision ?? 0
  }
}
