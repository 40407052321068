import clsx from "clsx"
import { forwardRef, HTMLAttributes, useEffect, useRef, useState } from "react"
import { useCombinedRef } from "../utils/refHelpers"

export const Truncatable = forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement>
>((props, forwardingRef) => {
  const internalRef = useRef<HTMLSpanElement>(null)

  const ref = useCombinedRef(internalRef, forwardingRef)

  const [textContent, setTextContent] = useState("")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      internalRef.current &&
      internalRef.current.textContent !== textContent
    ) {
      setTextContent(internalRef.current.textContent ?? "")
    }
  })

  return (
    <span
      {...props}
      ref={ref}
      className={clsx("truncate", props.className)}
      title={props.title ?? textContent}
    >
      {props.children}
    </span>
  )
})
