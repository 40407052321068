import clsx from "clsx"
import { CSSProperties, FC, forwardRef, ReactNode } from "react"
import { withClassName } from "../utils/reactHelpers/withClassName"
import { Divider } from "./Divider"

export interface CardProps {
  className?: string
  boxClassName?: string
  roundClassName?: string
  backgroundImage?: string
  style?: CSSProperties
  tabIndex?: number
  children?: ReactNode
}
export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => (
  <div
    ref={ref}
    className={clsx(
      "drop-shadow backdrop-blur bg-none",
      props.className,
      props.boxClassName ?? "p-3 sm:p-6",
      props.roundClassName ?? "rounded-2xl",
    )}
    style={{
      ["--tw-drop-shadow" as any]:
        "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05))",

      ["--tw-backdrop-blur" as any]: "blur(40px)",

      backgroundImage:
        props.backgroundImage ??
        "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",

      ...props.style,
    }}
    tabIndex={props.tabIndex}
  >
    {props.children}
  </div>
))

export const CardTitle = withClassName(
  "text-base sm:text-xl leading-7 font-bold text-gray-200",
  "div",
)

export const CardSubTitle = withClassName(
  "text-lg leading-7 font-normal text-gray-200",
  "div",
)

export const CardDivider = withClassName("border-gray-500 opacity-30", Divider)

export const CardNested: FC<CardProps> = props => (
  <Card {...props} boxClassName={props.boxClassName ?? "p-4"} />
)

export const cardInsetBgClassName = "bg-black bg-opacity-20"
export const CardInset = withClassName(
  `${cardInsetBgClassName} rounded-lg px-4 py-6`,
  "div",
)

export const CardInsetTitle = CardSubTitle

export const CardInsetSubTitle = withClassName(
  "text-sm leading-5 font-normal text-gray-400",
  "span",
)

export const CardInsetDivider = CardDivider

export const CardPlate = withClassName(
  `bg-white bg-opacity-5 rounded-lg p-3 sm:p-4`,
  "div",
)
