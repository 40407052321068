/* eslint-disable @typescript-eslint/no-var-requires */

import clsx from "clsx"
import { FC } from "react"

export interface LoadingIndicatorProps {
  className?: string

  /**
   * @default 36
   *
   * The maximum value is 72. If greater than 72, will be forced to 72
   */
  size?: number
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = props => {
  const { size: propSize = 36 } = props
  const size = propSize > 72 ? 72 : propSize

  return (
    <img
      alt={`Loading...`}
      className={clsx("animate-spin", props.className)}
      width={size}
      height={size}
      src={require("./loading.png")}
    />
  )
}
