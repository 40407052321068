import clsx from "clsx"
import { FC, ReactNode, useEffect } from "react"
import { GradientText } from "../../GradientText"
import { NavBackLink } from "../../NavLink"
import { useContextInComponent } from "../_/PageStackContext"
import { PageStackNavBar } from "../_/PageStackNavBar"
import { ReactComponent as ReturnIcon } from "./return.svg"

export interface PageStackPageProps {
  title?: ReactNode
  titleRight?: ReactNode
}

export const PageStackPage: FC<PageStackPageProps> = props => {
  const { pushPage } = useContextInComponent("PageStackPage")

  useEffect(() => {
    return pushPage({
      title: props.title,
      titleRight: props.titleRight,
    })
  }, [props.title, pushPage, props.titleRight])

  return (
    <>
      <PageStackNavBar className={"mb-10"} />
      {props.children}
    </>
  )
}

export const PrimaryPageTitle: FC = props => (
  <GradientText
    className={"text-2xl sm:text-3xl leading-9 font-medium uppercase"}
    gradientStyle={"linear-gradient(90.83deg, #FFFFFF 0.71%, #1D4ED8 99.29%)"}
  >
    {props.children}
  </GradientText>
)

export const returnIconMargin = "0.75rem"
const titleLineHeight = "2.25rem"

export const SecondaryPageTitle: FC<{
  className?: string
  returnFallbackURL?: string
  onClickReturn?: (event: { preventDefault: () => void }) => void
}> = props => {
  const wrapNavLink = (content: ReactNode): ReactNode =>
    props.returnFallbackURL ? (
      <NavBackLink
        fallbackURL={props.returnFallbackURL}
        onClick={props.onClickReturn}
      >
        {content}
      </NavBackLink>
    ) : (
      content
    )

  return (
    <div
      className={clsx("text-3xl font-medium text-white", props.className)}
      style={{ lineHeight: titleLineHeight }}
    >
      {wrapNavLink(
        <>
          <span
            className={"inline-flex items-center justify-center"}
            style={{ marginRight: returnIconMargin, height: titleLineHeight }}
          >
            <ReturnIcon width={24} height={24} fill={"currentColor"} />
          </span>

          {props.children}
        </>,
      )}
    </div>
  )
}
