import clsx from "clsx"
import { FC } from "react"

export const HeadlessButton: FC<{
  className?: string
  disabled?: boolean
  onClick?: () => void
}> = props => {
  return (
    <div
      className={clsx(
        "text-center",
        props.disabled && "pointer-events-none",
        props.onClick && "cursor-pointer",
        props.className,
      )}
      onClick={props.onClick}
      role={"button"}
      aria-disabled={props.disabled}
    >
      {props.children}
    </div>
  )
}
