import clsx from "clsx"
import { FC, PropsWithChildren, ReactNode } from "react"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { readResource, SuspenseResource } from "../../utils/SuspenseResource"
import { Spensor } from "../Spensor"
import { TokenIcon, TokenIconProps } from "../TokenIcon"
import { TokenName } from "../TokenName"
import { Truncatable } from "../Truncatable"

export interface BlockProps extends PropsWithChildren<unknown> {
  className?: string
  boxClassName?: string
  borderClassName?: string
  onClick?: () => void
}

export const Block: FC<BlockProps> = props => (
  <div
    className={clsx(
      "rounded-lg flex flex-col border",
      props.borderClassName ?? "border-gray-700",
      props.boxClassName ?? "p-3 sm:p-6",
      props.className,
    )}
    onClick={props.onClick}
  >
    {props.children}
  </div>
)

export const BlockTopLine: FC<{ className?: string }> = props => (
  <div className={clsx("mb-2", props.className)}>{props.children}</div>
)

export const BlockBottomLine: FC<{ className?: string }> = props => (
  <div className={clsx("mt-4", props.className)}>{props.children}</div>
)

export const BlockTopTitleLine: FC = props => {
  return (
    <BlockTopLine
      className={"text-sm sm:text-base leading-6 font-normal text-gray-200"}
    >
      {props.children}
    </BlockTopLine>
  )
}

export const BlockTokenLine: FC<{
  tokenNameArea?: ReactNode
  tokenCountArea: ReactNode
}> = props => (
  <div className={"flex items-center gap-4"}>
    {props.tokenNameArea}

    <Truncatable
      className={
        "text-2xl sm:text-3xl leading-9 font-medium text-gray-200 text-right flex-1 min-w-0"
      }
    >
      {props.tokenCountArea}
    </Truncatable>
  </div>
)

export const DefaultTokenNameArea: FC<{
  className?: string
  boxClassName?: string
  token: SuspenseResource<TokenInfo>
  afterTokenNameArea?: ReactNode
}> = props => (
  <div
    className={clsx(
      "flex gap-2.5 text-gray-200 flex items-center",
      props.boxClassName ?? "min-h-[calc(1.75rem_*_2)]",
      props.className,
    )}
  >
    <Spensor fallback={<span>&nbsp;</span>}>
      {() => (
        <>
          <TokenIcon
            token={readResource(props.token)}
            size={TokenIconProps.sizes.normal}
          />
          <TokenName
            className={
              "text-xl leading-7 font-medium max-w-[100px] sm:max-w-[150px]"
            }
            token={readResource(props.token)}
          />
          {props.afterTokenNameArea}
        </>
      )}
    </Spensor>
  </div>
)
