import { standardPrincipalCV } from "@stacks/transactions"
import {
  boolResult,
  contractRef,
  Currency,
  currencyContract,
  defineContract,
  definePublicCalls,
  Encoder,
  intResult,
  numberCV,
  optionalDecoder,
  principleResult,
  tokenResult,
  tupleDecoder,
} from "./contractBase"
import {
  getLaunchpadClaimPCs,
  getLaunchpadRefundPCs,
  getLaunchpadRegisterPCs,
} from "./postConditions/launchpadPCs"

export type LaunchToken = Currency.Alex
export type TicketToken = Currency.AlexLottery

const launchTokenRef: Encoder<LaunchToken> = contractRef

const launchPadDetailDecoder = tupleDecoder({
  activated: boolResult,
  "activation-threshold": intResult,
  "amount-per-ticket": intResult,
  "claim-end": intResult,
  "fee-to-address": principleResult,
  "registration-start": intResult,
  "registration-end": intResult,
  ticket: tokenResult,
  "tickets-won": intResult,
  "total-subscribed": intResult,
  "total-tickets": intResult,
  "wstx-per-ticket-in-fixed": intResult,
})

export type LaunchPadDetail = ReturnType<typeof launchPadDetailDecoder>

const registrationResult = tupleDecoder({
  "ticket-balance": intResult,
  "tickets-lost": intResult,
  "tickets-won": intResult,
  "value-high": intResult,
  "value-low": intResult,
  "wstx-locked-in-fixed": intResult,
})

export type RegistrationResult = ReturnType<typeof registrationResult>

export const launchPadContracts = defineContract({
  "alex-launchpad": {
    "get-listing-details": {
      input: [launchTokenRef],
      output: optionalDecoder(launchPadDetailDecoder),
    },
    register: definePublicCalls(
      [launchTokenRef, currencyContract, numberCV] as const,
      (
        senderAddress,
        contractArgs,
        { pricePerTicket }: { pricePerTicket: number },
      ) => {
        const govToken = contractArgs[0]
        const lotteryToken = contractArgs[1]
        const lotteryTokenAmount = contractArgs[2]
        const stxAmount = pricePerTicket * (lotteryTokenAmount as number)
        const launchpadPCArgs = {
          govToken,
          lotteryToken,
          lotteryTokenAmount,
          stxAmount,
        }
        return getLaunchpadRegisterPCs(senderAddress, launchpadPCArgs)
      },
    ),
    claim: definePublicCalls(
      [launchTokenRef, currencyContract] as const,
      (
        senderAddress,
        contractArgs,
        {
          lotteryTokenAmount,
          stxAmount,
        }: { lotteryTokenAmount: number; stxAmount: number },
      ) => {
        const alexToken = contractArgs[0]
        const lotteryToken = contractArgs[1]
        const claimArgs = {
          alexToken,
          stxAmount,
          lotteryToken,
          lotteryTokenAmount,
        }
        return getLaunchpadClaimPCs(claimArgs)
      },
    ),
    "get-user-id": {
      input: [launchTokenRef, standardPrincipalCV],
      output: optionalDecoder(intResult),
    },
    "get-subscriber-at-token-or-default": {
      input: [launchTokenRef, numberCV],
      output: registrationResult,
    },
    refund: definePublicCalls(
      [launchTokenRef, currencyContract] as const,
      (
        senderAddress,
        contractArgs,
        {
          lotteryTokenAmount,
          stxAmount,
        }: {
          stxAmount: number
          lotteryTokenAmount: number
        },
      ) => {
        const lotteryToken = contractArgs[1]
        return getLaunchpadRefundPCs({
          stxAmount,
          lotteryToken,
          lotteryTokenAmount,
        })
      },
    ),
  },
} as const)
