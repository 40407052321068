import clsx from "clsx"
import { FC } from "react"
import styles from "./NavButton.module.scss"

export const NavButton: FC<{
  className?: string
  active?: boolean
}> = props => (
  <div
    className={clsx(
      "px-4 h-full items-center text-base leading-6 font-medium cursor-pointer whitespace-nowrap",
      "text-gray-100 hover:text-gray-300 active:text-gray-400 ",
      props.active && styles.active,
    )}
  >
    {props.children}
  </div>
)
