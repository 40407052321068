import { FC } from "react"
import { TokenCount } from "../../../components/TokenCount"
import { TokenIcon } from "../../../components/TokenIcon"
import { TokenName } from "../../../components/TokenName"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { TokenInfoPresets } from "../../../utils/TokenInfoPresets/TokenInfoPresets"
import { VerticalInfoListContainer, VerticalInfoListItem } from "./InfoList"
import { TokenIDOSummaryInfo } from "./types"

export const TokenIDOSummary: FC<{
  className?: string
  token: TokenInfo
  tokenIDOSummary: TokenIDOSummaryInfo
}> = props => {
  const { token, tokenIDOSummary } = props
  return (
    <VerticalInfoListContainer className={props.className}>
      <VerticalInfoListItem
        title="Raise Size"
        detail={
          <>
            <TokenCount
              token={TokenInfoPresets.STX}
              count={tokenIDOSummary.raiseSize}
            />
            &nbsp;
            <TokenName token={TokenInfoPresets.STX} />
          </>
        }
      />

      <VerticalInfoListItem
        title="Tokens Per Ticket"
        detail={
          <>
            <TokenIcon className={"mr-2"} token={token} size={24} />
            <TokenCount
              token={token}
              count={tokenIDOSummary.tokenAmountPerTicket}
            />
            &nbsp;
            <TokenName token={token} />
          </>
        }
      />

      <VerticalInfoListItem
        title={
          <>
            Price Per <TokenName token={token} />
          </>
        }
        detail={
          <>
            <TokenIcon
              className={"mr-2"}
              token={TokenInfoPresets.STX}
              size={24}
            />
            &nbsp;
            <TokenCount
              token={TokenInfoPresets.STX}
              count={tokenIDOSummary.pricePerToken}
            />
            &nbsp;
            <TokenName token={TokenInfoPresets.STX} />
          </>
        }
      />
    </VerticalInfoListContainer>
  )
}
