import { Transaction } from "@stacks/stacks-blockchain-api-types"
import { errorMsgFor } from "../alexjs/errorCode"

export type TransactionState =
  | { type: "running" }
  | { type: "success"; explorerLink: string; telegramSubscribeLink: string }
  | { type: "error"; error: string; explorerLink?: string }

export class TransactionError extends Error {
  constructor(readonly transaction: Transaction) {
    super(errorMsgFor(transaction.tx_result.repr))
  }
}
