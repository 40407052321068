import clsx from "clsx"
import { FC, ReactNode } from "react"
import { Card, CardTitle } from "../Card"
import styles from "./CardModal.module.scss"

export type CardModalContentProps =
  | CardModalContentProps.WithTitle
  | CardModalContentProps.WithTitleBar
// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace CardModalContentProps {
  interface Common {
    className?: string
    bgClassName?: string
    layoutClassName?: string

    /**
     * Modal max width limitation. CSS `max-width` property value
     *
     * @default '80vw'
     */
    width?: number | string

    /**
     * Modal max height limitation. CSS `max-height` property value
     */
    height?: number | string
  }

  export interface WithTitle extends Common {
    title?: ReactNode
    onClose?: () => void
    titleBar?: undefined
  }

  export interface WithTitleBar extends Common {
    title?: undefined
    onClose?: undefined
    titleBar?: ReactNode
  }
}

export const CardModalContent: FC<CardModalContentProps> = props => {
  const titleBar = props.titleBar ?? (
    <DefaultTitleBar title={props.title} onClose={props.onClose} />
  )

  return (
    <Card
      className={clsx(
        styles.sizingLimit,
        props.layoutClassName ?? "flex flex-col gap-4",
        props.bgClassName ?? "bg-gray-900 backdrop-blur-2xl",
        "outline-none",
        props.className,
      )}
      style={
        {
          ["--CardModalContent-width"]: normalizeSize(props.width),
          ["--CardModalContent-height"]: normalizeSize(props.height),
        } as any
      }
      tabIndex={
        /* grab the focus from the original page, e.g. some focused input */
        0
      }
    >
      {titleBar}
      {props.children}
    </Card>
  )
}

const normalizeSize = (
  size: undefined | number | string,
): undefined | string => {
  if (size == null) return

  if (typeof size === "number") return `${size}px`

  return size
}

export const DefaultTitleBar: FC<{
  title?: ReactNode
  onClose?: () => void
}> = props => (
  <div className={"flex w-full items-center"}>
    <CardTitle className={"flex-1 text-center"}>{props.title}</CardTitle>

    {props.onClose && (
      <div className={"relative"}>
        <CloseButton
          iconClassName={"absolute -translate-y-1/2 right-[-5px]"}
          onClick={props.onClose}
        />
      </div>
    )}
  </div>
)

export const TitleBarWithLeftArea: FC<{
  leftArea?: ReactNode
  title?: ReactNode
  onClose?: () => void
}> = props => (
  <div className={"flex flex-wrap-reverse w-full items-center gap-y-3"}>
    <div className={"w-full flex-1 md:w-fit"}>{props.leftArea}</div>

    <CardTitle className={"flex-1 whitespace-nowrap text-center"}>
      {props.title}
    </CardTitle>

    <div className={"flex-1"}>
      <CloseButton
        className={"w-fit ml-auto"}
        iconClassName={"relative right-[-5px]"}
        onClick={props.onClose}
      />
    </div>
  </div>
)

const CloseButton: FC<{
  className?: string
  iconClassName?: string
  onClick?: () => void
}> = props => (
  <div className={props.className} onClick={props.onClick}>
    <svg
      className={clsx(
        "p-[5px] hover:opacity-30 active:opacity-20 cursor-pointer",
        props.iconClassName,
      )}
      viewBox={"0 0 16 16"}
      width={26}
      height={26}
      fill="#C4C4C4"
      opacity={0.4}
    >
      <path d="M9.873 7.754l4.919-4.957a.549.549 0 000-.794l-.757-.795a.549.549 0 00-.794 0L8.284 6.165a.366.366 0 01-.53 0L2.797 1.17a.549.549 0 00-.794 0l-.795.795a.549.549 0 000 .794l4.957 4.957a.366.366 0 010 .53L1.17 13.24a.549.549 0 000 .794l.795.795a.549.549 0 00.794 0l4.957-4.957a.366.366 0 01.53 0l4.957 4.957a.549.549 0 00.794 0l.795-.795a.549.549 0 000-.794L9.873 8.284a.366.366 0 010-.53z" />
    </svg>
  </div>
)
