import { Transaction } from "@stacks/stacks-blockchain-api-types"
import { ContractCallTransaction } from "@stacks/stacks-blockchain-api-types/generated"
import Dexie, { liveQuery, Observable, Table } from "dexie"
import { CONTRACT_DEPLOYER, PERSIST_KEY_PREFIX } from "../../config"
import { AlexContractNames } from "../../utils/alexjs/alexContracts"
import { AlexContracts, currentContractName } from "../../utils/alexjs/asSender"
import pMemoize, { skipCaching } from "../../utils/pMemorize"
import {
  fetchMemPoolTransactions,
  fetchNewTransactions,
} from "./AccountTransactions.services"

class AccountTransactions {
  private readonly db: Dexie
  readonly table: Table<Transaction, string>

  constructor(readonly stxAddress: string) {
    this.db = new Dexie(
      `${PERSIST_KEY_PREFIX}_account_transactions_${stxAddress}_v0.0.2`,
    )
    this.db.version(1).stores({
      transactions:
        "&tx_id, [tx_type+contract_call.contract_id+contract_call.function_name], nonce",
    })
    this.table = this.db.table("transactions")
    void this.sync()
  }

  sync = pMemoize(async () => {
    console.log(`start syncing transactions for ${this.stxAddress}`)
    const existing = await this.table.count()
    const transactions = await fetchNewTransactions(this.stxAddress, existing)
    await this.table.bulkPut(transactions)
    console.log(
      `synced ${transactions.length} transactions for ${this.stxAddress}`,
    )
    return skipCaching
  })

  liveQuery<T>(
    action: (table: Table<Transaction, string>) => T | Promise<T>,
  ): Observable<T> {
    return liveQuery(() => action(this.table))
  }

  async clear(): Promise<void> {
    await this.table.clear()
  }

  async fetchWithNonce(nonce: number): Promise<Transaction | undefined> {
    return this.table.where("nonce").equals(nonce).first()
  }

  async transactions<T extends AlexContractNames>(
    contract: T,
    func: keyof AlexContracts[T],
  ): Promise<ContractCallTransaction[]> {
    return this.table
      .where("[tx_type+contract_call.contract_id+contract_call.function_name]")
      .equals([
        "contract_call",
        `${CONTRACT_DEPLOYER}.${currentContractName(contract)}`,
        String(func),
      ])
      .toArray()
      .then(txs =>
        (txs as ContractCallTransaction[]).filter(
          t => t.tx_status === "success",
        ),
      )
  }

  fetchMemPoolTransactions = pMemoize(
    () => fetchMemPoolTransactions(this.stxAddress),
    { skipCaching: true },
  )
}

export default AccountTransactions
