import React, { FC } from "react"
import { Outlet } from "react-router-dom"
import { PageStackProvider } from "../PageStack/PageStackProvider"

export const Layout: FC = () => {
  return (
    <PageStackProvider>
      <div className={"mx-auto px-5 md:px-20 py-8 xl:w-[80rem]"}>
        <Outlet />
      </div>
    </PageStackProvider>
  )
}
