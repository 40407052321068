import { FC, ReactNode } from "react"

export interface CountNumberProps {
  children?: ReactNode
}

export const CountNumber: FC<CountNumberProps> = props => {
  if (typeof props.children !== "number") {
    return <>{props.children}</>
  }

  return <>{new Intl.NumberFormat().format(props.children)}</>
}
