import clsx from "clsx"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { Card } from "../../../../components/Card"
import { Spensor } from "../../../../components/Spensor"
import { TopNavBarHeight } from "../../../../components/TopNavBar/TopNavBar"
import { scrollIntoView } from "../../../../utils/domHelpers"
import { useLaunchPadStore } from "../../store/useLaunchPadStore"
import {
  ArticleRenderer,
  ArticleRendererRefValue,
  TocInfo,
} from "./ArticleRenderer"

export const WiredIDOIntro = forwardRef<
  IDOIntroRefValue,
  { className?: string }
>((props, ref) => {
  const store = useLaunchPadStore()

  return (
    <Spensor fallback={null}>
      {() => (
        <IDOIntro
          ref={ref}
          className={props.className}
          introHtml={store.tokenProfile.meta.introHtml}
        />
      )}
    </Spensor>
  )
})

export interface IDOIntroRefValue {
  scrollTo(): void
}

export const IDOIntro = forwardRef<
  IDOIntroRefValue,
  {
    className?: string
    introHtml: string
  }
>((props, forwardingRef) => {
  const [tocInfos, setTocInfos] = useState<TocInfo[]>([])

  const articleRef = useRef<ArticleRendererRefValue>(null)

  const cardRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(forwardingRef, () => ({
    scrollTo() {
      if (cardRef.current) {
        scrollIntoView(cardRef.current, { offset: TopNavBarHeight })
      }
    },
  }))

  return (
    <Card
      ref={cardRef}
      className={clsx("p-6 flex gap-8 items-start", props.className)}
    >
      <ul
        className={clsx(
          "px-4 py-6 flex-col gap-6 hidden rounded-lg min-w-[232px] md:flex",
          "sticky top-[104px]",
        )}
        style={{
          background: "rgba(229, 231, 235, 0.05)",
        }}
      >
        {tocInfos.map((info, idx) => (
          <li
            key={idx}
            className={clsx([
              "text-base leading-6 text-gray-200 cursor-pointer flex items-center",
              !info.isActive &&
                "text-opacity-50 pl-6 hover:text-opacity-100 active:text-opacity-80",
              info.isActive && "font-semibold",
            ])}
            onClick={() => articleRef.current?.focus(info.id)}
          >
            {info.isActive && (
              <svg className={"mr-4"} width={8} height={9}>
                <path d="M7 4.006l-6 4v-8l6 4z" fill="#E5E7EB" />
              </svg>
            )}
            {info.name}
          </li>
        ))}
      </ul>

      <ArticleRenderer
        ref={articleRef}
        topBarHeight={TopNavBarHeight}
        html={props.introHtml}
        onTocChanged={setTocInfos}
      />
    </Card>
  )
})
