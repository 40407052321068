import { FC, ReactNode, Suspense } from "react"
import { SuspenseResource } from "../utils/SuspenseResource"

const SpensorContent: FC<{ children: () => ReactNode }> = props => {
  return <>{props.children()}</>
}

export const Spensor: FC<{
  children: () => ReactNode
  fallback?: ReactNode
}> = props => {
  return (
    <Suspense fallback={props.fallback ?? null}>
      <SpensorContent>{props.children}</SpensorContent>
    </Suspense>
  )
}

export type SuspenseProps<T extends Record<string, any>> = {
  [P in keyof T]: SuspenseResource<T[P]>
}
