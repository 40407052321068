import clsx from "clsx"
import { throttle } from "lodash"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react"
import {
  isElementInViewport,
  scrollIntoView,
} from "../../../../utils/domHelpers"
import styles from "./ArticleRenderer.module.scss"

export interface TocInfo {
  id: string
  name: string
  isActive: boolean
}

export interface ArticleRendererRefValue {
  focus: (sectionId: string) => void
}

export const ArticleRenderer = forwardRef<
  ArticleRendererRefValue,
  {
    topBarHeight: number
    html: string
    onTocChanged?: (tocInfos: TocInfo[]) => void
  }
>((props, forwardingRef) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const { onTocChanged: propsOnTocChanged } = props

  const getTitleElements = useCallback((): HTMLHeadingElement[] => {
    const el = containerRef.current
    if (!el) return []
    return Array.from(el.querySelectorAll("h2"))
  }, [])

  useImperativeHandle(forwardingRef, () => ({
    focus: id => {
      const titleEls = getTitleElements()
      const titleEl = titleEls.find(
        (el, idx) => getTitleElementId(el, idx) === id,
      )
      if (!titleEl) return
      scrollIntoView(titleEl, { offset: props.topBarHeight })
    },
  }))

  useEffect(() => {
    const titleEls = getTitleElements()
    propsOnTocChanged?.(
      titleEls.map((el, idx) => ({
        id: getTitleElementId(el, idx),
        name: el.textContent?.trim() ?? "",
        isActive: false,
      })),
    )
  }, [getTitleElements, propsOnTocChanged])

  useEffect(() => {
    const titleEls = getTitleElements()
    const _onScroll = throttle(onScroll, 100)

    document.addEventListener("scroll", _onScroll)
    return () => {
      document.removeEventListener("scroll", _onScroll)
    }

    function onScroll(): void {
      const firstInViewportTitleElIndex = titleEls.findIndex(el =>
        isElementInViewport(el, {
          top: props.topBarHeight + el.offsetHeight + 6,
        }),
      )

      let firstInViewportSectionIndex = firstInViewportTitleElIndex - 1
      if (firstInViewportSectionIndex < 0) {
        firstInViewportSectionIndex =
          firstInViewportTitleElIndex === 0 ? 0 : titleEls.length - 1
      }

      propsOnTocChanged?.(
        titleEls.map((el, idx) => ({
          id: getTitleElementId(el, idx),
          name: el.textContent?.trim() ?? "",
          isActive: idx === firstInViewportSectionIndex,
        })),
      )
    }
  }, [getTitleElements, props.topBarHeight, propsOnTocChanged])

  return (
    <div
      ref={containerRef}
      className={clsx(
        styles.article,
        "prose dark:prose-invert max-w-none my-6",
      )}
      dangerouslySetInnerHTML={{ __html: props.html }}
    />
  )
})

const getTitleElementId = (el: HTMLHeadElement, idx: number): string => {
  return el.getAttribute("id") ?? `${el.textContent?.trim()}-${idx}`
}
