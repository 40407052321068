import clsx from "clsx"
import IMask from "imask/esm/imask"
import "imask/esm/masked/number"
import {
  createElement,
  FC,
  MutableRefObject,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { useFocusCallbacks } from "../../utils/reactHelpers/useFocus"
import {
  readResource,
  safeReadResource,
  SuspenseResource,
} from "../../utils/SuspenseResource"
import { Spensor } from "../Spensor"
import { TokenCount } from "../TokenCount"
import { Truncatable } from "../Truncatable"
import {
  Block,
  BlockProps,
  BlockTokenLine,
  DefaultTokenNameArea,
} from "./Block"

export interface TokenInputProps {
  className?: string
  disabled?: boolean
  error?: boolean
  readonly?: boolean
  token: SuspenseResource<TokenInfo>
  value: null | number
  onChange?: (newValue: null | number) => void
  tokenNameArea?: ReactNode
  topArea?: ReactNode
  bottomArea?: ReactNode
  renderBlock?: (props: BlockProps) => JSX.Element
}

export const TokenInput: FC<TokenInputProps> = props => {
  const [focusRef, inputProps] = useFocusCallbacks()
  const isInputHasFocus = focusRef.current

  const propOnChange = props.onChange

  const token = safeReadResource(props.token)
  const precision = token ? TokenInfo.getPrecision(token) : 4

  const iMaskOptions = useMemo(
    () => ({
      mask: Number,
      scale: precision,
      signed: false,
      thousandsSeparator: ",",
      padFractionalZeros: false,
      normalizeZeros: true,
      radix: ".",
      mapToRadix: ["."],
    }),
    [precision],
  )
  const { ref: _inputRef } = useIMask(props.value, iMaskOptions, {
    onAccept: useCallback(
      imask => {
        if (focusRef.current) {
          propOnChange?.(imask.typedValue)
        }
      },
      [focusRef, propOnChange],
    ),
  })
  const inputRef = _inputRef as RefObject<HTMLInputElement>

  // prettier-ignore
  const borderClassName = (
    props.disabled ? undefined :
    isInputHasFocus ? 'border-blue-800' :
    props.error ? 'border-red-600' :
    undefined
  )

  const inputClassNames = clsx(
    "flex-1 min-w-0 w-full appearance-none bg-transparent outline-none text-right",
    props.error && "text-red-500",
  )

  const children = (
    <>
      {props.topArea}

      <BlockTokenLine
        tokenNameArea={
          props.tokenNameArea ?? <DefaultTokenNameArea token={props.token} />
        }
        tokenCountArea={
          props.readonly ? (
            <Spensor fallback={<div className={inputClassNames}>-</div>}>
              {() => (
                <Truncatable>
                  <TokenCount
                    token={readResource(props.token)}
                    count={props.value ?? 0}
                  />
                </Truncatable>
              )}
            </Spensor>
          ) : (
            <input
              ref={inputRef}
              disabled={props.disabled}
              className={inputClassNames}
              placeholder={"0.0"}
              {...inputProps}
            />
          )
        }
      />

      {props.bottomArea}
    </>
  )

  const blockProps: BlockProps = {
    className: clsx(
      "rounded-lg flex flex-col border",
      (!props.readonly || props.disabled) && "bg-black/20",
      props.disabled && "opacity-30 pointer-events-none",
      props.className,
    ),
    borderClassName,
    onClick: () => {
      if (isInputHasFocus) return
      queueMicrotask(() => {
        inputRef.current?.focus()
      })
    },
    children,
  }

  const { renderBlock = props => createElement(Block, props) } = props

  return renderBlock(blockProps)
}

type MaskableElement = HTMLInputElement | HTMLTextAreaElement
function useIMask<Opts extends IMask.AnyMaskedOptions = IMask.AnyMaskedOptions>(
  value: any,
  opts: Opts,
  callbacks: {
    onAccept?: (maskRef: IMask.InputMask<Opts>, e?: InputEvent) => void
    onComplete?: (maskRef: IMask.InputMask<Opts>, e?: InputEvent) => void
  } = {},
): {
  ref: MutableRefObject<null | MaskableElement>
  maskRef: RefObject<IMask.InputMask<Opts>>
} {
  const ref = useRef<null | MaskableElement>(null)
  const maskRef = useRef<null | IMask.InputMask<Opts>>(null)

  const cbOnAccept = callbacks.onAccept
  const onAccept = useCallback(
    (event?: InputEvent) => {
      if (!maskRef.current) return
      cbOnAccept?.(maskRef.current, event)
    },
    [cbOnAccept],
  )

  const cbOnComplete = callbacks.onComplete
  const onComplete = useCallback(
    (event?: InputEvent) => {
      if (!maskRef.current) return
      cbOnComplete?.(maskRef.current, event)
    },
    [cbOnComplete],
  )

  const initMask = useCallback(() => {
    const el = ref.current

    if (!el || !opts?.mask) return

    maskRef.current = IMask(el, opts)
      .on("accept", onAccept)
      .on("complete", onComplete)

    if (el.defaultValue !== maskRef.current.value) {
      onAccept()
    }
  }, [onAccept, onComplete, opts])

  const destroyMask = useCallback(() => {
    maskRef.current?.destroy()
    maskRef.current = null
  }, [])

  useEffect(() => {
    const el = ref.current
    if (!el || !opts?.mask) return destroyMask()

    const instance = maskRef.current
    if (!instance) {
      initMask()
    } else {
      instance.updateOptions(opts)
    }
  }, [destroyMask, initMask, opts])

  useEffect(() => {
    if (maskRef.current == null) return
    if (value !== maskRef.current.typedValue) {
      maskRef.current.value = value ? String(value) : ""
    }
  }, [value])

  useEffect(() => destroyMask, [destroyMask])

  return {
    ref,
    maskRef,
  }
}
