import clsx from "clsx"
import { clamp } from "lodash"
import { FC } from "react"

export interface ProgressBarProps {
  className?: string
  bgColor: string
  fgColor: string
  progress: number
}

export const ProgressBar: FC<ProgressBarProps> = props => {
  return (
    <div
      className={clsx(props.className)}
      style={{ background: props.bgColor }}
    >
      <div
        style={{
          width: `${clamp(props.progress * 100, 0, 100)}%`,
          height: "100%",
          background: props.fgColor,
        }}
      />
    </div>
  )
}
