export const isElementInViewport = (
  el: HTMLElement,
  padding?: { top?: number; bottom?: number; left?: number; right?: number },
): boolean => {
  const bounding = el.getBoundingClientRect()
  const elHeight = el.offsetHeight
  const elWidth = el.offsetWidth

  return (
    bounding.top >= -elHeight + (padding?.top ?? 0) &&
    bounding.left >= -elWidth + (padding?.left ?? 0) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth) +
        elWidth -
        (padding?.right ?? 0) &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) +
        elHeight -
        (padding?.left ?? 0)
  )
}

export const scrollIntoView = (
  element: HTMLElement,
  options: { offset?: number } = {},
): void => {
  const { offset = 0 } = options

  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition + window.pageYOffset - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  })
}

export const isMobileDevice = (): boolean => {
  const MOBILE_REGEX =
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk|Phone|Opera M(obi|ini)/
  const detectMobile = (ua: string): boolean => MOBILE_REGEX.test(ua)
  return detectMobile(navigator.userAgent)
}

export function isTouchDevice(): boolean {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0
}
