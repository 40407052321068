import { FC } from "react"
import { NavLink } from "../../../../../components/NavLink"
import { TokenMetaInfo } from "../../types"
import { ReactComponent as DiscordIcon } from "./discord.svg"
import { ReactComponent as MediumIcon } from "./medium.svg"
import { ReactComponent as TwitterIcon } from "./twitter.svg"
import { ReactComponent as WebsiteIcon } from "./website.svg"

export const IDOTokenLinks: FC<{ links: TokenMetaInfo["links"] }> = ({
  links,
}) => {
  return (
    <>
      {links.website && <Link Icon={WebsiteIcon} url={links.website} />}
      {links.medium && <Link Icon={MediumIcon} url={links.medium} />}
      {links.twitter && <Link Icon={TwitterIcon} url={links.twitter} />}
      {links.discord && <Link Icon={DiscordIcon} url={links.discord} />}
    </>
  )
}

const Link: FC<{
  Icon: typeof WebsiteIcon
  url: string
}> = ({ Icon, url }) => {
  return (
    <NavLink className={"text-gray-200 opacity-40 hover:opacity-20"} to={url}>
      <Icon fill={"currentColor"} />
    </NavLink>
  )
}
