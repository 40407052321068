import { computed, makeObservable } from "mobx"
import { createTransformer } from "mobx-utils"
import {
  Currency,
  currencyContractMap,
  FungibleToken,
} from "../../utils/alexjs/contractBase"
import AuthStore from "../authStore/AuthStore"
import CurrencyStore from "../currencyStore/CurrencyStore"
import { LazyValue } from "../LazyValue/LazyValue"
import { fetchBalanceForAccount } from "./AccountStore.services"
import AccountTransactions from "./AccountTransactions"

class AccountStore {
  constructor(
    readonly authService: Pick<
      AuthStore,
      "stxAddress$" | "currentBlockHeight" | "signOut"
    >,
    readonly currencyStore: Pick<CurrencyStore, "getScale$">,
  ) {
    makeObservable(this)
  }

  private balances = new LazyValue(
    () =>
      [
        this.authService.stxAddress$,
        this.authService.currentBlockHeight,
      ] as const,
    async ([stxAddress]) => {
      const result = await fetchBalanceForAccount(stxAddress)
      function balanceFor(currency: FungibleToken): number {
        return Number(
          result.fungible_tokens[currencyContractMap[currency]]?.balance ?? "0",
        )
      }

      return {
        [Currency.STX]: Number(result.stx.balance) - Number(result.stx.locked),
        [Currency.USDA]: balanceFor(Currency.USDA),
        [Currency.WBTC]: balanceFor(Currency.WBTC),
        [Currency.AlexLottery]: balanceFor(Currency.AlexLottery),
        [Currency.Alex]: balanceFor(Currency.Alex),
        [Currency.APower]: balanceFor(Currency.APower),
        [Currency.FWP_WSTX_ALEX_50_50_V1_01]: balanceFor(
          Currency.FWP_WSTX_ALEX_50_50_V1_01,
        ),
        [Currency.FWP_WSTX_WBTC_50_50_V1_01]: balanceFor(
          Currency.FWP_WSTX_WBTC_50_50_V1_01,
        ),
      }
    },
  )

  getBalance$ = createTransformer((currency: Currency) => {
    const scale = this.currencyStore.getScale$(currency)
    const balance = this.balances.value$[currency]
    return balance / Math.pow(10, scale)
  })

  async updateBalance(): Promise<void> {
    await this.balances.triggerUpdate()
  }

  @computed({ keepAlive: true }) get transactions$(): AccountTransactions {
    return new AccountTransactions(this.authService.stxAddress$)
  }

  async signOut(): Promise<void> {
    await this.transactions$.clear()
    await this.authService.signOut()
  }
}

export default AccountStore
