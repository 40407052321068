import { FC } from "react"
import { Card, CardInset, CardTitle } from "../../../../components/Card"

export const MyIDOFrame: FC<{ className?: string }> = props => (
  <Card className={props.className}>
    <CardTitle className={"block mb-4"}>My IDO</CardTitle>

    <CardInset
      className={"flex flex-col gap-6 items-center px-4 py-6 text-center"}
    >
      {props.children}
    </CardInset>
  </Card>
)
