import { Observable, Observer } from "./Observable"

export interface CreateObservableObserver<T = any> {
  next: (value: T) => void
  error: (error: any) => void
  complete: () => void
}

export function createObservable<T>(
  factory: (observer: CreateObservableObserver<T>) => void | (() => void),
): Observable<T> {
  const observable: Observable<T> = {
    [Symbol.observable]: () => observable,
    subscribe: observer => {
      let next: Observer<T>["next"]
      let error: Observer<T>["error"]
      let complete: Observer<T>["complete"]
      let isClosed = false

      if (typeof observer === "function") {
        next = observer
      } else {
        next = observer?.next
        error = observer?.error
        complete = observer?.complete
      }

      let cleanup: void | undefined | (() => void)

      // eslint-disable-next-line prefer-const
      cleanup = factory({
        next(v) {
          if (isClosed) return
          next?.(v)
        },
        error(err) {
          if (isClosed) return
          isClosed = true
          error?.(err)
          cleanup?.()
        },
        complete() {
          if (isClosed) return
          isClosed = true
          complete?.()
          cleanup?.()
        },
      })

      // cleanup if closed immediately
      if (isClosed) {
        cleanup?.()
      }

      const unsubscribe = (): void => {
        if (isClosed) return
        isClosed = true
        cleanup?.()
      }

      return {
        unsubscribe,
        get closed() {
          return isClosed
        },
      }
    },
  }

  return observable
}
