import { computed, makeObservable } from "mobx"
import { ESTIMATED_BLOCK_DURATION } from "../../../config"
import AccountStore from "../../../stores/accountStore/AccountStore"
import AuthStore from "../../../stores/authStore/AuthStore"
import CurrencyStore from "../../../stores/currencyStore/CurrencyStore"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import {
  LaunchPadDetail,
  LaunchToken,
  TicketToken,
} from "../../../utils/alexjs/launchPadContracts"
import ClaimViewModule from "./ClaimViewModule"
import LaunchPadRefundModule from "./LaunchPadRefundModule"
import LaunchPadRegisterModule from "./LaunchPadRegisterModule"
import { fetchLaunchPad } from "./LaunchPadStore.service"
import { TokenProfileViewModule } from "./TokenProfileViewModule"
import UserIDOStatusViewModule from "./UserIDOStatusViewModule"

export enum LaunchingStatus {
  Upcoming = "Upcoming",
  Registration = "Registration",
  Claim = "Claim",
  Finished = "Finished",
}

class LaunchPadStore {
  constructor(
    readonly token: LaunchToken,
    readonly authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight">,
    readonly accountStore: Pick<AccountStore, "getBalance$" | "transactions$">,
    readonly currencyStore: Pick<CurrencyStore, "getScale$" | "getPrice$">,
  ) {
    makeObservable(this)
  }

  private detailFromAPI = new LazyValue(
    () => [this.token, this.authStore.currentBlockHeight] as const,
    ([token, blockHeight]) => fetchLaunchPad(token, blockHeight),
    { decorator: pMemoizeDecorator({ persistKey: "launchPadDetail" }) },
  )

  get detail$(): LaunchPadDetail {
    return this.detailFromAPI.value$
  }

  get ticketToken$(): TicketToken {
    return this.detail$["ticket"] as TicketToken
  }

  tokenProfile = new TokenProfileViewModule(this)
  userIDO = new UserIDOStatusViewModule(this)
  register = new LaunchPadRegisterModule(this)
  claim = new ClaimViewModule(this)
  refund = new LaunchPadRefundModule(this)

  @computed get currentBlock$(): number {
    return this.authStore.currentBlockHeight
  }

  @computed get singleBlockDuration$(): number {
    return ESTIMATED_BLOCK_DURATION
  }

  @computed get claimStartsAtBlock$(): number {
    return this.detail$["registration-end"]
  }

  @computed get claimStartsAt$(): Date {
    const willStartAfterMS =
      (this.claimStartsAtBlock$ - this.currentBlock$) *
      this.singleBlockDuration$
    return new Date(Date.now() + willStartAfterMS)
  }

  @computed get activationProgress$(): number {
    return this.currentActivation$ / this.activationThreshold$
  }

  @computed get activationThreshold$(): number {
    return this.detail$["activation-threshold"]
  }

  @computed get currentActivation$(): number {
    return this.detail$["total-subscribed"]
  }

  @computed get currentStatus$(): LaunchingStatus {
    if (this.currentBlock$ < this.detail$["registration-start"]) {
      return LaunchingStatus.Upcoming
    }
    if (this.currentBlock$ <= this.detail$["registration-end"]) {
      return LaunchingStatus.Registration
    }
    if (
      this.currentBlock$ < this.detail$["claim-end"] &&
      this.activationProgress$ >= 1
    ) {
      return LaunchingStatus.Claim
    }
    return LaunchingStatus.Finished
  }

  @computed get currentStatusEndBlock$(): null | number {
    if (this.currentBlock$ < this.detail$["registration-start"]) {
      return this.detail$["registration-start"]
    }
    if (this.currentBlock$ <= this.detail$["registration-end"]) {
      return this.detail$["registration-end"]
    }
    if (
      this.currentBlock$ < this.detail$["claim-end"] &&
      this.activationProgress$ >= 1
    ) {
      return this.detail$["claim-end"]
    }
    return null
  }
}

export default LaunchPadStore
