import { noop } from "lodash"
import {
  action,
  IReactionOptions,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
} from "mobx"
import { asyncAction, runAsyncAction } from "../../utils/asyncAction"
import { isObservable } from "../../utils/Observable/isObservable"
import { Observable } from "../../utils/Observable/Observable"
import pMemoize from "../../utils/pMemorize"
import { defer } from "../../utils/promiseHelpers"

export type FetchValueFn<P, V> = (params: P) => V

export type FetchValueDecorator<P, V> = (
  fetchValue: FetchValueFn<P, V>,
) => FetchValueFn<P, V>

type UnwrappedValue<T> = T extends Observable<infer R>
  ? R
  : T extends Promise<infer R>
  ? R
  : never

export class LazyValue<WrappedValue extends Promise<any> | Observable, Params> {
  @observable hasValue = false
  @observable.ref private _value?: UnwrappedValue<WrappedValue>
  @observable.ref private _failedWithError?: Error

  @observable isUpdating = false
  get value$(): UnwrappedValue<WrappedValue> {
    let params: Params
    try {
      params = this.paramsGetter$()
    } catch (e) {
      // new promise
      if (this.hasValue || this._failedWithError != null) {
        this.onReinitialize()
      }
      throw e
    }
    if (this._failedWithError != null) {
      throw this._failedWithError
    }
    if (!this.hasValue) {
      throw this.updateValue(params)
    }
    return this._value!
  }

  private readonly fetchValue: FetchValueFn<Params, WrappedValue>

  constructor(
    private readonly paramsGetter$: () => Params,
    fetchValue: FetchValueFn<Params, WrappedValue>,
    options?: {
      reactionOptions?: IReactionOptions<Params, boolean>
      decorator?: FetchValueDecorator<Params, WrappedValue>
    },
  ) {
    makeObservable(this)

    if (options?.decorator) {
      this.fetchValue = options.decorator(fetchValue)
    } else {
      this.fetchValue = fetchValue
    }

    let dispose: () => void
    let hasBeenDisposed = false
    onBecomeObserved(this, "_value", async () => {
      dispose = reaction(
        () => this.paramsGetter$(),
        () => {
          void this.triggerUpdate()
        },
        {
          // if it has been disposed and resubscribed
          // we want to fire reaction immediately to get the latest value
          // otherwise it might stay at stale value forever
          fireImmediately: hasBeenDisposed,
          ...options?.reactionOptions,
        },
      )
    })
    onBecomeUnobserved(this, "_value", () => {
      hasBeenDisposed = true
      dispose?.()
      this.unsubscribeLatestFetchValueCall?.()
    })
  }

  unsubscribeLatestFetchValueCall?: () => void
  private updateValue = pMemoize(
    (params: Params): Promise<void> => {
      this.unsubscribeLatestFetchValueCall?.()

      let handleResult: FetchValueSourceHandleResult

      const source = this.fetchValue(params)
      if (isObservable(source)) {
        handleResult = this.updateValueFromObservable(source)
      } else {
        handleResult = this.updateValueFromPromise(source)
      }

      this.unsubscribeLatestFetchValueCall = handleResult.unsubscribe
      return handleResult.promise
    },
    { skipCaching: true },
  )
  private updateValueFromPromise(
    promise: Promise<UnwrappedValue<WrappedValue>>,
  ): FetchValueSourceHandleResult {
    let isUnsubscribed = false
    void promise.then(
      value => {
        if (isUnsubscribed) return
        this.onReceiveValue(value)
      },
      error => {
        if (isUnsubscribed) return
        this.onEncounterError(error)
      },
    )
    return {
      promise: promise.then(noop),
      unsubscribe: () => {
        isUnsubscribed = true
      },
    }
  }
  private updateValueFromObservable(
    observable: Observable<UnwrappedValue<WrappedValue>>,
  ): FetchValueSourceHandleResult {
    const deferred = defer()

    const sub = observable.subscribe({
      next: value => {
        this.onReceiveValue(value)
        deferred.resolve()
      },
      error: err => {
        this.onEncounterError(err)
        deferred.reject(err)
      },
    })

    return {
      promise: deferred.promise,
      unsubscribe: () => {
        deferred.resolve()
        sub.unsubscribe()
      },
    }
  }

  @action private onReinitialize(): void {
    this.hasValue = false
    this._value = undefined
    this._failedWithError = undefined
  }

  @action private onReceiveValue(value: UnwrappedValue<WrappedValue>): void {
    this.hasValue = true
    this._value = value
    this._failedWithError = undefined
  }

  @action private onEncounterError(error: any): void {
    this.hasValue = false
    this._value = undefined
    this._failedWithError = error
  }

  @asyncAction async triggerUpdate(run = runAsyncAction): Promise<void> {
    try {
      this.isUpdating = true
      await run(this.updateValue(this.paramsGetter$()))
      this.isUpdating = false
    } catch (e) {
      this.isUpdating = false
    }
  }
}

interface FetchValueSourceHandleResult {
  promise: Promise<void>
  unsubscribe: () => void
}
