import clsx from "clsx"
import { FC } from "react"
import { ReactComponent as MenuIcon } from "./menu.svg"

export const MenuButton: FC<{ className?: string }> = props => {
  return (
    <div
      className={clsx(
        "text-white rounded-full hover:text-black hover:bg-white",
        props.className,
      )}
    >
      <MenuIcon className={"fill-current"} />
    </div>
  )
}
