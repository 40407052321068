import type {
  FungiblePostCondition,
  STXPostCondition,
} from "@stacks/transactions"
import {
  createAssetInfo,
  FungibleConditionCode,
  makeContractFungiblePostCondition,
  makeContractSTXPostCondition,
  makeStandardFungiblePostCondition,
  makeStandardSTXPostCondition,
} from "@stacks/transactions"
import { CONTRACT_DEPLOYER } from "../../../config"
import { Currency, LiquidityPoolToken, LiquidityToken } from "../contractBase"
import { currencyTxnData } from "./assetMapping"

export const getAddLiquidityPCs = (
  senderAddress: string,
  liquidityPCArgs: {
    tokenX: Currency
    tokenY: Currency
    poolToken: Currency
    dx: number
    dy: number | undefined
  },
): Array<FungiblePostCondition | STXPostCondition> => {
  const { tokenY, dx, dy, poolToken } = liquidityPCArgs
  //Jan. 16th: only stx combinations available. STX is always tokenX
  //TODO When prices become more stable, fetch expected return value
  return [
    getStandardSTXPC(senderAddress, Math.ceil(dx / 1e2)),
    getStandardFungiblePC(senderAddress, tokenY, dy),
    getContractFungiblePC(poolToken),
  ]
}

export const getRemoveLiquidityPCs = (
  senderAddress: string,
  liquidityPCArgs: {
    tokenX: LiquidityToken
    tokenY: LiquidityToken
    poolToken: LiquidityPoolToken
    percentage: number
    amountToRemove: number
  },
): Array<FungiblePostCondition | STXPostCondition> => {
  const { tokenY, poolToken } = liquidityPCArgs
  //Jan. 16th: only stx combinations available. STX is always tokenX
  //TODO When prices become more stable, fetch expected return value for tokens
  // getContractFungiblePC(tokenY, pooledY * 1e8),
  // getContractSTXPC(Math.floor(pooledX * 1e6)),
  return [
    getStandardBurnFungiblePC(
      senderAddress,
      poolToken,
      0,
      // Math.round(amountToRemove * 1e8 + amountToRemove * 1e7),
    ),
    getContractSTXPC(),
    getContractFungiblePC(tokenY),
  ]
}
const getStandardFungiblePC = (
  senderAddress: string,
  tokenY: Currency,
  amount: number | undefined,
): FungiblePostCondition => {
  const code = FungibleConditionCode.LessEqual
  const { assetAddress, assetContractName, assetName } = currencyTxnData(tokenY)
  const fungibleAssetInfo = createAssetInfo(
    assetAddress,
    assetContractName,
    assetName,
  )
  return makeStandardFungiblePostCondition(
    senderAddress,
    code,
    amount ?? 0,
    fungibleAssetInfo,
  )
}

const getStandardBurnFungiblePC = (
  senderAddress: string,
  tokenX: Currency,
  amount: number | undefined,
): FungiblePostCondition => {
  const code = FungibleConditionCode.GreaterEqual
  const { assetAddress, assetContractName, assetName } = currencyTxnData(tokenX)
  const fungibleAssetInfo = createAssetInfo(
    assetAddress,
    assetContractName,
    assetName,
  )
  return makeStandardFungiblePostCondition(
    senderAddress,
    code,
    amount ?? 0,
    fungibleAssetInfo,
  )
}

const getStandardSTXPC = (
  senderAddress: string,
  amount = 0,
): STXPostCondition => {
  const code = FungibleConditionCode.LessEqual
  return makeStandardSTXPostCondition(senderAddress, code, amount)
}

const getContractFungiblePC = (
  tokenY: Currency,
  amount?: number,
): FungiblePostCondition => {
  const code = FungibleConditionCode.GreaterEqual
  const contractAddress = CONTRACT_DEPLOYER
  const contractName = "alex-vault"

  const { assetAddress, assetContractName, assetName } = currencyTxnData(tokenY)
  const fungibleAssetInfo = createAssetInfo(
    assetAddress,
    assetContractName,
    assetName,
  )
  return makeContractFungiblePostCondition(
    contractAddress,
    contractName,
    code,
    amount ?? 0,
    fungibleAssetInfo,
  )
}

const getContractSTXPC = (amount = 0): STXPostCondition => {
  const code = FungibleConditionCode.GreaterEqual
  const contractAddress = CONTRACT_DEPLOYER
  const contractName = "alex-vault"

  return makeContractSTXPostCondition(
    contractAddress,
    contractName,
    code,
    amount,
  )
}
