import clsx from "clsx"
import { FC, ReactNode } from "react"
import styles from "./InfoList.module.scss"

export const HorizontalInfoListContainer: FC<{
  className?: string
}> = props => {
  return (
    <div
      className={clsx("flex gap-2.5 justify-between w-full", props.className)}
    >
      {props.children}
    </div>
  )
}

export const HorizontalInfoListItem: FC<{
  className?: string
  title: ReactNode
  detail: ReactNode
}> = props => (
  <dl className={clsx("flex-1", props.className)}>
    <dd
      className={
        "text-5xl leading-none font-normal text-gray-200 mb-2 flex justify-center items-center text-center"
      }
    >
      {props.detail}
    </dd>
    <dt
      className={
        "text-sm leading-5 font-normal text-gray-500 flex justify-center text-center"
      }
    >
      {props.title}
    </dt>
  </dl>
)

export const VerticalInfoListContainer: FC<{ className?: string }> = props => (
  <div className={clsx(styles.verticalInfoListContainer, props.className)}>
    {props.children}
  </div>
)

export const VerticalInfoListItem: FC<{
  title: ReactNode
  detail: ReactNode
}> = props => (
  <dl className={"flex items-center justify-between py-3"}>
    <dt className={"text-sm leading-5 font-normal text-gray-400"}>
      {props.title}
    </dt>
    <dd
      className={
        "text-base leading-6 font-semibold text-gray-200 flex items-center whitespace-pre"
      }
    >
      {props.detail}
    </dd>
  </dl>
)
