export function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x)
}

type Writable<T> = {
  -readonly [k in keyof T]: T[k]
}

export function writable<T>(value: T): Writable<T> {
  return value
}

export type AnyFunc<Args extends any[] = any, Ret = any> = (
  ...args: Args
) => Ret
