export enum RegisterFormErrorType {
  EmptyTokenCount = "EmptyTokenCount",
  InsufficientTokenBalance = "InsufficientTokenBalance",
  InsufficientSTXBalance = "InsufficientSTXBalance",
}

export type RegisterFormError = {
  type: RegisterFormErrorType
  message: string
}
