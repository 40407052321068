import * as Sentry from "@sentry/browser"
import { Component, ErrorInfo, FC, ReactNode } from "react"
import { ReactComponent as AlexLogo } from "../components/TopNavBar/alexLogo.svg"
import { ReactComponent as AlexLogoText } from "../components/TopNavBar/alexLogoText.svg"
import { WhiteFilledButton } from "./button/FilledButton"
import { GradientText } from "./GradientText"

interface GlobalErrorBoundaryPageState {
  hasError: boolean
  error?: Error
  eventId?: string
}
class GlobalErrorBoundaryPage extends Component {
  state: GlobalErrorBoundaryPageState = {
    hasError: false,
  }

  static getDerivedStateFromError(error: Error): GlobalErrorBoundaryPageState {
    return {
      hasError: true,
      error,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.withScope(scope => {
      scope.setExtras({ errorInfo })
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <GlobalErrorBoundaryPageContent
          title="Oops! Something went wrong."
          subTitle={this.state.error?.message}
          actions={
            <>
              <GoToHomePageButton />
              <WhiteFilledButton
                onClick={() => {
                  Sentry.showReportDialog({
                    eventId: this.state.eventId,
                  })
                }}
              >
                Report Issue
              </WhiteFilledButton>
            </>
          }
        />
      )
    }
    return <>{this.props.children}</>
  }
}

export default GlobalErrorBoundaryPage

export const GoToHomePageButton: FC = () => {
  return (
    <WhiteFilledButton
      className="min-w-[240px]"
      onClick={() => (window.location.href = "/")}
    >
      Go to Home
    </WhiteFilledButton>
  )
}

export const GlobalErrorBoundaryPageContent: FC<{
  code?: string
  title?: ReactNode
  subTitle?: ReactNode
  actions?: ReactNode
}> = props => (
  <div className="h-screen flex flex-col justify-between px-4 py-12 lg:px-24 lg:py-24">
    <div className="flex justify-between items-center">
      <div className={"flex items-center cursor-pointer gap-1.5"}>
        <AlexLogo width={32} height={32} />
        <AlexLogoText width={106} height={28} />
      </div>
      {props.code && (
        <GradientText
          className={"text-2xl sm:text-3xl leading-9 font-medium uppercase"}
          gradientStyle={
            "linear-gradient(90.83deg, #FFFFFF 0.71%, #1D4ED8 99.29%)"
          }
        >
          {props.code}
        </GradientText>
      )}
    </div>
    <div>
      <h1>
        <GradientText
          className={"text-5xl leading-9 font-medium"}
          gradientStyle={
            "linear-gradient(90.83deg, #FFFFFF 0.71%, #1D4ED8 99.29%)"
          }
        >
          {props.title}
        </GradientText>
      </h1>
      {props.subTitle && (
        <GradientText
          className={"text-2xl leading-9 font-medium"}
          gradientStyle={
            "linear-gradient(90.83deg, #FFFFFF 0.71%, #1D4ED8 99.29%)"
          }
        >
          {props.subTitle}
        </GradientText>
      )}
    </div>
    <div className="flex gap-2">{props.actions}</div>
  </div>
)

export const NotFoundPage: FC = () => (
  <GlobalErrorBoundaryPageContent
    title="Not Found"
    code="404"
    subTitle="ALEX provide complete defi on bitcoin, but apparently you have entered a incomplete page."
    actions={<GoToHomePageButton />}
  />
)
