import { FinishedTxData } from "@stacks/connect"
import { CONTRACT_DEPLOYER } from "../../../config"
import { asSender } from "../../../utils/alexjs/asSender"
import {
  LaunchPadDetail,
  LaunchToken,
  RegistrationResult,
  TicketToken,
} from "../../../utils/alexjs/launchPadContracts"

// const sample = {
//   _id: "61b1d4dfa170314f000fd117",
//   currentblock: 334,
//   token: "age000-governance-token",
//   "activation-threshold": 100,
//   "amount-per-ticket": 100,
//   "claim-end": 66000,
//   "fee-to-address": "64296bdd8629ff566e2021441fec53336ce5cebe",
//   "last-random": 0,
//   "registration-end": 400,
//   "registration-start": 300,
//   ticket: "lottery-ido-alex",
//   "tickets-won": 0,
//   "total-subscribed": 0,
//   "total-tickets": 1000,
//   "users-nonce": 0,
//   "wstx-per-ticket-in-fixed": 2500000000,
//   "wstx-per-ticket": 25,
//   "total-raise": 25000,
//   "price-per-token": 4,
//   "current-allocation": 0,
//   "total-allocation": 100000,
//   time_stamp: "2021-12-09T10:05:19.946Z",
// }

// export type LaunchPadDetail = typeof sample

export async function fetchLaunchPad(
  token: LaunchToken,
  blockHeight: number,
): Promise<LaunchPadDetail> {
  const result = await asSender(CONTRACT_DEPLOYER, blockHeight)
    .contract("alex-launchpad")
    .func("get-listing-details")
    .call([token])
  if (result == null) {
    throw new Error(`Token ${token} not found`)
  }
  console.log(result)
  return result
}

export function fetchUserIDONonce(
  stxAddress: string,
  token: LaunchToken,
  blockHeight: number,
): Promise<number | undefined> {
  return asSender(stxAddress, blockHeight)
    .contract("alex-launchpad")
    .func("get-user-id")
    .call([token, stxAddress])
}

export async function fetchUserSubscription(
  stxAddress: string,
  userId: number,
  token: LaunchToken,
  blockHeight: number,
): Promise<RegistrationResult> {
  console.log("Checking if user is registered")
  const result = await asSender(stxAddress, blockHeight)
    .contract("alex-launchpad")
    .func("get-subscriber-at-token-or-default")
    .call([token, userId])
  console.log(result)
  return result
}

export async function openTicket(
  stxAddress: string,
  token: LaunchToken,
  ticket: TicketToken,
  stxPerTicket: number,
): Promise<FinishedTxData> {
  return await asSender(stxAddress)
    .contract("alex-launchpad")
    .func("claim")
    .call([token, ticket], { lotteryTokenAmount: 1, stxAmount: stxPerTicket })
}
