import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { OpacityButton } from "./components/button/OpacityButton"
import { GlobalErrorBoundaryPageContent } from "./components/GlobalErrorBoundaryPage"
import { ENV_NAME, IS_IN_MAINTENANCE_MODE } from "./config"
import reportWebVitals from "./reportWebVitals"
import "./styles/index.css"

Sentry.init({
  environment: ENV_NAME,
  dsn: "https://b426e868435d4e43aa126549c83a2fbd@o1020431.ingest.sentry.io/6090179",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1,
  ignoreErrors: [],
})

if (IS_IN_MAINTENANCE_MODE) {
  ReactDOM.render(
    <GlobalErrorBoundaryPageContent
      title="Maintenance"
      code="404"
      subTitle="Sorry, we are under maintenance."
      actions={
        <OpacityButton className="min-w-[240px]">
          We will be back soon
        </OpacityButton>
      }
    />,
    document.getElementById("root"),
  )
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root"),
  )
}

if (!process.env.STORYBOOK) {
  // Forcibly enable tailwind dark mode in production environment before light
  // mode complete
  document.documentElement.classList.add("dark")
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
