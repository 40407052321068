"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const elliptic_1 = require("elliptic");
const ecdsa_sig_formatter_1 = require("ecdsa-sig-formatter");
const errors_1 = require("../errors");
class SECP256K1Client {
    constructor() {
    }
    static loadPrivateKey(rawPrivateKey) {
        if (rawPrivateKey.length === 66) {
            rawPrivateKey = rawPrivateKey.slice(0, 64);
        }
        return SECP256K1Client.ec.keyFromPrivate(rawPrivateKey);
    }
    static loadPublicKey(rawPublicKey) {
        return SECP256K1Client.ec.keyFromPublic(rawPublicKey, 'hex');
    }
    static derivePublicKey(privateKey, compressed = true) {
        if (typeof privateKey !== 'string') {
            throw Error('private key must be a string');
        }
        if (!(/^[0-9A-F]+$/i.test(privateKey))) {
            throw Error('private key must be a hex string');
        }
        if (privateKey.length == 66) {
            privateKey = privateKey.slice(0, 64);
        }
        else if (privateKey.length <= 64) {
            // do nothing
        }
        else {
            throw Error('private key must be 66 characters or less');
        }
        const keypair = SECP256K1Client.ec.keyFromPrivate(privateKey);
        return keypair.getPublic(compressed, 'hex');
    }
    static signHash(signingInputHash, rawPrivateKey, format = 'jose') {
        // make sure the required parameters are provided
        if (!(signingInputHash && rawPrivateKey)) {
            throw new errors_1.MissingParametersError('a signing input hash and private key are all required');
        }
        // prepare the private key
        const privateKeyObject = SECP256K1Client.loadPrivateKey(rawPrivateKey);
        // calculate the signature
        const signatureObject = privateKeyObject.sign(signingInputHash);
        const derSignature = Buffer.from(signatureObject.toDER());
        if (format === 'der') {
            return derSignature.toString('hex');
        }
        else if (format === 'jose') {
            // return the JOSE-formatted signature
            return ecdsa_sig_formatter_1.derToJose(derSignature, 'ES256');
        }
        else {
            throw Error('Invalid signature format');
        }
    }
    static loadSignature(joseSignature) {
        // create and return the DER-formatted signature buffer
        return ecdsa_sig_formatter_1.joseToDer(joseSignature, 'ES256');
    }
    static verifyHash(signingInputHash, derSignatureBuffer, rawPublicKey) {
        // make sure the required parameters are provided
        if (!(signingInputHash && derSignatureBuffer && rawPublicKey)) {
            throw new errors_1.MissingParametersError('a signing input hash, der signature, and public key are all required');
        }
        // prepare the public key
        const publicKeyObject = SECP256K1Client.loadPublicKey(rawPublicKey);
        // verify the token
        return publicKeyObject.verify(signingInputHash, derSignatureBuffer);
    }
}
exports.SECP256K1Client = SECP256K1Client;
SECP256K1Client.ec = new elliptic_1.ec('secp256k1');
SECP256K1Client.algorithmName = 'ES256K';
