import clsx from "clsx"
import { FC } from "react"
import { ReactComponent as CountdownIcon } from "../countdown.svg"

export const Container: FC<{
  className?: string
}> = props => (
  <div
    className={clsx(
      "px-2 py-1 rounded border border-white border-opacity-10",
      "flex gap-2 items-center",
      "text-sm leading-5",
      props.className,
    )}
  >
    <CountdownIcon width={16} height={16} fill={"#c4c4c4"} />
    {props.children}
  </div>
)
