import { action, computed, makeObservable, observable } from "mobx"
import { EXPLORER_TX_URL, TG_SUB_URL } from "../../../config"
import { asSender } from "../../../utils/alexjs/asSender"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { CancelError } from "../../../utils/error"
import {
  TransactionError,
  TransactionState,
} from "../../../utils/models/TransactionState"
import LaunchPadStore, { LaunchingStatus } from "./LaunchPadStore"

class LaunchPadRefundModule {
  constructor(readonly store: LaunchPadStore) {
    makeObservable(this)
  }

  @computed get canRefund(): boolean {
    return (
      this.store.currentStatus$ === LaunchingStatus.Finished &&
      this.store.userIDO.lockedSTX$ > 0
    )
  }

  @observable running?: TransactionState

  @asyncAction async refund(run = runAsyncAction): Promise<void> {
    try {
      const { txId: transactionId } = await run(
        asSender(this.store.authStore.stxAddress$)
          .contract("alex-launchpad")
          .func("refund")
          .call([this.store.token, this.store.ticketToken$], {
            stxAmount: this.store.userIDO.lockedSTX$,
            lotteryTokenAmount: this.store.userIDO.lotteryToClaim$,
          }),
      )
      // this.running = { type: "running" }
      // const transaction = await run(
      //   retryWhenBlockChangedUntil(
      //     this.store.authStore,
      //     () => fetchTransaction(transactionId),
      //     tx => tx.tx_status !== "pending",
      //   ),
      // )
      // if (transaction.tx_status !== "success") {
      //   // noinspection ExceptionCaughtLocallyJS
      //   throw new TransactionError(transaction)
      // }
      this.running = {
        type: "success",
        explorerLink: EXPLORER_TX_URL(transactionId),
        telegramSubscribeLink: TG_SUB_URL(transactionId),
      }
    } catch (e) {
      if (e instanceof CancelError) {
        this.running = undefined
      } else if (e instanceof TransactionError) {
        this.running = {
          type: "error",
          error: e.message,
          explorerLink: EXPLORER_TX_URL(e.transaction.tx_id),
        }
      } else {
        this.running = {
          type: "error",
          error: (e as Error).message,
        }
      }
      console.error(e)
    }
  }

  @action closeRunning(): void {
    this.running = undefined
  }
}

export default LaunchPadRefundModule
