import clsx from "clsx"
import { FC, ReactNode } from "react"
import {
  FilledButton,
  RedFilledButton,
} from "../../../../components/button/FilledButton"
import { GradientButton } from "../../../../components/button/GradientButton/GradientButton"
import { HeadlessButton } from "../../../../components/button/HeadlessButton"
import {
  Card,
  CardDivider,
  CardInset,
  CardInsetDivider,
  CardInsetSubTitle,
  CardInsetTitle,
  CardTitle,
} from "../../../../components/Card"
import { CardModalContent } from "../../../../components/CardModal/CardModal"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { PercentNumber } from "../../../../components/PercentNumber"
import { Spensor } from "../../../../components/Spensor"
import { StepsBar } from "../../../../components/StepsBar/StepsBar"
import { BlockTimeCountdown } from "../../../../components/TimeCountdown/BlockTimeCountdown"
import { TimeCountdownText } from "../../../../components/TimeCountdown/TimeCountdownText"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenIcon, TokenIconProps } from "../../../../components/TokenIcon"
import { BalanceBottomArea } from "../../../../components/TokenInput/BalanceBottomArea"
import { TokenInput } from "../../../../components/TokenInput/TokenInput"
import { TokenInputGroup } from "../../../../components/TokenInput/TokenInputGroup"
import { TokenName } from "../../../../components/TokenName"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { withClassName } from "../../../../utils/reactHelpers/withClassName"
import {
  readResource,
  safeReadResource,
  suspenseResource,
  SuspenseResource,
} from "../../../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { LotteryTicket } from "../../store/ClaimViewModule"
import { LaunchingStatus } from "../../store/LaunchPadStore"
import {
  HorizontalInfoListContainer,
  HorizontalInfoListItem,
  VerticalInfoListContainer,
  VerticalInfoListItem,
} from "../InfoList"
import { NoteParagraph } from "../NoteParagraph/NoteParagraph"
import { TokenIDOSuccessInfo } from "../types"
import { ReactComponent as AfterRegisterIcon } from "./afterRegisterImage.svg"
import { ReactComponent as CanOnlyValidateOnceIcon } from "./canOnlyValidateOnce.svg"
import { ReactComponent as ConnectWalletIcon } from "./connectWalletImage.svg"
import { ReactComponent as FinishedFailedIcon } from "./finishedFailedImage.svg"
import { ReactComponent as FinishedSucceedIcon } from "./finishedSucceedImage.svg"
import { ReactComponent as LotteryForNotRegisteredIcon } from "./lotteryForNotRegisteredImage.svg"
import {
  LotteryTicketCard,
  LotteryTicketCardProps,
} from "./LotteryTicketCard/LotteryTicketCard"
import { RegisterFormError, RegisterFormErrorType } from "./types"

export const ActionSectionContent$BeforeRegister: FC<{
  formError?: SuspenseResource<undefined | RegisterFormError>
  lotteryTicketCount: number
  lotteryTicketBalance: SuspenseResource<number>
  stxCount: number
  stxBalance: SuspenseResource<number>
  stxToUSDT: SuspenseResource<number>
  pricePerTicket: SuspenseResource<number>
  onLotteryTicketCountChange?: (ticketCount: null | number) => void
  onPressRegister?: SuspenseResource<undefined | (() => void)>
}> = props => {
  return (
    <>
      <IDOSteps currentStatus={LaunchingStatus.Registration} />

      <TokenInputGroup
        firstInput={
          <TokenInput
            token={TokenInfoPresets.AlexLotteryTicket}
            error={
              safeReadResource(props.formError)?.type ===
              RegisterFormErrorType.InsufficientTokenBalance
            }
            value={props.lotteryTicketCount}
            onChange={props.onLotteryTicketCountChange}
            bottomArea={
              <BalanceBottomArea
                token={TokenInfoPresets.AlexLotteryTicket}
                balance={props.lotteryTicketBalance}
                error={
                  safeReadResource(props.formError)?.type ===
                  RegisterFormErrorType.InsufficientTokenBalance
                }
                onPressMax={suspenseResource(() => {
                  const balance = readResource(props.lotteryTicketBalance)
                  if (props.lotteryTicketCount === balance) return
                  if (!balance) return
                  return () => props.onLotteryTicketCountChange?.(balance)
                })}
              />
            }
          />
        }
        secondInput={
          <TokenInput
            readonly={true}
            token={TokenInfoPresets.STX}
            value={props.stxCount}
            error={
              safeReadResource(props.formError)?.type ===
              RegisterFormErrorType.InsufficientSTXBalance
            }
            bottomArea={
              <BalanceBottomArea
                token={TokenInfoPresets.STX}
                balance={props.stxBalance}
                error={
                  safeReadResource(props.formError)?.type ===
                  RegisterFormErrorType.InsufficientSTXBalance
                }
                estimatedUSD={props.stxToUSDT}
              />
            }
          />
        }
      />

      <dl className={"flex justify-between text-sm leading-5 font-normal"}>
        <dt className={"text-gray-400"}>Price</dt>
        <dd className={"text-gray-200"}>
          <Spensor fallback={"..."}>
            {() => (
              <>
                1 Ticket = Deposit {readResource(props.pricePerTicket)}&nbsp;
                <TokenName token={TokenInfoPresets.STX} />
              </>
            )}
          </Spensor>
        </dd>
      </dl>

      <Spensor
        fallback={<GradientButton disabled={true}>Validate</GradientButton>}
      >
        {() => {
          const formError = props.formError && readResource(props.formError)

          if (formError) {
            if (formError?.type !== RegisterFormErrorType.EmptyTokenCount) {
              return (
                <MainButton Variant={RedFilledButton} disabled={true}>
                  {formError.message}
                </MainButton>
              )
            } else {
              return (
                <MainButton Variant={GradientButton} disabled={true}>
                  {formError.message}
                </MainButton>
              )
            }
          }

          return (
            <MainButton
              Variant={GradientButton}
              onClick={
                props.onPressRegister && readResource(props.onPressRegister)
              }
            >
              Validate
            </MainButton>
          )
        }}
      </Spensor>

      <div className={"text-sm leading-5 flex flex-col items-center"}>
        <NoteParagraph>
          If the lottery is not won, the corresponding STX deposit will be
          refunded to you
        </NoteParagraph>
        <NoteParagraph>
          Lottery validation can only be submit once per wallet
        </NoteParagraph>
      </div>
    </>
  )
}

export const ActionSectionContent$Loading: FC = () => {
  return (
    <div className={"flex-1 flex items-center justify-center"}>
      <LoadingIndicator />
    </div>
  )
}

export const ActionSectionContent$ConnectWallet: FC<{
  currState: LaunchingStatus
  onConnect?: () => void
}> = props => {
  return (
    <>
      <IDOSteps currentStatus={props.currState} />

      <CardInset className={"flex flex-col items-center gap-6"}>
        <CardInsetTitle>Connect wallet to join IDO</CardInsetTitle>

        <ConnectWalletIcon />
      </CardInset>

      <MainButton Variant={GradientButton} onClick={props.onConnect}>
        Connect Wallet
      </MainButton>
    </>
  )
}

export const ActionSectionContent$Upcoming: FC = () => {
  return (
    <CardInset>
      <CardTitle className={"text-center"}>The project is upcoming</CardTitle>
    </CardInset>
  )
}

export const ActionSectionContent$AfterRegister: FC<{
  registeredLottery: number
  lockedSTX: number
  currentBlock: number
  startedAt: Date
  startedAtBlock: number
}> = props => {
  return (
    <>
      <IDOSteps currentStatus={LaunchingStatus.Registration} />

      <CardInset className={"flex flex-col items-center gap-6"}>
        <div className={"flex flex-col items-center gap-4"}>
          <AfterRegisterIcon />

          <CardInsetTitle>
            You have validated, Lottery will start in
          </CardInsetTitle>

          <div
            className={
              "bg-purple-600 rounded p-2.5 font-medium text-white text-center"
            }
          >
            <p className={"mx-4 text-xl leading-7"}>
              {props.startedAtBlock - props.currentBlock > 1 ? (
                <>
                  <TimeCountdownText time={props.startedAt} /> (Estimate)
                </>
              ) : (
                <>1 Block Remaining</>
              )}
            </p>
            <p className={"mx-4 text-base leading-6 opacity-50"}>
              start at: Block {props.startedAtBlock}
            </p>
          </div>
        </div>

        <CardInsetDivider />

        <HorizontalInfoListContainer>
          <HorizontalInfoListItem
            title={"Validated Lottery"}
            detail={props.registeredLottery}
          />
          <HorizontalInfoListItem
            title={"Locked STX"}
            detail={props.lockedSTX}
          />
        </HorizontalInfoListContainer>

        <NoteParagraph className={"text-center"}>
          If the lottery is not won, the corresponding STX deposit will be
          refunded to you
        </NoteParagraph>
      </CardInset>
    </>
  )
}

export const ActionSectionContent$ClaimForRegistered: FC<{
  token: TokenInfo
  lotteryTickets: LotteryTicket[]
  onOpenTicket?: LotteryTicketCardProps["onOpenTicket"]
  onRetryTicket?: LotteryTicketCardProps["onRetryTicket"]
}> = props => {
  return (
    <>
      <IDOSteps currentStatus={LaunchingStatus.Claim} />

      <CardInset className={"flex flex-col items-center gap-6"}>
        <CardTitle>Your Lottery Ticket</CardTitle>

        <ul className={"flex flex-wrap justify-center gap-2.5"}>
          {props.lotteryTickets.map((t, idx) => (
            <LotteryTicketCard
              key={idx}
              token={props.token}
              lotteryTicket={t}
              onOpenTicket={props.onOpenTicket}
              onRetryTicket={props.onRetryTicket}
            />
          ))}
        </ul>

        <NoteParagraph>
          Ticket claim may take time due to blockchain transaction approval.
        </NoteParagraph>
      </CardInset>
    </>
  )
}

export const ActionSectionContent$ClaimForNotRegistered: FC<{
  token: TokenInfo
  currentAllocationTokenCount: number
}> = props => {
  return (
    <>
      <IDOSteps currentStatus={LaunchingStatus.Claim} />

      <CardInset className={"flex flex-col items-center gap-6"}>
        <CardTitle>Project in lottery</CardTitle>

        <p
          className={
            "w-full text-2xl leading-8 font-normal text-gray-200 flex flex-wrap gap-5 items-center justify-center"
          }
        >
          Current allocation:
          <span className={"flex gap-2.5 items-center justify-center"}>
            <TokenIcon token={props.token} size={TokenIconProps.sizes.small} />
            <span className={"text-green-300"}>
              <TokenCount
                token={props.token}
                count={props.currentAllocationTokenCount}
              />
            </span>
            <TokenName className={"text-green-300"} token={props.token} />
          </span>
        </p>

        <div className={"flex items-center justify-center relative w-full"}>
          <LotteryForNotRegisteredIcon />
          <TokenIcon
            className={
              "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            }
            token={props.token}
            size={TokenIconProps.sizes.large}
          />
        </div>

        <CardInsetSubTitle>
          You did not join the <TokenName token={props.token} /> project IDO
        </CardInsetSubTitle>
      </CardInset>
    </>
  )
}

export const ActionSectionContent$FinishedFailed: FC<{
  userAssets?: {
    registeredLottery: number
    lockedSTX: number
  }
  onClaimSTX?: () => void
}> = props => {
  return (
    <>
      <CardInset className={"flex flex-col items-center gap-6"}>
        <div className={"flex flex-col items-center gap-4"}>
          <FinishedFailedIcon />

          <CardInsetTitle>The project was failed to launch</CardInsetTitle>

          <CardInsetSubTitle>
            Please look forward to other projects
          </CardInsetSubTitle>
        </div>

        {props.userAssets && (
          <>
            <CardInsetDivider />

            <HorizontalInfoListContainer>
              <HorizontalInfoListItem
                title={"Validated Lottery"}
                detail={props.userAssets.registeredLottery}
              />
              <HorizontalInfoListItem
                title={"Locked STX"}
                detail={props.userAssets.lockedSTX}
              />
            </HorizontalInfoListContainer>
          </>
        )}
      </CardInset>

      {props.userAssets && (
        <>
          <GradientButton className={"w-full"} onClick={props.onClaimSTX}>
            Claim STX
          </GradientButton>

          <NoteParagraph className={"self-center w-fit-content"}>
            Your locked STX will return to your wallet.
          </NoteParagraph>
        </>
      )}
    </>
  )
}

export const ActionSectionContent$FinishedSucceed: FC<{
  token: TokenInfo
  tokenIDOSuccessSummary: TokenIDOSuccessInfo
  onClaimRemainingLockedSTX?: () => void
}> = props => {
  return (
    <CardInset className={"flex flex-col items-center gap-6"}>
      <div className={"flex flex-col items-center gap-4"}>
        <FinishedSucceedIcon />

        <CardInsetTitle>
          <TokenName token={props.token} /> Token Launched
        </CardInsetTitle>
      </div>

      <VerticalInfoListContainer className={"w-full px-4 md:px-24 py-6"}>
        <VerticalInfoListItem
          title="Filled"
          detail={
            <PercentNumber number={props.tokenIDOSuccessSummary.fillRate} />
          }
        />

        <VerticalInfoListItem
          title="Total Allocation"
          detail={
            <>
              <TokenIcon className={"mr-2"} token={props.token} size={24} />
              <TokenCount
                token={props.token}
                count={props.tokenIDOSuccessSummary.totalAllocation}
              />
              &nbsp;
              <TokenName token={props.token} />
            </>
          }
        />

        <VerticalInfoListItem
          title="Total Lottery"
          detail={
            <>
              <TokenCount
                token={TokenInfoPresets.AlexLotteryTicket}
                count={props.tokenIDOSuccessSummary.currentActivation}
              />
              &nbsp; Tickets
            </>
          }
        />
      </VerticalInfoListContainer>
      <div>
        <NoteParagraph>
          <TokenName token={props.token} /> IDO completed, all unclaimed lottery
          tickets are expired.
        </NoteParagraph>

        {props.onClaimRemainingLockedSTX && (
          <HeadlessButton
            className={"text-sm leading-5 font-normal text-blue-500"}
            onClick={props.onClaimRemainingLockedSTX}
          >
            Claim expired tickets
          </HeadlessButton>
        )}
      </div>
    </CardInset>
  )
}

const IDOSteps: FC<{
  className?: string
  currentStatus: LaunchingStatus
}> = props => (
  <StepsBar
    className={props.className}
    current={
      props.currentStatus === LaunchingStatus.Upcoming
        ? 1
        : props.currentStatus === LaunchingStatus.Registration
        ? 2
        : 3
    }
    steps={[
      { aboveText: "Upcoming", belowText: "Preparation" },
      { aboveText: "Jan 3", belowText: "Exchange Ticket" },
      { aboveText: "Jan 10", belowText: "Validate" },
      { aboveText: "Jan 17", belowText: "Lottery" },
    ]}
  />
)

export const RegisterConfirmModalContent: FC<{
  lotteryTicketCount: number
  stxCount: number
  onConfirm?: () => void
  onClose?: () => void
}> = props => {
  return (
    <CardModalContent onClose={props.onClose} title={"Validate"}>
      <p className={"text-gray-200 text-center"}>
        Please confirm your validation details
      </p>

      <CardDivider />

      <RegisterConfirmModalTokenLine
        token={TokenInfoPresets.AlexLotteryTicket}
        precision={0}
        count={props.lotteryTicketCount}
      />
      <RegisterConfirmModalTokenLine
        token={TokenInfoPresets.STX}
        precision={2}
        count={props.stxCount}
      />

      <CardDivider />

      <NoteParagraph
        textColorClassName={"text-yellow-500"}
        icon={<CanOnlyValidateOnceIcon />}
      >
        Lottery validation can only be submit once per wallet
      </NoteParagraph>

      <GradientButton className={"block w-full"} onClick={props.onConfirm}>
        Confirm
      </GradientButton>
    </CardModalContent>
  )
}
const RegisterConfirmModalTokenLine: FC<{
  token: TokenInfo
  precision: number
  count: number
}> = props => (
  <p className={"text-gray-200 flex items-center"}>
    <TokenIcon className={"mr-2.5"} token={props.token} />

    <TokenName
      className={"text-xl leading-7 font-medium mr-auto"}
      token={props.token}
    />

    <span className={"text-3xl leading-9 font-medium"}>
      <TokenCount token={props.token} count={props.count} />
    </span>
  </p>
)

export const ActionSectionFrame: FC<{
  className?: string
  titleRightSide?: ReactNode
}> = props => (
  <Card className={clsx(props.className, "flex flex-col gap-4")}>
    <div className={"flex items-center justify-between"}>
      <CardTitle>Schedule</CardTitle>
      {props.titleRightSide}
    </div>

    {props.children}
  </Card>
)

const MainButton = withClassName("block mt-3.5 mb-2", FilledButton)

export const StepEndCountdown: FC<{
  className?: string
  currentStepNumber: number
  currentBlock: number
  currentBlockEndedAt: Date
  endBlock: number
}> = props => (
  <Tooltip
    title={`Current Block: ${props.currentBlock}\nStep ${props.currentStepNumber} Ended At: Block ${props.endBlock}`}
  >
    <BlockTimeCountdown
      prefix={"Step Countdown"}
      currentBlock={props.currentBlock}
      targetBlock={props.endBlock}
      time={props.currentBlockEndedAt}
    />
  </Tooltip>
)
