/* eslint-disable import/no-webpack-loader-syntax */

// @ts-ignore
import alexTokenIntroHtml from "markdown-loader!./alexIntro.md?raw"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import alexTokenLogoSrc from "./alexTokenLogo.svg"

const alex = {
  tokenInfo: TokenInfoPresets.ALEX,
  introHtml: alexTokenIntroHtml,
  logoSrc: alexTokenLogoSrc,
  website: "https://www.alexgo.io/",
  twitter: "https://twitter.com/alexgoBtc",
  medium: "https://medium.com/alexgobtc",
  discord: "https://discord.gg/alexgo",
}

export const launchMeta = {
  "age000-governance-token": alex,
}
