/**
 * Decimal adjustment of a number.
 *
 * from {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor#decimal_adjustment}
 *
 * @param {String} type  The type of adjustment.
 * @param {Number} value The number.
 * @param {Integer} exp The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number} The adjusted value.
 */
export function decimalAdjust(
  type: "ceil" | "floor" | "round",
  value: number,
  exp?: number,
): number {
  // If the exp is undefined or zero...
  if (exp == null || +exp === 0) return Math[type](value)

  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || exp % 1 !== 0) return NaN

  // Shift (transform `111` to `Math.[method].(Number(111e+-exp))`)
  let splitValue = value.toString().split("e")
  value = Math[type](
    Number(
      splitValue[0] +
        "e" +
        (splitValue[1] ? Number(splitValue[1]) - exp : -exp),
    ),
  )

  // Shift back, same as above
  splitValue = value.toString().split("e")
  return Number(
    splitValue[0] + "e" + (splitValue[1] ? Number(splitValue[1]) + exp : exp),
  )
}

export function closeTo(a: number, b: number, epsilon = 0.0001): boolean {
  return Math.abs(a - b) < epsilon
}

/**
 * Math.pow(0.1, 4)          // -> 0.00010000000000000002
 * Math.pow(10, -4)          // -> 0.00009999999999999999
 * getPrecisionEdgeNumber(4) // -> 0.0001
 */
export function getPrecisionFloor(precision: number): number {
  return 1 / Math.pow(10, precision)
}
