import { standardPrincipalCV, uintCV } from "@stacks/transactions"
import {
  currencyContract,
  defineContract,
  intListResult,
  intResult,
  optional,
  tupleDecoder,
} from "./contractBase"

const getTokenGivenPositionResult = tupleDecoder({
  "dy-act": intResult,
})
const getYTPDetailResult = tupleDecoder({
  "total-supply": intResult,
})

const getYTPPositionGivenBurnResult = tupleDecoder({
  dx: intResult,
  "dy-act": intResult,
})

export const liquidityMiningContracts = defineContract({
  yield_token_template: {
    "get-decimals": {
      input: [uintCV],
      output: intResult,
    },
    "get-token-owned": {
      input: [standardPrincipalCV],
      output: intListResult,
    },
    "get-balance": {
      input: [uintCV, standardPrincipalCV],
      output: intResult,
    },
  },
  "yield-token-pool": {
    "add-to-position": {
      input: [
        uintCV, //expiry
        currencyContract, // x
        currencyContract, // y
        currencyContract, // pool token
        uintCV, // amount
        optional(uintCV), // slippage
      ],
      mode: "public",
    },
    "reduce-position": {
      input: [
        uintCV, //expiry
        currencyContract, // x
        currencyContract, // y
        currencyContract, // pool token
        uintCV, // percentage
      ],
      mode: "public",
    },
    "get-price": {
      input: [uintCV, currencyContract],
      output: intResult,
    },
    "get-token-given-position": {
      input: [uintCV, currencyContract, uintCV],
      output: getTokenGivenPositionResult,
    },
    "get-position-given-burn": {
      input: [uintCV, currencyContract, uintCV],
      output: getYTPPositionGivenBurnResult,
    },
    "get-pool-details": {
      input: [uintCV, currencyContract],
      output: getYTPDetailResult,
    },
  },
} as const)
