import clsx from "clsx"
import React, { FC, useMemo, useRef } from "react"
import { useLocation } from "react-router"
import { useParams } from "react-router-dom"
import { NavLink } from "../../components/NavLink"
import {
  PageStackPage,
  PrimaryPageTitle,
} from "../../components/PageStack/PageStackPage/PageStackPage"
import { TransactionStateDialog } from "../../components/TransactionStateDialog/TransactionStateDialog"
import { contractVariantsForTesting } from "../../utils/alexjs/asSender"
import { Currency } from "../../utils/alexjs/contractBase"
import { setCachePrefix } from "../../utils/persistCache"
import { ReactComponent as HowLaunchpadWorksVideoIcon } from "./component/assets/howLaunchpadWorksVideoIcon.svg"
import { IDOIntroRefValue, WiredIDOIntro } from "./component/IDOIntro/IDOIntro"
import { WiredTokenProfileSection } from "./component/TokenProfileSection/TokenProfileSection"
import { WiredUserIDOStatusSection } from "./component/UserIDOStatusSection/UserIDOStatusSection"
import { LaunchPadProvider, useLaunchPadStore } from "./store/useLaunchPadStore"
import { WiredActionSection } from "./WiredActionSection"

const WiredLaunchpadContentImpl: FC = () => {
  const idoIntroSectionRef = useRef<IDOIntroRefValue>(null)

  return (
    <div className={"grid grid-cols-12 grid-rows-1 gap-y-8 lg:gap-8 mb-8"}>
      <div
        className={clsx("col-span-12", "lg:col-span-5", "flex flex-col gap-8")}
      >
        <WiredTokenProfileSection
          onScrollToTokenIntro={() => {
            idoIntroSectionRef.current?.scrollTo()
          }}
          className={"lg:row-start-1"}
        />

        <WiredUserIDOStatusSection className={"flex-1"} />
      </div>

      <WiredActionSection
        className={clsx(
          "col-span-12",
          "lg:col-start-6 lg:col-span-7 lg:row-start-1",
        )}
      />

      <WiredIDOIntro ref={idoIntroSectionRef} className={clsx("col-span-12")} />
    </div>
  )
}

const WiredLaunchpadContent: FC = () => {
  return (
    <PageStackPage
      title={<PrimaryPageTitle>alex launchpad</PrimaryPageTitle>}
      titleRight={
        <NavLink
          className={
            "text-base leading-6 font-normal text-gray-100 flex items-center"
          }
          to={
            "https://www.youtube.com/embed/KH5oNGD62nA?controls=1&autoplay=1&rel=0"
          }
        >
          <HowLaunchpadWorksVideoIcon className={"mr-3"} />
          How does the Launchpad work?
        </NavLink>
      }
    >
      <WiredLaunchpadContentImpl />
      <WiredTXInfoAlert />
    </PageStackPage>
  )
}

const WiredTXInfoAlert: FC = () => {
  const store = useLaunchPadStore()
  return (
    <TransactionStateDialog
      getTransactionState={() => store.register.running ?? store.refund.running}
      onClose={() => {
        store.register.closeRunning()
        store.refund.closeRunning()
      }}
    />
  )
}

const LaunchpadPage: FC = () => {
  console.log(useParams())
  const { search } = useLocation()
  useMemo(() => {
    //for testing
    const contractName = new URLSearchParams(search).get("contractName")
    if (contractName) {
      contractVariantsForTesting["alex-launchpad"] = contractName
      // otherwise, the cache will collide with the real one
      setCachePrefix(`alex-launchpad-${contractName}`)
    }
  }, [search])
  return (
    <LaunchPadProvider token={Currency.Alex}>
      <WiredLaunchpadContent />
    </LaunchPadProvider>
  )
}

export default LaunchpadPage
