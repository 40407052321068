import type {
  FungiblePostCondition,
  STXPostCondition,
} from "@stacks/transactions"
import {
  createAssetInfo,
  FungibleConditionCode,
  makeContractFungiblePostCondition,
  makeContractSTXPostCondition,
  makeStandardFungiblePostCondition,
  makeStandardSTXPostCondition,
} from "@stacks/transactions"
import { CONTRACT_DEPLOYER, IS_MAIN_NET } from "../../../config"
import { Currency } from "../contractBase"

export const getLaunchpadRegisterPCs = (
  senderAddress: string,
  launchpadPCArgs: {
    govToken: Currency
    lotteryToken: Currency
    lotteryTokenAmount: number
    stxAmount: number
  },
): Array<FungiblePostCondition | STXPostCondition> => {
  const { lotteryToken, lotteryTokenAmount, stxAmount } = launchpadPCArgs
  return [
    getStandardFungiblePC(senderAddress, lotteryToken, lotteryTokenAmount),
    getStandardSTXPC(senderAddress, stxAmount),
  ]
}

export const getLaunchpadClaimPCs = (claimPCArgs: {
  alexToken: string
  stxAmount: number
  lotteryToken: string
  lotteryTokenAmount: number
}): Array<FungiblePostCondition | STXPostCondition> => {
  const { alexToken, stxAmount, lotteryToken, lotteryTokenAmount } = claimPCArgs
  return [
    getContractSTXPC(stxAmount),
    getContractFungiblePC(lotteryToken, lotteryTokenAmount),
    getAlexLotteryPC(alexToken),
  ]
}

export const getLaunchpadRefundPCs = (refundPCArgs: {
  stxAmount: number
  lotteryToken: string
  lotteryTokenAmount: number
}): Array<FungiblePostCondition | STXPostCondition> => {
  const { stxAmount, lotteryToken, lotteryTokenAmount } = refundPCArgs
  return [
    getContractSTXPC(stxAmount),
    getContractFungiblePC(lotteryToken, lotteryTokenAmount),
  ]
}

const getStandardFungiblePC = (
  senderAddress: string,
  lotteryToken: Currency,
  amount: number,
): FungiblePostCondition => {
  const code = FungibleConditionCode.Equal
  const assetAddress = CONTRACT_DEPLOYER
  const assetContractName = lotteryToken
  const assetName = lotteryToken
  const fungibleAssetInfo = createAssetInfo(
    assetAddress,
    assetContractName,
    assetName,
  )
  return makeStandardFungiblePostCondition(
    senderAddress,
    code,
    (amount as number) * 1e8,
    fungibleAssetInfo,
  )
}

const getStandardSTXPC = (
  senderAddress: string,
  amount = 0,
): STXPostCondition => {
  const code = FungibleConditionCode.Equal
  return makeStandardSTXPostCondition(senderAddress, code, amount * 1e6)
}

const getContractSTXPC = (amount = 0): STXPostCondition => {
  const code = FungibleConditionCode.Equal
  const contractAddress = CONTRACT_DEPLOYER
  const contractName = getLaunchpadName()

  return makeContractSTXPostCondition(
    contractAddress,
    contractName as string,
    code,
    (amount as number) * 1e6,
  )
}

const getContractFungiblePC = (
  lotteryToken: string,
  lotteryTokenAmount: number,
): FungiblePostCondition => {
  const code = FungibleConditionCode.Equal
  const contractAddress = CONTRACT_DEPLOYER
  const contractName = getLaunchpadName()
  const assetAddress = CONTRACT_DEPLOYER
  const assetContractName = lotteryToken
  const assetName = lotteryToken
  const fungibleAssetInfo = createAssetInfo(
    assetAddress,
    assetContractName,
    assetName,
  )
  return makeContractFungiblePostCondition(
    contractAddress,
    contractName as string,
    code,
    (lotteryTokenAmount as number) * 1e8,
    fungibleAssetInfo,
  )
}

const getAlexLotteryPC = (alexToken: string): FungiblePostCondition => {
  const code = FungibleConditionCode.GreaterEqual
  const contractAddress = CONTRACT_DEPLOYER
  const contractName = getLaunchpadName()
  const assetAddress = CONTRACT_DEPLOYER
  const assetContractName = alexToken
  const assetName = "alex"
  const fungibleAssetInfo = createAssetInfo(
    assetAddress,
    assetContractName,
    assetName,
  )
  return makeContractFungiblePostCondition(
    contractAddress,
    contractName as string,
    code,
    0,
    fungibleAssetInfo,
  )
}
//Must differentiate between test launchpad and mainnet launchpad
const getLaunchpadName = (): string => {
  return IS_MAIN_NET
    ? "alex-launchpad"
    : (window.location.href.split("=")[1] as string)
}
