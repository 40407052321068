import clsx from "clsx"
import { FC } from "react"
import { HeadlessButton } from "../HeadlessButton"
import * as defaultStyles from "./defaultStyles"

export interface BaseFilledButtonProps {
  className?: string
  bgClassName?: string
  boxClassName?: string
  textClassName?: string
  disabled?: boolean
  onClick?: () => void
}

export const BaseFilledButton: FC<BaseFilledButtonProps> = props => {
  return (
    <HeadlessButton
      className={clsx(
        `rounded-full disabled:text-opacity-30`,
        props.boxClassName ?? defaultStyles.medium.boxClassName,
        props.textClassName ?? defaultStyles.medium.textClassName,
        props.bgClassName,
        props.className,
      )}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </HeadlessButton>
  )
}
