import {
  MempoolTransaction,
  Transaction,
  TransactionResults,
} from "@stacks/stacks-blockchain-api-types"
import { range } from "lodash"
import { API_HOST } from "../../config"

export async function fetchNewTransactions(
  stxAddress: string,
  existing = 0,
): Promise<Transaction[]> {
  const first: TransactionResults = await fetch(
    `${API_HOST}/extended/v1/address/${stxAddress}/transactions?limit=5`,
  ).then(r => (r.ok ? r.json() : Promise.reject(new Error(r.statusText))))
  if (first.total === existing) {
    return []
  }
  if (first.total - existing <= first.results.length) {
    return first.results.slice(0, first.total - existing)
  }
  const remainingCount = first.total - first.results.length - existing
  const remaining = await Promise.all(
    range(Math.ceil(remainingCount / 50)).map(i => {
      return fetch(
        `${API_HOST}/extended/v1/address/${stxAddress}/transactions?offset=${
          first.results.length + 50 * i
        }&limit=50`,
      )
        .then(r => (r.ok ? r.json() : Promise.reject(new Error(r.statusText))))
        .then((r: TransactionResults) => r.results)
    }),
  ).then(r => r.flat())
  return [...first.results, ...remaining]
}

export async function fetchMemPoolTransactions(
  stxAddress: string,
): Promise<MempoolTransaction[]> {
  const response = await fetch(
    `${API_HOST}/extended/v1/address/${stxAddress}/mempool?limit=50`,
  ).then(r => (r.ok ? r.json() : Promise.reject(new Error(r.statusText))))
  return response.results
}
