import { FC } from "react"

export interface PercentNumberProps extends PercentNumberFormatOptions {
  number: number
}

export const PercentNumber: FC<PercentNumberProps> = props => {
  return (
    <>
      {formatPercentNumber(props.number, {
        precision: props.precision,
      })}
    </>
  )
}

export interface PercentNumberFormatOptions {
  /**
   * @default 2
   */
  precision?: number
}

export function formatPercentNumber(
  number: number,
  options: PercentNumberFormatOptions = {},
): string {
  const { precision = 2 } = options
  if (number === 0) {
    return "0%"
  }
  if (number < 0.00001) {
    return "≤ 0.00001%"
  }

  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: precision,
  }).format(number)
}
