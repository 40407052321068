import clsx from "clsx"
import { ComponentType, FC } from "react"
import { BaseFilledButton } from "./_/BaseFilledButton"
import * as btnPresets from "./_/defaultStyles"

export { btnPresets }

export interface FilledButtonVariantProps {
  className?: string
  boxClassName?: string
  textClassName?: string
  disabled?: boolean
  onClick?: () => void
}

export interface FilledButtonProps extends FilledButtonVariantProps {
  Variant?: ComponentType<FilledButtonVariantProps>
}
export const FilledButton: FC<FilledButtonProps> = props => {
  const { Variant = WhiteFilledButton, ...restProps } = props
  return <Variant {...restProps}>{props.children}</Variant>
}

export const WhiteFilledButton: FC<FilledButtonVariantProps> = props => (
  <BaseFilledButton
    {...props}
    className={clsx("text-gray-900", props.className)}
    bgClassName={
      "bg-gray-100 hover:bg-gray-300 active:bg-gray-400 disabled:bg-gray-400"
    }
  >
    {props.children}
  </BaseFilledButton>
)

export const RedFilledButton: FC<FilledButtonVariantProps> = props => (
  <BaseFilledButton
    {...props}
    className={clsx("text-gray-100", props.className)}
    bgClassName={
      "bg-red-500 hover:bg-red-600 active:bg-red-700 disabled:bg-red-700"
    }
  >
    {props.children}
  </BaseFilledButton>
)
