import { Dialog as HeadlessDialog, Transition } from "@headlessui/react"
import { noop } from "lodash"
import { FC, Fragment } from "react"

export interface DialogProps {
  visible: boolean
  onClose?: () => void
}

export const Dialog: FC<DialogProps> = props => (
  <Transition appear show={props.visible} as={Fragment}>
    <HeadlessDialog
      className={"fixed inset-0 z-10 overflow-y-auto"}
      as={"div"}
      onClose={props.onClose ?? noop}
    >
      <div className="min-h-screen p-4 flex items-center justify-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <HeadlessDialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <Transition.Child
          as={"div"}
          className={"transform-gpu transition-transform min-w-0"}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {props.children}
        </Transition.Child>
      </div>
    </HeadlessDialog>
  </Transition>
)
