import { createContext, FC, ReactNode, useContext } from "react"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { AppEnvStore } from "./AppEnvStore"

const AppEnvStoreContext = createContext<AppEnvStore | null>(null)

export const AppEnvStoreProvider: FC<{
  children: ReactNode
}> = props => {
  const store = useCreation(() => new AppEnvStore(), [])

  return (
    <AppEnvStoreContext.Provider value={store}>
      {props.children}
    </AppEnvStoreContext.Provider>
  )
}

export const useAppEnvStore = (): AppEnvStore => {
  const store = useContext(AppEnvStoreContext)
  if (store == null) {
    throw new Error("useAppEnvStore must be used within a AuthStoreProvider")
  }
  return store
}
