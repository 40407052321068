export type RoutePath = typeof RoutePath[keyof typeof RoutePath]

const FarmPrefix = "farm"
const PoolPrefix = "pool"
const AnalyticsPrefix = "analytics"

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace RoutePath {
  export const Faucet = "faucet"
  export const Stake = "stake"
  export const DepositBorrow = "deposit-borrow"
  export const Swap = "swap"
  export const Liquidity = "liquidity"
  export const Margin = "margin"
  export const Launchpad = "launchpad"

  export const Pool = `${PoolPrefix}` as const
  export const PoolDetail = `${PoolPrefix}/:token` as const

  export const Farm = `${FarmPrefix}` as const
  export const FarmDetail = `${FarmPrefix}/:token` as const

  export const AnalyticsStakingFarming =
    `${AnalyticsPrefix}/staking_farming` as const
  export const AnalyticsALEXPool = `${AnalyticsPrefix}/alex_pool` as const
  export const AnalyticsBTCPool = `${AnalyticsPrefix}/btc_pool` as const
}

export const RoutePathDefault = RoutePath.Swap
