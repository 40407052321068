import { Currency, currencyContractMap } from "../contractBase"

export const currencyTxnData = (
  currency: Currency,
): {
  assetAddress: string
  assetContractName: string
  assetName: string
} => {
  const [assetAddress, assetContractName, assetName] = currencyContractMap[
    currency
  ].split(/\.|::/) as [string, string, string]
  return {
    assetAddress,
    assetContractName,
    assetName,
  }
}
