import {
  add,
  differenceInDays,
  formatDuration as _formatDuration,
  intervalToDuration,
} from "date-fns"
import { omit } from "lodash"
import { FC, useCallback, useEffect, useState } from "react"

export type FormatOptions = Parameters<typeof _formatDuration>[1]

/**
 * convert `duration` to `xxx days xxx hours xxx minutes xxx seconds`
 */
export const longFormatDuration = (
  duration: Duration,
  options?: FormatOptions,
): string => {
  if (
    duration.years != null ||
    duration.months != null ||
    duration.weeks != null
  ) {
    const nowDate = new Date()
    const futureDate = add(nowDate, duration)
    const durationDayCount = differenceInDays(futureDate, nowDate)

    duration = omit(duration, ["years", "months", "weeks"])
    duration.days = durationDayCount
  }

  if (duration.seconds != null) {
    duration = omit(duration, ["seconds"])
  }

  return _formatDuration(duration, options)
}

/**
 * convert `duration` to `xxx d xxx h xxx m xxx s`
 */
export const shortFormatDuration = (
  duration: Duration,
  options?: FormatOptions,
): string => {
  const res = longFormatDuration(duration, options)

  return res
    .replace("days", "d")
    .replace("day", "d")
    .replace("hours", "h")
    .replace("hour", "h")
    .replace("minutes", "m")
    .replace("minute", "m")
}

export const TimeCountdownText: FC<{
  time: Date
  format?: (duration: Duration) => string
}> = props => {
  const getResult = useCallback(
    () =>
      formatTimeCountdownText(props.time, {
        format: props.format,
      }),
    [props.format, props.time],
  )

  const [result, setResult] = useState(getResult)

  useEffect(() => {
    const timer = setInterval(() => {
      setResult(getResult)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [getResult])

  return <>{result}</>
}

export function formatTimeCountdownText(
  time: Date,
  options: { format?: (duration: Duration) => string } = {},
): string {
  if (Date.now() >= time.getTime()) return "<1M"

  const format = options.format ?? longFormatDuration

  const duration = intervalToDuration({
    start: new Date(),
    end: time,
  })

  return format(duration) || "<1M"
}
