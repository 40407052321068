const listeners = new Set<() => void>()

let ready = false
window.addEventListener(
  "load",
  () => {
    ready = true

    listeners.forEach(f => f())
    listeners.clear()
  },
  { once: true },
)

export function untilWindowLoad(): Promise<void> {
  if (ready) return Promise.resolve()

  return new Promise(r => {
    listeners.add(r)
  })
}
