/* eslint-disable @typescript-eslint/no-var-requires */

import { Currency } from "../alexjs/contractBase"
import { TokenInfo } from "../models/TokenInfo"
import { assertNever } from "../types"

export namespace TokenInfoPresets {
  export const toCurrency = (token: TokenInfo): Currency => {
    const currency = token.name as Currency

    if (!Object.values(Currency).includes(currency)) {
      throw new Error(`Unknown currency ${token.name}`)
    }

    return currency
  }

  export const fromCurrency = (token: Currency): TokenInfo => {
    switch (token) {
      case Currency.Alex:
        return TokenInfoPresets.ALEX
      case Currency.AlexLottery:
        return TokenInfoPresets.AlexLotteryTicket
      case Currency.APower:
        return TokenInfoPresets.APower
      case Currency.STX:
        return TokenInfoPresets.STX
      case Currency.WBTC:
        return TokenInfoPresets.WBTC
      case Currency.USDA:
        return TokenInfoPresets.USDA
      case Currency.FWP_WSTX_ALEX_50_50_V1_01:
        return TokenInfoPresets.FWP_WSTX_ALEX_50_50_V1_01
      case Currency.FWP_WSTX_WBTC_50_50_V1_01:
        return TokenInfoPresets.FWP_WSTX_WBTC_50_50_V1_01
      default:
        assertNever(token)
    }
  }

  export const ALEX: TokenInfo = {
    name: Currency.Alex,
    displayName: "ALEX",
    description: "ALEX Token",
    precision: 4,
    iconSrc: require("./alex.svg").default,
  }

  export const APower: TokenInfo = {
    name: Currency.APower,
    displayName: "APower",
    precision: 4,
    iconSrc: require("./apower.svg").default,
  }

  export const STX: TokenInfo = {
    name: Currency.STX,
    displayName: "STX",
    description: "Stacks Token",
    precision: 4,
    iconSrc: require("./STX.svg").default,
  }

  export const WBTC: TokenInfo = {
    name: Currency.WBTC,
    displayName: "XBTC",
    description: "Wrapped Bitcoin",
    precision: 5,
    iconSrc: require("./XBTC.svg").default,
  }

  export const USD: TokenInfo = {
    name: Currency.USDA,
    precision: 2,
    iconSrc: require("./USDA.svg").default,
  }

  export const USDA: TokenInfo = {
    name: Currency.USDA,
    displayName: "USDA",
    precision: USD.precision,
    iconSrc: require("./USDA.svg").default,
  }

  export const AlexLotteryTicket: TokenInfo = {
    name: Currency.AlexLottery,
    displayName: "ALEX IDO Ticket",
    iconSrc: require("./lotteryTicket.svg").default,
  }

  export const FWP_WSTX_ALEX_50_50_V1_01: TokenInfo = {
    name: Currency.FWP_WSTX_ALEX_50_50_V1_01,
    displayName: "STX-ALEX-50-50",
    precision: STX.precision,
    type: "combined",
    combinedTokens: [STX, ALEX],
  }

  export const FWP_WSTX_WBTC_50_50_V1_01: TokenInfo = {
    type: "combined",
    name: Currency.FWP_WSTX_WBTC_50_50_V1_01,
    displayName: "STX-XBTC-50-50",
    precision: STX.precision,
    combinedTokens: [STX, WBTC],
  }
}
