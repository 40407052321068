import { action, computed, makeObservable, observable } from "mobx"
import { EXPLORER_TX_URL, TG_SUB_URL } from "../../../config"
import { asSender } from "../../../utils/alexjs/asSender"
import { Currency } from "../../../utils/alexjs/contractBase"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { CancelError } from "../../../utils/error"
import {
  TransactionError,
  TransactionState,
} from "../../../utils/models/TransactionState"
import { Result } from "../../../utils/Result"
import {
  RegisterFormError,
  RegisterFormErrorType,
} from "../component/ActionSection/types"
import LaunchPadStore from "./LaunchPadStore"

export interface RegisterFormData {
  ticketCountToRegister: number
}

class LaunchPadRegisterModule {
  constructor(readonly store: LaunchPadStore) {
    makeObservable(this)
  }

  @observable running?: TransactionState

  @computed get formData(): Result<RegisterFormData, RegisterFormError> {
    if (this.ticketToRegister <= 0) {
      return Result.error({
        type: RegisterFormErrorType.EmptyTokenCount,
        message: "Enter an amount",
      })
    }

    if (this.ticketToRegister > this.store.userIDO.ticketCount$) {
      return Result.error({
        type: RegisterFormErrorType.InsufficientTokenBalance,
        message: "Insufficient balance",
      })
    }

    if (
      this.willLockedSTXAmount$ >
      this.store.accountStore.getBalance$(Currency.STX)
    ) {
      return Result.error({
        type: RegisterFormErrorType.InsufficientSTXBalance,
        message: "Insufficient balance",
      })
    }

    return Result.ok({ ticketCountToRegister: this.ticketToRegister })
  }

  @asyncAction async submitForm(
    formData: RegisterFormData,
    run = runAsyncAction,
  ): Promise<void> {
    try {
      const { txId: transactionId } = await run(
        asSender(this.store.authStore.stxAddress$)
          .contract("alex-launchpad")
          .func("register")
          .call(
            [
              this.store.token,
              this.store.ticketToken$,
              formData.ticketCountToRegister,
            ],
            { pricePerTicket: this.store.tokenProfile.pricePerTicket },
          ),
      )
      // note(@zhigang1992): skip waiting for now
      // this.registerRunning = { type: "running" }
      // const transaction = await run(
      //   retryWhenBlockChangedUntil(
      //     this.store.authStore,
      //     () => fetchTransaction(transactionId),
      //     tx => tx.tx_status !== "pending",
      //   ),
      // )
      // if (transaction.tx_status !== "success") {
      //   // noinspection ExceptionCaughtLocallyJS
      //   throw new TransactionError(transaction)
      // }
      this.running = {
        type: "success",
        explorerLink: EXPLORER_TX_URL(transactionId),
        telegramSubscribeLink: TG_SUB_URL(transactionId),
      }
    } catch (e) {
      if (e instanceof CancelError) {
        this.running = undefined
      } else if (e instanceof TransactionError) {
        this.running = {
          type: "error",
          error: e.message,
          explorerLink: EXPLORER_TX_URL(e.transaction.tx_id),
        }
      } else {
        this.running = {
          type: "error",
          error: (e as Error).message,
        }
      }
      console.error(e)
    }
  }

  @action closeRunning(): void {
    this.running = undefined
  }

  @observable ticketToRegister = 0

  @action setTicketToRegister(amount: number): void {
    this.ticketToRegister = amount
  }

  @computed get willLockedSTXAmount$(): number {
    return (
      this.store.tokenProfile.pricePerToken *
      this.store.tokenProfile.tokenAmountPerTicket *
      this.ticketToRegister
    )
  }

  @computed get willLockSTXEstimatedUSD$(): number {
    return (
      this.store.currencyStore.getPrice$(Currency.STX) *
      this.willLockedSTXAmount$
    )
  }
}

export default LaunchPadRegisterModule
