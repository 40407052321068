import clsx from "clsx"
import { FC } from "react"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { readResource, SuspenseResource } from "../../utils/SuspenseResource"
import { TokenInfoPresets } from "../../utils/TokenInfoPresets/TokenInfoPresets"
import { OpacityButton } from "../button/OpacityButton"
import { Spensor } from "../Spensor"
import { TokenCount } from "../TokenCount"
import { Truncatable } from "../Truncatable"
import { BlockBottomLine } from "./Block"

export const BalanceBottomArea: FC<{
  token?: TokenInfo
  balance: SuspenseResource<number>
  error?: boolean
  estimatedUSD?: SuspenseResource<number>
  onPressMax?: SuspenseResource<undefined | (() => void)>
}> = props => {
  return (
    <BlockBottomLine className={"flex"}>
      <BalanceCount token={props.token} balance={props.balance} />

      <Spensor>
        {() => {
          const onPressMax = props.onPressMax && readResource(props.onPressMax)
          return (
            onPressMax && (
              <MaxButton
                className={"mx-1 sm:mx-2.5"}
                onClick={readResource(onPressMax)}
              />
            )
          )
        }}
      </Spensor>

      {props.estimatedUSD != null && (
        <EstimatedUSD error={props.error} usdCount={props.estimatedUSD} />
      )}
    </BlockBottomLine>
  )
}

export const BalanceCount: FC<{
  token?: TokenInfo
  balance: SuspenseResource<number>
}> = props => (
  <span className={"text-gray-500 text-xs sm:text-base py-0.5"}>
    Balance:&nbsp;
    <Spensor fallback={"-"}>
      {() =>
        props.token === undefined ? (
          "Select a token"
        ) : (
          <TokenCount token={props.token} count={readResource(props.balance)} />
        )
      }
    </Spensor>
  </span>
)

export const MaxButton: FC<{
  className?: string
  onClick: () => void
}> = props => (
  <OpacityButton
    className={clsx("flex items-center", props.className)}
    boxClassName={"px-1 sm:px-2 sm:py-0.5"}
    textClassName={"text-blue-600 text-xs sm:text-sm font-medium"}
    onClick={props.onClick}
  >
    Max
  </OpacityButton>
)

export const EstimatedUSD: FC<{
  usdCount: SuspenseResource<number>
  error?: boolean
}> = props => (
  <Truncatable
    className={clsx(
      "ml-auto whitespace-nowrap text-xs sm:text-base py-0.5",
      props.error ? "text-red-500" : "text-gray-500",
    )}
  >
    ≈${" "}
    <Spensor fallback={"-"}>
      {() => (
        <TokenCount
          token={TokenInfoPresets.USD}
          count={readResource(props.usdCount)}
        />
      )}
    </Spensor>
  </Truncatable>
)
