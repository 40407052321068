import clsx from "clsx"
import { FC } from "react"
import { FilledButtonVariantProps } from "./FilledButton"
import { BaseFilledButton } from "./_/BaseFilledButton"

export const OpacityButton: FC<FilledButtonVariantProps> = props => {
  return (
    <BaseFilledButton
      {...props}
      className={clsx(`text-gray-200 disabled:opacity-30`, props.className)}
      bgClassName={
        "bg-white bg-opacity-5 hover:bg-black hover:bg-opacity-5 active:bg-black active:bg-opacity-15 disabled:bg-black disabled:bg-opacity-15"
      }
    >
      {props.children}
    </BaseFilledButton>
  )
}
