import * as Sentry from "@sentry/browser"
import { AppConfig, AuthOptions, UserSession } from "@stacks/connect"
import { CoreNodeInfoResponse } from "@stacks/stacks-blockchain-api-types"
import { action, computed, makeObservable } from "mobx"
import { createTransformer } from "mobx-utils"
import {
  ALLOW_CONTRACT_ARGUMENTATION,
  API_HOST,
  ESTIMATED_BLOCK_DURATION,
  EXPLORER_ADDR_URL,
  IS_MAIN_NET,
  REFRESH_FOR_NEW_BLOCK,
} from "../../config"
import { RoutePath } from "../../routes/routes"
import { LazyValue } from "../LazyValue/LazyValue"
import { SuspenseObservable } from "../SuspenseObservable"
import TimerValue from "../TimerValue"

class AuthStore {
  private appConfig = new AppConfig(
    ["store_write", "store_read", "publish_data"],
    "https://app.alexlab.co",
  )

  private userSession = new UserSession({ appConfig: this.appConfig })

  private stxAddress = new SuspenseObservable<string>()
  private publicKey = new SuspenseObservable<string>()

  refreshTimer = new TimerValue(REFRESH_FOR_NEW_BLOCK)

  private currenBlock = new LazyValue(
    () => this.refreshTimer.value,
    () =>
      fetch(`${API_HOST}/v2/info`)
        .then(r => r.json())
        .then((r: CoreNodeInfoResponse) => r["stacks_tip_height"]),
  )

  @computed get currentBlockHeight(): number {
    return this.currenBlock.value$
  }

  estimatedDateForBlock = createTransformer((targetBlock: number) => {
    return new Date(
      new Date().getTime() +
        (targetBlock - this.currentBlockHeight) * ESTIMATED_BLOCK_DURATION,
    )
  })

  @computed get stxAddress$(): string {
    const asAddress = ALLOW_CONTRACT_ARGUMENTATION
      ? new URLSearchParams(window.location.search).get("asAddress")
      : undefined
    // return asAddress || CONTRACT_DEPLOYER
    return asAddress || this.stxAddress.read$
    // return CONTRACT_DEPLOYER
  }

  @computed get publicKey$(): string {
    return this.publicKey.read$
  }

  @computed get addressExplorerLink$(): string {
    return EXPLORER_ADDR_URL(this.stxAddress$)
  }

  @computed get isWalletConnected(): boolean {
    try {
      return this.stxAddress$ != null
    } catch (e) {
      return false
    }
  }

  constructor() {
    makeObservable(this)
    this.refreshAddress()
  }

  @action refreshAddress(): void {
    if (this.userSession.isUserSignedIn()) {
      const { mainnet, testnet } =
        this.userSession.loadUserData().profile?.stxAddress
      this.stxAddress.set(IS_MAIN_NET ? mainnet : testnet)
      const ak = this.userSession.loadUserData().appPrivateKey
      this.publicKey.set(ak)
    } else {
      this.stxAddress.set(undefined)
      this.publicKey.set(undefined)
    }
    const address = this.stxAddress.get()
    Sentry.configureScope(scope => {
      scope.setUser(address ? { id: address } : null)
    })
  }

  getAuthOptions(): AuthOptions {
    const onFinish: AuthOptions["onFinish"] = () => {
      this.refreshAddress()
    }

    const onCancel: AuthOptions["onCancel"] = () => {
      this.refreshAddress()
    }

    return {
      manifestPath: "",
      redirectTo: "/" + RoutePath.DepositBorrow,
      userSession: this.userSession,
      onFinish,
      onCancel,
      appDetails: {
        name: "ALEX",
        icon: "https://cdn.alexlab.co/logos/ALEX_Token.png",
      },
    }
  }

  async signOut(): Promise<void> {
    this.userSession.signUserOut()
    this.refreshAddress()
  }
}

export default AuthStore
