import { uintCV } from "@stacks/transactions"
import {
  contractRef,
  Currency,
  defineContract,
  definePublicCalls,
  Encoder,
  FWPToken,
  intResult,
  listDecoder,
  listEncoder,
  optionalDecoder,
  parseResult,
  tupleDecoder,
} from "./contractBase"
import { getHarvestPCs, getStakePC } from "./postConditions/stakePC"

export type StakingToken = Currency.Alex | FWPToken

const stakingTokenRef: Encoder<StakingToken> = contractRef

const getStakedResult = listDecoder(
  tupleDecoder({
    "amount-staked": intResult,
    "to-return": intResult,
  }),
)

export type GetStakedResult = ReturnType<typeof getStakedResult>

export const stakingContracts = defineContract({
  "alex-reserve-pool": {
    "get-apower-multiplier-in-fixed-or-default": {
      input: [stakingTokenRef],
      output: intResult,
    },
    // "get-reward-cycle": {
    //   input: [stakingTokenRef, uintCV],
    //   output: optionalDecoder(intResult),
    // },
    "get-first-stacks-block-in-reward-cycle": {
      input: [stakingTokenRef, uintCV],
      output: intResult,
    },
    "stake-tokens": definePublicCalls(
      [stakingTokenRef, uintCV, uintCV] as const,
      (senderAddress, contractArgs) => {
        const tokenX = contractArgs[0]
        const dx = contractArgs[1]
        const stakePCArgs = {
          tokenX,
          dx,
        }
        return getStakePC(senderAddress, stakePCArgs)
      },
    ),
    "get-reward-cycle-length": {
      input: [],
      output: intResult,
    },
    "get-staking-stats-at-cycle": {
      input: [stakingTokenRef, uintCV],
      output: optionalDecoder(intResult),
    },
  },
  "staking-helper": {
    // "get-staked": {
    //   input: [stakingTokenRef, listEncoder(uintCV)],
    //   output: getStakedResult,
    // },
    "get-staking-rewards": {
      input: [stakingTokenRef, listEncoder(uintCV)],
      output: listDecoder(intResult),
    },
    "claim-staking-reward": definePublicCalls(
      [stakingTokenRef, listEncoder(uintCV)] as const,
      (senderAddress, contractArgs, extraArgs: { pendingReturns: number }) => {
        const stakingToken = contractArgs[0]
        const { pendingReturns } = extraArgs
        const harvestPCArgs = {
          stakingToken,
          pendingReturns,
        }
        return getHarvestPCs(harvestPCArgs)
      },
    ),
  },
} as const)

export const stakingClaimedResult = parseResult(
  listDecoder(
    parseResult(
      tupleDecoder({
        "entitled-token": intResult,
        "to-return": intResult,
      }),
    ),
  ),
)
