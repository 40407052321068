import {
  FungiblePostCondition,
  STXPostCondition,
  uintCV,
} from "@stacks/transactions"
import {
  currencyContract,
  defineContract,
  definePublicCalls,
  Encoder,
  intResult,
  LiquidityPoolToken,
  LiquidityToken,
  numberCV,
  optional,
  tupleDecoder,
} from "./contractBase"
import {
  getAddLiquidityPCs,
  getRemoveLiquidityPCs,
} from "./postConditions/liquidityPCs"
import { getSpotPCs } from "./postConditions/spotPCs"

const getFWPDetailResult = tupleDecoder({
  "total-supply": intResult,
})

const getFWPPositionGivenBurnResult = tupleDecoder({
  dx: intResult,
  dy: intResult,
})

const getFWPPositionGivenPositionResult = tupleDecoder({
  token: intResult,
  dy: intResult,
})

const fixedWeightPoolContracts = defineContract({
  "fixed-weight-pool-v1-01": {
    "get-y-given-x": {
      input: [currencyContract, currencyContract, uintCV, uintCV, uintCV],
      output: intResult,
    },
    "get-fee-rate-x": {
      input: [currencyContract, currencyContract, uintCV, uintCV],
      output: intResult,
    },
    "get-fee-rate-y": {
      input: [currencyContract, currencyContract, uintCV, uintCV],
      output: intResult,
    },
    "get-pool-details": {
      input: [currencyContract, currencyContract, uintCV, uintCV],
      output: getFWPDetailResult,
    },
    "get-position-given-burn": {
      input: [currencyContract, currencyContract, uintCV, uintCV, uintCV],
      output: getFWPPositionGivenBurnResult,
    },
    "get-token-given-position": {
      input: [
        currencyContract,
        currencyContract,
        uintCV,
        uintCV,
        uintCV,
        optional(uintCV),
      ],
      output: getFWPPositionGivenPositionResult,
    },
    "swap-x-for-y": {
      input: [
        currencyContract, // x
        currencyContract, // y
        uintCV, // weightX
        uintCV, // weightY
        uintCV, // dX
        optional(uintCV), // optional(minDy)
      ],
      mode: "public",
    },
    "swap-y-for-x": {
      input: [
        currencyContract, // x
        currencyContract, // y
        uintCV, // weightX
        uintCV, // weightY
        uintCV, // dX
        optional(uintCV), // optional(minDy)
      ],
      mode: "public",
    },
    "swap-helper": definePublicCalls(
      [
        currencyContract, // x
        currencyContract, // y
        numberCV, // weightX
        numberCV, // weightY
        numberCV, // dX
        optional(numberCV), // optional(minDy)
      ] as const,
      (senderAddress, contractArgs) => {
        const spotPCArgs = {
          tokenX: contractArgs[0],
          tokenY: contractArgs[1],
          dx: contractArgs[4],
          minDy: contractArgs[5],
        }
        return getSpotPCs(senderAddress, spotPCArgs)
      },
    ),
    "get-helper": {
      input: [
        currencyContract, // x
        currencyContract, // y
        uintCV, // weightX
        uintCV, // weightY
        uintCV, // amount
      ],
      output: intResult,
    },
    "add-to-position": definePublicCalls(
      [
        currencyContract as Encoder<LiquidityToken>, // x
        currencyContract as Encoder<LiquidityToken>, // y
        uintCV, // weightX
        uintCV, // weightY
        currencyContract as Encoder<LiquidityPoolToken>, // pool token
        numberCV, //amount
        optional(numberCV),
      ] as const,
      (
        senderAddress,
        contractArgs,
      ): Array<FungiblePostCondition | STXPostCondition> => {
        const tokenX = contractArgs[0]
        const tokenY = contractArgs[1]
        const poolToken = contractArgs[4]
        const dx = contractArgs[5]
        const dy = contractArgs[6]
        const liquidityPCArgs = {
          tokenX,
          tokenY,
          poolToken,
          dx,
          dy,
        }
        return getAddLiquidityPCs(senderAddress, liquidityPCArgs)
      },
    ),
    "reduce-position": definePublicCalls(
      [
        currencyContract as Encoder<LiquidityToken>, // x
        currencyContract as Encoder<LiquidityToken>, // y
        uintCV, // weightX
        uintCV, // weightY
        currencyContract as Encoder<LiquidityPoolToken>, // pool token
        numberCV, // percentage
      ] as const,
      (
        senderAddress,
        contractArgs,
        extraArgs: {
          amountToRemove: number
          // pooledX: number; pooledY: number
        },
      ) => {
        const tokenX = contractArgs[0]
        const tokenY = contractArgs[1]
        const poolToken = contractArgs[4]
        const percentage = contractArgs[5]
        const { amountToRemove } = extraArgs
        const removeLiquidityPCArgs = {
          tokenX,
          tokenY,
          poolToken,
          percentage,
          amountToRemove,
        }
        return getRemoveLiquidityPCs(senderAddress, removeLiquidityPCArgs)
      },
    ),
  },
} as const)

export default fixedWeightPoolContracts
