import clsx from "clsx"
import { FC } from "react"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import { LoadingIndicator } from "../../LoadingIndicator/LoadingIndicator"
import { TokenCount } from "../../TokenCount"
import { TokenName } from "../../TokenName"

export interface BalanceBarProps {
  className?: string
  balanceInfos: {
    token: TokenInfo
    balance: number
  }[]
}

export const BalanceBar: FC<BalanceBarProps> = props => {
  return (
    <BalanceBarFrame className={props.className}>
      {props.balanceInfos.map((info, idx) => (
        <dl key={idx} className={"flex"}>
          <dd>
            <TokenCount token={info.token} count={info.balance} />
          </dd>
          <dt className={"ml-2 opacity-50"}>
            <TokenName token={info.token} />
          </dt>
        </dl>
      ))}
    </BalanceBarFrame>
  )
}

export const BalanceBar$Loading: FC<{ className?: string }> = props => {
  return (
    <BalanceBarFrame
      className={clsx("flex items-center justify-center", props.className)}
    >
      <LoadingIndicator size={20} />
    </BalanceBarFrame>
  )
}

export const BalanceBarFrame: FC<{
  className?: string
}> = props => (
  <div
    className={clsx(
      `flex p-2 gap-8 justify-center backdrop-blur-lg border-b`,
      "text-sm leading-5 font-medium text-gray-200",
      props.className,
    )}
    style={{
      backgroundColor: "rgba(63, 63, 70, 0.2)",
      borderColor: "rgba(255, 255, 255, 0.05)",
    }}
  >
    {props.children}
  </div>
)
