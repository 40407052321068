import errorCodes from "./errorCodes.json"

export function errorMsgFor(repr: string): string {
  // (err u2020)
  const match = repr.match(/\(err u(\d+)\)/)
  if (match == null) {
    return `Unrecognized error: ${repr}`
  }
  const code = Number(match[1])
  return errorMsgForCode(code)
}

export function errorMsgForCode(code: number): string {
  return (
    (errorCodes as any)[code.toString()]?.comment ??
    `Unknown error code: ${code}`
  )
}
