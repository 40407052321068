import clsx from "clsx"
import { FC, ReactNode } from "react"
import { Link } from "react-router-dom"
import { PathGenerator, usePathGenerator } from "../../routes/routes"
import { GradientText } from "../GradientText"
import { NavLink } from "../NavLink"
import { ReactComponent as AboutIcon } from "./about.svg"
import { ReactComponent as AlexLogo } from "./alexLogo.svg"
import { ReactComponent as AlexLogoText } from "./alexLogoText.svg"
import { ReactComponent as DiscordIcon } from "./discord.svg"
import { Dropdown, DropdownDivider, DropdownItem } from "./Dropdown"
import { MenuButton } from "./MenuButton/MenuButton"
import { NavButton } from "./NavButton/NavButton"
import { BalanceBar, BalanceBarProps } from "./_/BalanceBar"
import { BalanceButton, BalanceButtonProps } from "./_/BalanceButton"

const buttons: {
  name: string
  link: (pathGenerator: PathGenerator) => string
}[] = [
  { link: g => g.launchpad(), name: "LAUNCHPAD✨" },
  { link: g => g.swap(), name: "SWAP" },
  { link: g => g.poolList(), name: "POOL" },
  { link: g => g.stake(), name: "STAKE" },
  { link: g => g.farmList(), name: "FARM" },
]

const AutoHideBalanceComps: FC = props => {
  return <div className={"hidden md:block"}>{props.children}</div>
}

export const _BalanceButton: FC<BalanceButtonProps> = props => {
  return (
    <AutoHideBalanceComps>
      <BalanceButton
        {...props}
        className={`cursor-pointer ${props.className}`}
      />
    </AutoHideBalanceComps>
  )
}

export const _BalanceBar: FC<BalanceBarProps> = props => {
  return (
    <AutoHideBalanceComps>
      <BalanceBar {...props} />
    </AutoHideBalanceComps>
  )
}

export const TopNavBarHeight = 80

export interface TopNavBarProps {
  className?: string
  rightSide: ReactNode
}

export const TopNavBar: FC<TopNavBarProps> = props => {
  const gen = usePathGenerator()

  return (
    <div
      className={clsx(
        "flex items-center min-h-[5rem] py-5 px-4 sm:px-8 backdrop-blur-lg space-between gap-1",
        props.className,
      )}
      style={{ background: "rgba(17, 24, 39, 0.2)" }}
    >
      <Link
        className={"flex items-center cursor-pointer gap-1.5"}
        to={gen.index()}
      >
        <AlexLogo width={32} height={32} />
        <AlexLogoText width={106} height={28} />
      </Link>

      <div className={"flex-1"}>
        <div className={"mx-[38px] justify-start hidden lg:flex"}>
          {buttons.map(b => (
            <NavLink to={b.link(gen)} key={b.link(gen)} className="">
              {({ isActive }) => (
                <NavButton active={isActive}>
                  {b.link(gen) === gen.launchpad() ? (
                    <LaunchpadNavButtonContent />
                  ) : (
                    b.name
                  )}
                </NavButton>
              )}
            </NavLink>
          ))}
        </div>
      </div>

      <div className={"flex gap-0.5 sm:gap-6 items-center"}>
        {props.rightSide && (
          <div className="flex items-center gap-2">{props.rightSide}</div>
        )}

        <Dropdown placement={"bottom-end"} trigger={<MenuButton />}>
          {buttons.map(b => (
            <NavLink key={b.link(gen)} className={"lg:hidden"} to={b.link(gen)}>
              <DropdownItem>{b.name}</DropdownItem>
            </NavLink>
          ))}
          <DropdownDivider className={"lg:hidden"} />

          <NavLink to="https://alexgo.io/">
            <DropdownItem icon={<AboutIcon className={"fill-current"} />}>
              About
            </DropdownItem>
          </NavLink>
          <NavLink to="https://discord.gg/alexgo">
            <DropdownItem icon={<DiscordIcon className={"fill-current"} />}>
              Discord
            </DropdownItem>
          </NavLink>
        </Dropdown>
      </div>
    </div>
  )
}

const LaunchpadNavButtonContent: FC = () => {
  return (
    <span className={"hover:opacity-80 active:opacity-60"}>
      <GradientText
        gradientStyle={
          "linear-gradient(90deg, #EEEEEE 0.12%, #93C5FD 49.08%, #FB923C 100.12%)"
        }
      >
        LAUNCHPAD
      </GradientText>
      ✨
    </span>
  )
}
