import { Menu } from "@headlessui/react"
import { FC } from "react"
import { isTouchDevice } from "../../utils/domHelpers"
import { useHoverCallbacks } from "../../utils/reactHelpers/useHover"
import { withClassName } from "../../utils/reactHelpers/withClassName"
import { Divider } from "../Divider"
import {
  Placement,
  PopperContainer,
  PopperElement,
  ReferenceElement,
} from "../Popper"

export interface DropdownProps {
  /**
   * @default "hover"
   */
  triggerMethod?: "hover" | "click"

  trigger: JSX.Element | ((info: { open: boolean }) => JSX.Element)

  placement?: Placement
}
export const Dropdown: FC<DropdownProps> = props => {
  const { triggerMethod = "hover" } = props

  const trigger =
    typeof props.trigger === "function" ? props.trigger : () => props.trigger

  const [hoverVisible, hoverCallbacks] = useHoverCallbacks()

  const content = (
    <PopperElement className={"z-1"} {...hoverCallbacks}>
      <Menu.Items
        className="rounded-xl overflow-hidden py-3 mt-2 text-white shadow-lg backdrop-blur-2xl focus:outline-none flex flex-col gap-2"
        style={{
          minWidth: 180,
          boxShadow: "box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05)",
          background:
            "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), rgba(17, 24, 39, 0.95)",
        }}
        static={true}
      >
        {props.children}
      </Menu.Items>
    </PopperElement>
  )

  return (
    <PopperContainer placement={props.placement ?? "bottom"}>
      <Menu>
        {({ open: clickVisible }) => {
          const open =
            isTouchDevice() || triggerMethod === "click"
              ? clickVisible
              : hoverVisible

          return (
            <>
              <Menu.Button as={ReferenceElement} {...hoverCallbacks}>
                {trigger({ open })}
              </Menu.Button>
              {open && content}
            </>
          )
        }}
      </Menu>
    </PopperContainer>
  )
}

export const DropdownItem: FC<{
  className?: string
  icon?: JSX.Element
  onClick?: () => void
}> = props => {
  return (
    <Menu.Item>
      <div
        className={`px-4 py-2 flex items-center justify-between cursor-pointer text-sm leading-5 font-normal text-gray-200 hover:text-white hover:bg-white hover:bg-opacity-10 active:bg-opacity-5 ${props.className}`}
        onClick={props.onClick}
      >
        <div>{props.children}</div>

        {props.icon && (
          <span className={"flex items-center text-gray-500"}>
            {props.icon}
          </span>
        )}
      </div>
    </Menu.Item>
  )
}

export const DropdownDivider = withClassName("border-gray-500/30", Divider)
