import { gql } from "@urql/core"
import { CONTRACT_DEPLOYER } from "../../config"
import {
  FetchLatestAlexPriceQuery,
  FetchLatestAlexPriceQueryVariables,
  FetchLatestPriceQuery,
  FetchLatestPriceQueryVariables,
} from "../../graphql.generated"
import { asSender } from "../../utils/alexjs/asSender"
import { Currency } from "../../utils/alexjs/contractBase"
import { DisplayableError } from "../../utils/error"
import { gqlQuery } from "../../utils/graphqlHelpers"
import { fromUrqlSource } from "../../utils/Observable/fromUrqlSource"
import { Observable } from "../../utils/Observable/Observable"

export async function getDxDyOnFixedWeightPool(
  fwp: Currency.FWP_WSTX_WBTC_50_50_V1_01 | Currency.FWP_WSTX_ALEX_50_50_V1_01,
): Promise<{
  x: Currency.STX
  dx: number
  y: Currency.WBTC | Currency.Alex
  dy: number
}> {
  const tokenB =
    fwp === Currency.FWP_WSTX_ALEX_50_50_V1_01 ? Currency.Alex : Currency.WBTC
  const unit = 1000 * 1e8
  const { dx, dy } = await asSender(CONTRACT_DEPLOYER)
    .contract("fixed-weight-pool-v1-01")
    .func("get-position-given-burn")
    .call([Currency.STX, tokenB, 0.5e8, 0.5e8, unit])
  return {
    x: Currency.STX,
    y: tokenB,
    dx: dx / unit,
    dy: dy / unit,
  }
}

export function fetchLatestPrice(currency: Currency): Observable<number> {
  const unsupportedError = new DisplayableError(
    `[fetchLatestPrice] Unsupported currency: ${currency}`,
  )

  if (currency === Currency.Alex) {
    return fromUrqlSource(
      gqlQuery(
        gql<FetchLatestAlexPriceQuery, FetchLatestAlexPriceQueryVariables>`
          query FetchLatestAlexPrice {
            laplace_alex_usd_pricing {
              avg_price_usd
            }
          }
        `,
      ),
      res => {
        const first = res.data.laplace_alex_usd_pricing[0]
        if (first?.avg_price_usd == null) throw unsupportedError
        return first.avg_price_usd
      },
    )
  }

  return fromUrqlSource(
    gqlQuery(
      gql<FetchLatestPriceQuery, FetchLatestPriceQueryVariables>`
        query FetchLatestPrice($tokenName: String!) {
          laplace_coin_gecko(where: { token: { _eq: $tokenName } }) {
            avg_price_usd
          }
        }
      `,
      {
        tokenName: currency,
      },
    ),
    res => {
      const first = res.data.laplace_coin_gecko[0]
      if (first == null) throw unsupportedError
      return first.avg_price_usd
    },
  )
}
