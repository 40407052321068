import { createContext, FC, useContext } from "react"
import { useAccountStore } from "../../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../../stores/authStore/useAuthStore"
import { useCurrencyStore } from "../../../stores/currencyStore/useCurrencyStore"
import { LaunchToken } from "../../../utils/alexjs/launchPadContracts"
import { useCreation } from "../../../utils/reactHelpers/useCreation"
import LaunchPadStore from "./LaunchPadStore"

const LaunchPadStoreContext = createContext<LaunchPadStore | null>(null)

export const LaunchPadProvider: FC<{ token: LaunchToken }> = props => {
  const authStore = useAuthStore()
  const accountStore = useAccountStore()
  const currencyStore = useCurrencyStore()
  const store = useCreation(
    () =>
      new LaunchPadStore(props.token, authStore, accountStore, currencyStore),
    [accountStore, currencyStore, accountStore, currencyStore],
  )

  return (
    <LaunchPadStoreContext.Provider value={store}>
      {props.children}
    </LaunchPadStoreContext.Provider>
  )
}

export const useLaunchPadStore = (): LaunchPadStore => {
  const store = useContext(LaunchPadStoreContext)
  if (store == null) {
    throw new Error(`useSpotStore must be used within a SpotStoreProvider`)
  }
  return store
}
