import { FilledButtonVariantProps } from "../FilledButton"

export const small: FilledButtonVariantProps = {
  boxClassName: "px-3 py-1",
  textClassName: "text-sm leading-5 font-medium",
}

export const medium: FilledButtonVariantProps = {
  boxClassName: "px-6 py-3",
  textClassName: "text-base leading-6 font-semibold",
}
