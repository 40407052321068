import { IntegerType } from "@stacks/common"
import {
  createAssetInfo,
  FungibleConditionCode,
  FungiblePostCondition,
  makeContractFungiblePostCondition,
  makeStandardFungiblePostCondition,
} from "@stacks/transactions"
import { CONTRACT_DEPLOYER } from "../../../config"
import { StakingToken } from "../stakingContracts"
import { currencyTxnData } from "./assetMapping"

export const getStakePC = (
  senderAddress: string,
  stakePCArgs: {
    tokenX: StakingToken
    dx: IntegerType
  },
): Array<FungiblePostCondition> => {
  const { tokenX, dx } = stakePCArgs
  return [getStandardFungiblePC(senderAddress, tokenX, dx)]
}

export const getHarvestPCs = (harvestPCArgs: {
  stakingToken: StakingToken
  pendingReturns: number
}): Array<FungiblePostCondition> => {
  const { stakingToken, pendingReturns } = harvestPCArgs
  const code = FungibleConditionCode.GreaterEqual
  const contractAddress = CONTRACT_DEPLOYER
  const contractName = "alex-vault"
  const { assetAddress, assetContractName, assetName } =
    currencyTxnData(stakingToken)
  const fungibleAssetInfo = createAssetInfo(
    assetAddress,
    assetContractName,
    assetName,
  )
  return [
    makeContractFungiblePostCondition(
      contractAddress,
      contractName,
      code,
      Math.round(pendingReturns * 1e8),
      fungibleAssetInfo,
    ),
  ]
}

const getStandardFungiblePC = (
  senderAddress: string,
  tokenX: StakingToken,
  amount: IntegerType,
): FungiblePostCondition => {
  const code = FungibleConditionCode.Equal
  const { assetAddress, assetContractName, assetName } = currencyTxnData(tokenX)
  const fungibleAssetInfo = createAssetInfo(
    assetAddress,
    assetContractName,
    assetName,
  )
  return makeStandardFungiblePostCondition(
    senderAddress,
    code,
    amount,
    fungibleAssetInfo,
  )
}
