import * as Sentry from "@sentry/react"
import { Connect } from "@stacks/connect-react"
import { FC } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import GlobalErrorBoundaryPage, {
  NotFoundPage,
} from "./components/GlobalErrorBoundaryPage"
import { Layout } from "./components/Layout/Layout"
import { MessageProvider } from "./components/message/MessageProvider"
import LaunchpadPage from "./pages/Launchpad/LaunchpadPage"
import { RoutePath } from "./routes/routes"
import SyncTransactionsOnIdle from "./stores/accountStore/SyncTransactionsOnIdle"
import { AccountStoreProvider } from "./stores/accountStore/useAccountStore"
import { AppEnvStoreProvider } from "./stores/appEnvStore/useAppEnvStore"
import {
  AuthStoreProvider,
  useAuthStore,
} from "./stores/authStore/useAuthStore"
import { CurrencyStoreProvider } from "./stores/currencyStore/useCurrencyStore"

const StoreContexts: FC = props => (
  <AppEnvStoreProvider>
    <AuthStoreProvider>
      <CurrencyStoreProvider>
        <AccountStoreProvider>{props.children}</AccountStoreProvider>
      </CurrencyStoreProvider>
    </AuthStoreProvider>
  </AppEnvStoreProvider>
)

const StackConnectProvider: FC = props => {
  const authStore = useAuthStore()
  return (
    <Connect authOptions={authStore.getAuthOptions()}>{props.children}</Connect>
  )
}

const App: FC = () => {
  return (
    <GlobalErrorBoundaryPage>
      <MessageProvider>
        <StoreContexts>
          <StackConnectProvider>
            <BrowserRouter>
              <Routes>
                <Route path={"/"} element={<Layout />}>
                  <Route index element={<LaunchpadPage />} />
                  <Route
                    path={RoutePath.Launchpad}
                    element={<LaunchpadPage />}
                  />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </BrowserRouter>

            <SyncTransactionsOnIdle />
          </StackConnectProvider>
        </StoreContexts>
      </MessageProvider>
    </GlobalErrorBoundaryPage>
  )
}

export default Sentry.withProfiler(App)
