import { computed, makeObservable } from "mobx"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { pMemoizeDecorator } from "../../../stores/LazyValue/pMemoizeDecorator"
import LaunchPadStore, { LaunchingStatus } from "./LaunchPadStore"
import {
  fetchUserIDONonce,
  fetchUserSubscription,
} from "./LaunchPadStore.service"

class UserIDOStatusViewModule {
  constructor(readonly store: LaunchPadStore) {
    makeObservable(this)
  }

  get idoStatus(): LaunchingStatus {
    return this.store.currentStatus$
  }

  private userId = new LazyValue(
    () =>
      [
        this.store.authStore.stxAddress$,
        this.store.token,
        this.store.authStore.currentBlockHeight,
      ] as const,
    ([stxAddress, token, block]) => fetchUserIDONonce(stxAddress, token, block),
    { decorator: pMemoizeDecorator({ persistKey: "launchpad-user-id" }) },
  )

  private registered = new LazyValue(
    () =>
      [
        this.userId.value$,
        this.store.authStore.stxAddress$,
        this.store.token,
        this.store.authStore.currentBlockHeight,
      ] as const,
    async ([userId, stxAddress, token, blockHeight]) => {
      if (userId == null) {
        return null
      }
      return await fetchUserSubscription(stxAddress, userId, token, blockHeight)
    },
    {
      decorator: pMemoizeDecorator({
        persistKey: "launchpad-user-subscription",
      }),
    },
  )

  @computed get remainingRegisteredTicketCount(): number {
    return this.registered.value$?.["ticket-balance"] ?? 0
  }

  @computed get hasRegistered(): boolean {
    return this.registered.value$ != null
  }

  @computed get ticketCount$(): number {
    return this.store.accountStore.getBalance$(this.store.ticketToken$)
  }

  @computed get lockedSTX$(): number {
    return (this.registered.value$?.["wstx-locked-in-fixed"] ?? 0) / 1e8
  }

  @computed get allocatedTokenCount$(): number {
    return this.store.tokenProfile.tokenAmountPerTicket * this.lotteryWon$
  }

  @computed get lotteryToClaim$(): number {
    // this will cause the number to jump
    // return Math.max(
    //   0,
    //   this.remainingRegisteredTicketCount - this.lotteryPending$,
    // )
    return this.remainingRegisteredTicketCount
  }

  @computed get lotteryWon$(): number {
    return this.registered.value$?.["tickets-won"] ?? 0
  }

  @computed get lotteryLost$(): number {
    return this.registered.value$?.["tickets-lost"] ?? 0
  }

  @computed get lotteryPending$(): number {
    return this.store.claim.inFlightTicketCount
  }

  @computed get costSTX$(): number {
    return this.lotteryWon$ * this.store.tokenProfile.pricePerTicket
  }

  @computed get refundableSTX$(): number {
    if (this.idoStatus === LaunchingStatus.Finished) {
      return this.lockedSTX$
    }
    return this.lotteryLost$ * this.store.tokenProfile.pricePerTicket
  }
}

export default UserIDOStatusViewModule
