import { RefObject, useMemo, useReducer, useRef } from "react"

export interface UseFocusCallbacks {
  onFocus: () => void
  onBlur: () => void
}

export const useFocusCallbacks = (): [
  visible: RefObject<boolean>,
  listeners: UseFocusCallbacks,
] => {
  const focus = useRef(false)
  const rerender = useReducer(a => a + 1, 0)[1]

  const callbacks = useMemo(
    () => ({
      onFocus: () => {
        focus.current = true
        rerender()
      },
      onBlur: () => {
        focus.current = false
        rerender()
      },
    }),
    [rerender],
  )

  return [focus, callbacks]
}
