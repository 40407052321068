import { FC, ReactNode } from "react"
import { shortFormatDuration, TimeCountdownText } from "./TimeCountdownText"
import { Container } from "./_/Container"
import { PrefixText } from "./_/PrefixText"

export interface TimeCountdownProps {
  className?: string
  prefix?: ReactNode
  time?: Date
}

export const TimeCountdown: FC<TimeCountdownProps> = props => (
  <Container className={props.className}>
    {props.prefix && <PrefixText>{props.prefix}</PrefixText>}

    {props.time && (
      <div className={"text-gray-200 uppercase"}>
        <TimeCountdownText
          time={props.time}
          format={d =>
            shortFormatDuration(d, { delimiter: ":" }).replace(/ /g, "")
          }
        />
      </div>
    )}
  </Container>
)
