import { createContext, FC, ReactNode, useContext } from "react"
import { useCreation } from "../../utils/reactHelpers/useCreation"
import { useAuthStore } from "../authStore/useAuthStore"
import { useCurrencyStore } from "../currencyStore/useCurrencyStore"
import AccountStore from "./AccountStore"

const AccountStoreContext = createContext<AccountStore | null>(null)

export const AccountStoreProvider: FC<{
  children: ReactNode
}> = props => {
  const authStore = useAuthStore()
  const currencyStore = useCurrencyStore()
  const store = useCreation(
    () => new AccountStore(authStore, currencyStore),
    [],
  )
  return (
    <AccountStoreContext.Provider value={store}>
      {props.children}
    </AccountStoreContext.Provider>
  )
}

export const useAccountStore = (): AccountStore => {
  const store = useContext(AccountStoreContext)
  if (store == null) {
    throw new Error(
      "useAccountStore must be used within a AccountStoreProvider",
    )
  }
  return store
}
