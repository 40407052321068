import { range } from "lodash"
import { computed, makeObservable, observable } from "mobx"
import { CONTRACT_DEPLOYER, EXPLORER_TX_URL, TG_SUB_URL } from "../../../config"
import { LazyValue } from "../../../stores/LazyValue/LazyValue"
import { currentContractName } from "../../../utils/alexjs/asSender"
import { fetchTransaction } from "../../../utils/alexjs/fetchTransaction"
import { asyncAction, runAsyncAction } from "../../../utils/asyncAction"
import { retryWhenBlockChangedUntil } from "../../../utils/retryWhenBlockChangedUntil"
import { assertNever } from "../../../utils/types"
import LaunchPadStore, { LaunchingStatus } from "./LaunchPadStore"
import { openTicket } from "./LaunchPadStore.service"

export type LotteryTicket =
  | LotteryTicket.Waiting
  | LotteryTicket.Pending
  | LotteryTicket.Failed
  | LotteryTicket.Won
  | LotteryTicket.Lose

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace LotteryTicket {
  export enum Type {
    Waiting = "waiting",
    Pending = "pending",
    TransactionFailed = "transaction failed",
    Won = "won",
    Lose = "lose",
  }

  export interface Waiting {
    type: Type.Waiting
    onOpen?: () => void
  }

  export interface Pending {
    type: Type.Pending
    txId: string
    explorerLink: string
    telegramSubscribeLink: string
  }

  export interface Failed {
    type: Type.TransactionFailed
    onRetry?: () => void
  }

  export interface Won {
    type: Type.Won
    explorerLink: string
    wonTokenCount: number
  }

  export interface Lose {
    type: Type.Lose
    explorerLink: string
    withdrawSTXCount: number
  }
}

class ClaimViewModule {
  constructor(readonly store: LaunchPadStore) {
    makeObservable(this)
  }

  @observable tickets: LotteryTicket[] = []

  @computed get currentStepNumber(): number {
    switch (this.store.currentStatus$) {
      case LaunchingStatus.Upcoming:
        return 1
      // LaunchingStatus will add a new value `ExchangeTicket` later
      case LaunchingStatus.Registration:
        return 3
      case LaunchingStatus.Claim:
        return 4
      case LaunchingStatus.Finished:
        return 5
      default:
        assertNever(this.store.currentStatus$)
    }
  }

  @computed get currentStepEndedAt(): null | Date {
    if (this.store.currentStatusEndBlock$ == null) return null
    const willEndAfterMS =
      (this.store.currentStatusEndBlock$ - this.store.currentBlock$) *
      this.store.singleBlockDuration$
    return new Date(Date.now() + willEndAfterMS)
  }

  @computed get inFlightTicketCount(): number {
    return this.tickets.filter(
      t => t.type === LotteryTicket.Type.Pending && t.explorerLink != null,
    ).length
  }

  // note(@zhigang1992): we need this to prevent mobx from automatically updating the tickets array
  @asyncAction async materializeTicket({
    pendingCount,
    wonCount,
    wonStIds,
    lostCount,
    lostStIds,
    inflightIds,
  }: {
    pendingCount: number
    wonCount: number
    lostCount: number
    wonStIds: string[]
    lostStIds: string[]
    inflightIds: string[]
  }): Promise<void> {
    this.tickets = [
      ...range(wonCount).map(
        (i): LotteryTicket.Won => ({
          type: LotteryTicket.Type.Won,
          explorerLink: EXPLORER_TX_URL(wonStIds[i]!),
          wonTokenCount: this.store.tokenProfile.tokenAmountPerTicket,
        }),
      ),
      ...range(lostCount).map(
        (i): LotteryTicket.Lose => ({
          type: LotteryTicket.Type.Lose,
          explorerLink: EXPLORER_TX_URL(lostStIds[i]!),
          withdrawSTXCount: this.store.tokenProfile.pricePerTicket,
        }),
      ),
      ...inflightIds.slice(0, pendingCount).map(
        (id): LotteryTicket.Pending => ({
          type: LotteryTicket.Type.Pending,
          txId: id,
          explorerLink: EXPLORER_TX_URL(id),
          telegramSubscribeLink: TG_SUB_URL(id),
        }),
      ),
      ...range(Math.max(0, pendingCount - inflightIds.length)).map(
        (i): LotteryTicket.Waiting => ({
          type: LotteryTicket.Type.Waiting,
          onOpen: () =>
            this.openPending(i + wonCount + lostCount + inflightIds.length),
        }),
      ),
    ]
    setTimeout(() => {
      range(0, inflightIds.slice(0, pendingCount).length)
        .map(i => i + wonCount + lostCount)
        .forEach(i => {
          this.checkForUpdate(i).catch(() => null)
        })
    })
  }

  transaction = new LazyValue(
    () =>
      [
        this.store.accountStore.transactions$,
        this.store.detail$["registration-end"],
        this.store.detail$["claim-end"],
        this.store.token,
      ] as const,
    async ([transaction, start, end, token]) => {
      await transaction.sync()
      const txs = await transaction.transactions("alex-launchpad", "claim")
      return txs.filter(
        tx =>
          tx.tx_status === "success" &&
          tx.block_height >= start &&
          tx.block_height <= end &&
          tx.contract_call.function_args?.[0]?.repr ===
            `${CONTRACT_DEPLOYER}.${token}`,
      )
    },
  )

  mempoolTransactions = new LazyValue(
    () => [this.store.accountStore.transactions$, this.store.token] as const,
    ([transactions, token]) => {
      return transactions
        .fetchMemPoolTransactions()
        .then(r =>
          r.filter(
            t =>
              t.tx_type === "contract_call" &&
              t.contract_call.contract_id ===
                `${CONTRACT_DEPLOYER}.${currentContractName(
                  "alex-launchpad",
                )}` &&
              t.contract_call.function_name === "claim" &&
              t.contract_call.function_args?.[0]?.repr ===
                `${CONTRACT_DEPLOYER}.${token}` &&
              t.tx_status === "pending",
          ),
        )
    },
  )

  @computed get wonClaims(): string[] {
    return this.transaction.value$
      .filter(t => t.tx_result.repr === "(ok true)")
      .map(t => t.tx_id)
  }

  @computed get lostClaims(): string[] {
    return this.transaction.value$
      .filter(t => t.tx_result.repr !== "(ok true)")
      .map(t => t.tx_id)
  }

  @computed get memPoolClaims(): string[] {
    return this.mempoolTransactions.value$.map(t => t.tx_id)
  }

  @asyncAction async checkForUpdate(
    index: number,
    run = runAsyncAction,
  ): Promise<void> {
    const ticket = this.tickets[index]
    if (ticket == null || ticket.type !== LotteryTicket.Type.Pending) {
      return
    }
    const transaction = await run(
      retryWhenBlockChangedUntil(
        this.store.authStore,
        () => fetchTransaction(ticket.txId),
        tx =>
          tx.tx_status !== "pending" &&
          "is_unanchored" in tx &&
          !tx.is_unanchored,
        { fireImmediately: true },
      ),
    )
    if (transaction.tx_status === "success") {
      if (transaction.tx_result.repr === "(ok true)") {
        this.tickets[index] = {
          type: LotteryTicket.Type.Won,
          explorerLink: ticket.explorerLink,
          wonTokenCount: this.store.tokenProfile.tokenAmountPerTicket,
        } as LotteryTicket.Won
      } else {
        this.tickets[index] = {
          type: LotteryTicket.Type.Lose,
          explorerLink: ticket.explorerLink,
          withdrawSTXCount: this.store.tokenProfile.pricePerTicket,
        } as LotteryTicket.Lose
      }
    } else {
      this.tickets[index] = {
        type: LotteryTicket.Type.TransactionFailed,
        onRetry: () => this.openPending(index),
      } as LotteryTicket.Failed
    }
  }

  @asyncAction async openPending(
    index: number,
    run = runAsyncAction,
  ): Promise<void> {
    const result = await run(
      openTicket(
        this.store.authStore.stxAddress$,
        this.store.token,
        this.store.ticketToken$,
        this.store.tokenProfile.pricePerTicket,
      ),
    )
    if (
      this.tickets.some(
        t => t.type === LotteryTicket.Type.Pending && t.txId === result.txId,
      )
    ) {
      return
    }
    const link = EXPLORER_TX_URL(result.txId)
    this.tickets[index] = {
      type: LotteryTicket.Type.Pending,
      explorerLink: link,
      txId: result.txId,
    } as LotteryTicket.Pending
    await run(this.checkForUpdate(index))
  }
}

export default ClaimViewModule
