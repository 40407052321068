import { generatePath } from "react-router-dom"
import { TokenInfo } from "../../utils/models/TokenInfo"
import { RoutePath } from "./RoutePath"

export type PathGenerator = typeof pathGenerator

export const pathGenerator = {
  index: g(RoutePath.Launchpad),
  faucet: g(RoutePath.Faucet),
  farmList: g(RoutePath.Farm),
  farmDetail: (token: TokenInfo): string =>
    generatePath(`/${RoutePath.FarmDetail}`, {
      token: token.name,
    }),
  stake: g(RoutePath.Stake),
  poolList: g(RoutePath.Pool),
  poolDetail: (token: TokenInfo): string =>
    generatePath(`/${RoutePath.PoolDetail}`, {
      token: token.name,
    }),
  swap: g(RoutePath.Swap),
  margin: g(RoutePath.Margin),
  launchpad: g(RoutePath.Launchpad),
  analyticsStakingFarming: g(RoutePath.AnalyticsStakingFarming),
  analyticsAlexPool: g(RoutePath.AnalyticsALEXPool),
  analyticsBTCPool: g(RoutePath.AnalyticsBTCPool),
}

function g(pathName: RoutePath): () => string {
  return () => generatePath(`/${pathName}`)
}
