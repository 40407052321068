import clsx from "clsx"
import { FC, PropsWithChildren } from "react"

export interface GradientTextProps extends PropsWithChildren<unknown> {
  className?: string
  gradientStyle: string
}

export const GradientText: FC<GradientTextProps> = props => {
  return (
    <span
      className={clsx(
        "bg-clip-text bg-transparent text-transparent",
        props.className,
      )}
      style={{ ...extraStyle, backgroundImage: props.gradientStyle }}
    >
      {props.children}
    </span>
  )
}

// from https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#browser_name
const isSafari =
  navigator.userAgent.includes("Safari/") &&
  !navigator.userAgent.includes("Chrome/")
const extraStyle = !isSafari
  ? {}
  : {
      // https://stackoverflow.com/a/65330979/1226532
      "-webkit-box-decoration-break": "clone",
    }
