import type {
  FungiblePostCondition,
  STXPostCondition,
} from "@stacks/transactions"
import {
  createAssetInfo,
  FungibleConditionCode,
  makeContractFungiblePostCondition,
  makeContractSTXPostCondition,
  makeStandardFungiblePostCondition,
  makeStandardSTXPostCondition,
} from "@stacks/transactions"
import { CONTRACT_DEPLOYER } from "../../../config"
import { Currency } from "../contractBase"
import { currencyTxnData } from "./assetMapping"

export const getSpotPCs = (
  senderAddress: string,
  spotPCArgs: {
    tokenX: Currency
    tokenY: Currency
    dx: number
    minDy: number | undefined
  },
): Array<FungiblePostCondition | STXPostCondition> => {
  const { tokenX, tokenY, dx, minDy } = spotPCArgs

  //STX is handled differently by PCs
  if (tokenX === Currency.STX) {
    return [
      getStandardSTXPC(senderAddress, Math.ceil(dx / 1e2)),
      getContractFungiblePC(tokenY, minDy),
    ]
  } else if (tokenY === Currency.STX) {
    return [
      getStandardFungiblePC(senderAddress, tokenX, dx),
      getContractSTXPC(
        (minDy as number) == null
          ? undefined
          : Math.round((minDy as number) / 1e2),
      ),
    ]
  }
  //If STX is not part of the transaction
  return [
    getStandardFungiblePC(senderAddress, tokenX, dx),
    getContractFungiblePC(tokenY, minDy),
    getIntermediaryStandardSTXPC(senderAddress),
    getIntermediaryContractSTXPC(),
  ]
}

const getStandardFungiblePC = (
  senderAddress: string,
  tokenX: Currency,
  amount: number,
): FungiblePostCondition => {
  const code = FungibleConditionCode.Equal
  const { assetAddress, assetContractName, assetName } = currencyTxnData(tokenX)
  const fungibleAssetInfo = createAssetInfo(
    assetAddress,
    assetContractName,
    assetName,
  )
  return makeStandardFungiblePostCondition(
    senderAddress,
    code,
    amount,
    fungibleAssetInfo,
  )
}

const getStandardSTXPC = (
  senderAddress: string,
  amount = 0,
): STXPostCondition => {
  const code = FungibleConditionCode.LessEqual
  return makeStandardSTXPostCondition(senderAddress, code, amount)
}

const getIntermediaryStandardSTXPC = (
  senderAddress: string,
): STXPostCondition => {
  const code = FungibleConditionCode.GreaterEqual
  return makeStandardSTXPostCondition(senderAddress, code, 0)
}

const getIntermediaryContractSTXPC = (): STXPostCondition => {
  const code = FungibleConditionCode.GreaterEqual
  const contractAddress = CONTRACT_DEPLOYER
  const contractName = "alex-vault"

  return makeContractSTXPostCondition(contractAddress, contractName, code, 0)
}

const getContractSTXPC = (amount = 0): STXPostCondition => {
  const code = FungibleConditionCode.GreaterEqual
  const contractAddress = CONTRACT_DEPLOYER
  const contractName = "alex-vault"

  return makeContractSTXPostCondition(
    contractAddress,
    contractName,
    code,
    amount,
  )
}

const getContractFungiblePC = (
  tokenY: Currency,
  amount?: number,
): FungiblePostCondition => {
  const code = FungibleConditionCode.GreaterEqual
  const contractAddress = CONTRACT_DEPLOYER
  const contractName = "alex-vault"
  const { assetAddress, assetContractName, assetName } = currencyTxnData(tokenY)
  const fungibleAssetInfo = createAssetInfo(
    assetAddress,
    assetContractName,
    assetName,
  )
  return makeContractFungiblePostCondition(
    contractAddress,
    contractName,
    code,
    amount ?? 0,
    fungibleAssetInfo,
  )
}
