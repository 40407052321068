import clsx from "clsx"
import { cloneElement, ComponentType, FC, ReactElement, SVGProps } from "react"
import { PressableSvg } from "../button/PressableSvg"

export const TokenInputGroup: FC<{
  firstInput: ReactElement
  secondInput: ReactElement
  Icon?: ComponentType<{ className?: string }>
  onPressIcon?: () => void
}> = props => {
  const { Icon = PlusIcon } = props

  return (
    <div className={"flex flex-col gap-1.5 items-center"}>
      {props.firstInput &&
        cloneElement(props.firstInput, {
          className: clsx(props.firstInput.props.className, "w-full"),
        })}

      <div className={"relative"}>
        <p
          className={
            "absolute p-1 rounded-full border border-gray-700 backdrop-blur-2xl -translate-x-1/2 -translate-y-1/2 scale-90 sm:scale-100"
          }
          style={{
            // we can not use rgba in here, the `drop-shadow` css property from `Card`
            // component will make the whole icon become transparency
            backgroundImage:
              "linear-gradient(152.97deg, rgb(49 53 66) 0%, rgb(36 40 46) 100%)",
          }}
        >
          <PressableSvg
            svg={<Icon className={"w-6 h-6 text-gray-200"} />}
            onClick={props.onPressIcon}
          />
        </p>
      </div>

      {props.secondInput &&
        cloneElement(props.secondInput, {
          className: clsx(props.secondInput.props.className, "w-full"),
        })}
    </div>
  )
}

const PlusIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg viewBox={"0 0 24 24"} fill={"currentColor"} {...props}>
    <path d="M21 10.5h-7.5V3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3z" />
  </svg>
)
