import { Ref, useMemo } from "react"

export type ImmutableRefObject<T> = {
  readonly current: T
}

export function assignRef<V>(
  refValue: V | null,
  ref: Ref<V> | undefined,
): void {
  if (typeof ref === "function") {
    ref(refValue)
  }

  if (ref && "current" in ref) {
    ;(ref as any).current = refValue
  }
}

export function combineRefs<V>(...refs: (Ref<V> | undefined)[]): Ref<V> {
  return (instance: V | null): void => {
    refs.forEach(ref => assignRef(instance, ref))
  }
}

export function useCombinedRef<V>(...refs: (Ref<V> | undefined)[]): Ref<V> {
  return useMemo(
    () => combineRefs(...refs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs,
  )
}
