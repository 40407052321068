import { useConnect } from "@stacks/connect-react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { Dialog } from "../../components/Dialog"
import { Spensor } from "../../components/Spensor"
import { useAccountStore } from "../../stores/accountStore/useAccountStore"
import { useAuthStore } from "../../stores/authStore/useAuthStore"
import { Currency } from "../../utils/alexjs/contractBase"
import { Result } from "../../utils/Result"
import { suspenseResource } from "../../utils/SuspenseResource"
import { assertNever } from "../../utils/types"
import {
  ActionSectionContent$AfterRegister,
  ActionSectionContent$BeforeRegister,
  ActionSectionContent$ClaimForNotRegistered,
  ActionSectionContent$ClaimForRegistered,
  ActionSectionContent$ConnectWallet,
  ActionSectionContent$FinishedFailed,
  ActionSectionContent$FinishedSucceed,
  ActionSectionContent$Loading,
  ActionSectionContent$Upcoming,
  ActionSectionFrame,
  RegisterConfirmModalContent,
  StepEndCountdown,
} from "./component/ActionSection/ActionSection"
import { NoMoreTicketAlertModalContent } from "./component/NoMoreTicketAlertModalContent"
import { LotteryTicket } from "./store/ClaimViewModule"
import { RegisterFormData } from "./store/LaunchPadRegisterModule"
import { LaunchingStatus } from "./store/LaunchPadStore"
import { useLaunchPadStore } from "./store/useLaunchPadStore"

export const WiredActionSection: FC<{ className?: string }> = props => {
  const authStore = useAuthStore()
  const store = useLaunchPadStore()

  const { doOpenAuth } = useConnect()

  return (
    <ActionSectionFrame
      className={props.className}
      titleRightSide={
        <Spensor fallback={null}>
          {() =>
            store.claim.currentStepEndedAt &&
            store.currentStatusEndBlock$ != null && (
              <StepEndCountdown
                currentStepNumber={store.claim.currentStepNumber}
                endBlock={store.currentStatusEndBlock$}
                currentBlock={store.currentBlock$}
                currentBlockEndedAt={store.claim.currentStepEndedAt}
              />
            )
          }
        </Spensor>
      }
    >
      <Spensor
        fallback={
          authStore.isWalletConnected ? (
            <ActionSectionContent$Loading />
          ) : (
            <Spensor fallback={<ActionSectionContent$Loading />}>
              {() => (
                <ActionSectionContent$ConnectWallet
                  currState={store.currentStatus$}
                  onConnect={doOpenAuth}
                />
              )}
            </Spensor>
          )
        }
      >
        {() => <WiredActionSectionContent />}
      </Spensor>
    </ActionSectionFrame>
  )
}

const WiredActionSectionContent: FC = () => {
  const store = useLaunchPadStore()
  const authStore = useAuthStore()

  if (store.tokenProfile.status.type === LaunchingStatus.Finished) {
    return store.tokenProfile.status.success ? (
      <ActionSectionContent$FinishedSucceed
        token={store.tokenProfile.tokenInfo}
        tokenIDOSuccessSummary={store.tokenProfile}
        onClaimRemainingLockedSTX={
          authStore.isWalletConnected && store.refund.canRefund
            ? () => store.refund.refund()
            : undefined
        }
      />
    ) : (
      <ActionSectionContent$FinishedFailed
        onClaimSTX={
          authStore.isWalletConnected && store.refund.canRefund
            ? () => store.refund.refund()
            : undefined
        }
        userAssets={
          authStore.isWalletConnected &&
          store.userIDO.hasRegistered &&
          store.userIDO.lockedSTX$ > 0
            ? {
                registeredLottery: store.userIDO.remainingRegisteredTicketCount,
                lockedSTX: store.userIDO.lockedSTX$,
              }
            : undefined
        }
      />
    )
  }

  if (store.tokenProfile.status.type === LaunchingStatus.Claim) {
    return store.userIDO.hasRegistered ? (
      <WiredActionSectionContent$ClaimForRegistered />
    ) : (
      <ActionSectionContent$ClaimForNotRegistered
        token={store.tokenProfile.tokenInfo}
        currentAllocationTokenCount={store.tokenProfile.totalAllocation}
      />
    )
  }

  if (store.tokenProfile.status.type === LaunchingStatus.Registration) {
    return !store.userIDO.hasRegistered ? (
      <WiredActionSectionContent$BeforeRegister />
    ) : (
      <ActionSectionContent$AfterRegister
        registeredLottery={store.userIDO.remainingRegisteredTicketCount}
        lockedSTX={store.userIDO.lockedSTX$}
        currentBlock={store.currentBlock$}
        startedAt={store.claimStartsAt$}
        startedAtBlock={store.claimStartsAtBlock$}
      />
    )
  }

  if (store.tokenProfile.status.type === LaunchingStatus.Upcoming) {
    return <ActionSectionContent$Upcoming />
  }

  assertNever(store.tokenProfile.status.type)
}

const WiredActionSectionContent$ClaimForRegistered: FC = () => {
  const { tokenProfile, claim, userIDO, token } = useLaunchPadStore()
  const { stxAddress$ } = useAuthStore()

  const initialValue = {
    pendingCount: userIDO.remainingRegisteredTicketCount,
    wonCount: userIDO.lotteryWon$,
    lostCount: userIDO.lotteryLost$,
    inflightIds: claim.memPoolClaims,
    wonStIds: claim.wonClaims,
    lostStIds: claim.lostClaims,
  }

  const options = useRef(initialValue)
  options.current = initialValue

  const [showFinishedAlert, setShowFinishedAlert] = useState(false)
  const storageKey = `launchpad-all-ticket-own-${token}-${stxAddress$}`

  const onOpenTicket = useCallback((ticket: LotteryTicket) => {
    if (ticket.type === LotteryTicket.Type.Waiting) {
      void ticket.onOpen?.()
    }
  }, [])

  const onRetryTicket = useCallback((ticket: LotteryTicket) => {
    if (ticket.type === LotteryTicket.Type.TransactionFailed) {
      void ticket.onRetry?.()
    }
  }, [])

  useEffect(() => {
    claim.materializeTicket(options.current).catch(() => null)
  }, [claim, options])

  useEffect(() => {
    if (
      tokenProfile.allTicketAllocated &&
      options.current.pendingCount > 0 &&
      !localStorage.getItem(storageKey)
    ) {
      setShowFinishedAlert(true)
    }
  }, [storageKey, tokenProfile.allTicketAllocated])

  return (
    <>
      <ActionSectionContent$ClaimForRegistered
        token={tokenProfile.tokenInfo}
        lotteryTickets={claim.tickets}
        onOpenTicket={onOpenTicket}
        onRetryTicket={onRetryTicket}
      />
      <Dialog visible={showFinishedAlert}>
        <NoMoreTicketAlertModalContent
          onConfirm={() => {
            setShowFinishedAlert(false)
            localStorage.setItem(storageKey, "true")
          }}
        />
      </Dialog>
    </>
  )
}

const WiredActionSectionContent$BeforeRegister: FC = () => {
  const store = useLaunchPadStore()
  const accStore = useAccountStore()

  const [formData, setFormData] = useState<null | RegisterFormData>(null)

  return (
    <>
      <ActionSectionContent$BeforeRegister
        formError={suspenseResource(() =>
          Result.maybeError(store.register.formData),
        )}
        lotteryTicketCount={store.register.ticketToRegister}
        lotteryTicketBalance={store.userIDO.ticketCount$}
        stxCount={store.register.willLockedSTXAmount$}
        stxBalance={accStore.getBalance$(Currency.STX)}
        stxToUSDT={store.register.willLockSTXEstimatedUSD$}
        pricePerTicket={store.tokenProfile.pricePerTicket}
        onLotteryTicketCountChange={c =>
          store.register.setTicketToRegister(c ?? 0)
        }
        onPressRegister={suspenseResource(() => {
          const { formData } = store.register
          return formData.type !== "ok"
            ? undefined
            : () => setFormData(formData.payload)
        })}
      />

      <Dialog visible={!!formData} onClose={() => setFormData(null)}>
        <RegisterConfirmModalContent
          lotteryTicketCount={store.register.ticketToRegister}
          stxCount={store.register.willLockedSTXAmount$}
          onConfirm={() => {
            if (!formData) return
            setFormData(null)
            void store.register.submitForm(formData)
          }}
          onClose={() => setFormData(null)}
        />
      </Dialog>
    </>
  )
}
