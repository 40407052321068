import {
  MempoolTransaction,
  Transaction,
} from "@stacks/stacks-blockchain-api-types"
import { API_HOST } from "../../config"

export async function fetchTransaction(
  stxId: string,
): Promise<Transaction | MempoolTransaction> {
  return fetch(`${API_HOST}/extended/v1/tx/${stxId}`).then(res =>
    res.ok ? res.json() : Promise.reject(res.statusText),
  )
}
