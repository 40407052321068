import { isStacksWalletInstalled } from "@stacks/connect"
import { isMobileDevice } from "../../utils/domHelpers"
import { untilWindowLoad } from "../../utils/untilWindowLoad"
import { LazyValue } from "../LazyValue/LazyValue"
import TimerValue from "../TimerValue"

export enum EnvironmentUnsupportedReason {
  WalletNotInstalled = "WalletNotInstalled",
  BrowserUnsupported = "BrowserUnsupported",
}

export class AppEnvStore {
  private environmentUnsupportedReasonRefreshTimer = new TimerValue(2000)
  environmentUnsupportedReason = new LazyValue(
    () => this.environmentUnsupportedReasonRefreshTimer.value,
    () => getEnvironmentUnsupportedReason(),
  )
}

async function getEnvironmentUnsupportedReason(): Promise<
  EnvironmentUnsupportedReason | undefined
> {
  const isMobile = isMobileDevice()
  const isBrowserSupported = (window as any).chrome != null || isMobile
  if (!isBrowserSupported) {
    return EnvironmentUnsupportedReason.BrowserUnsupported
  }

  // The Hiro Wallet extension will inject code into page to make
  // `isStacksWalletInstalled` returns true, so we need to wait until all assets
  // ready, otherwise we might get the wrong conclusion
  await untilWindowLoad()
  if (!isStacksWalletInstalled()) {
    return EnvironmentUnsupportedReason.WalletNotInstalled
  }

  return
}
