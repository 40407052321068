import clsx from "clsx"
import { FC } from "react"
import { TokenInfo } from "../utils/models/TokenInfo"

export interface TokenIconProps {
  className?: string

  boxClassName?: string

  token: TokenInfo

  /**
   * @default TokenIconProps.sizes.normal
   */
  size?: number
}
export namespace TokenIconProps {
  export namespace sizes {
    export const small = 24
    export const normal = 32
    export const large = 64
  }

  export const defaultSize = sizes.normal
}

export const TokenIcon: FC<TokenIconProps> = props => {
  const { size = TokenIconProps.defaultSize } = props

  return (
    <span
      className={clsx(
        "shrink-0",
        props.className,
        props.boxClassName ?? "inline-block",
      )}
      style={{ height: size }}
    >
      {props.token.type === "combined" ? (
        <MultiTokenIcon size={size} tokens={props.token.combinedTokens} />
      ) : (
        <SingleTokenIcon size={size} token={props.token} />
      )}
    </span>
  )
}

const SingleTokenIcon: FC<{
  className?: string
  token: TokenInfo.SingleTokenInfo
  size: number
}> = props => (
  <img
    className={"block"}
    alt={`Token ${props.token.name} icon`}
    src={props.token.iconSrc}
    style={{ minWidth: props.size, maxHeight: props.size }}
    height={props.size}
  />
)

const MultiTokenIcon: FC<{
  className?: string
  tokens: TokenInfo.SingleTokenInfo[]
  size: number
}> = props => {
  const marginLeft = -(props.size / 4)

  return (
    <ul className={clsx("flex flex-row-reverse", props.className)}>
      {props.tokens
        .slice()
        .reverse()
        .map((token, idx, tokens) => (
          <li
            key={idx}
            className={"shrink-0"}
            style={idx === tokens.length - 1 ? {} : { marginLeft }}
          >
            <SingleTokenIcon token={token} size={props.size} />
          </li>
        ))}
    </ul>
  )
}
