import clsx from "clsx"
import { FC, ReactNode } from "react"
import { ReactComponent as NoticeIcon } from "./notice.svg"

export const NoteParagraph: FC<{
  className?: string
  icon?: ReactNode
  textColorClassName?: string
}> = props => (
  <div
    className={clsx(
      "flex",
      props.className,
      props.textColorClassName ?? "text-gray-500",
    )}
  >
    <div className={"mr-2.5"}>
      <div className={"inline-flex items-center"}>
        &#8203; {/* zero-width space char */}
        {/*
        Above char is used to hold up this box, make it as height as one line
        text, then we can make the icon middle align with first line
        */}
        {props.icon ?? <NoticeIcon fill={"currentColor"} />}
      </div>
    </div>

    <div className={"flex-1 text-left text-sm sm:text-base"}>
      {props.children}
    </div>
  </div>
)
