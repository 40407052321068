import {
  ComponentType,
  createContext,
  CSSProperties,
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLProps,
  PropsWithoutRef,
  Ref,
  RefAttributes,
  useContext,
  useMemo,
  useState,
} from "react"
import { usePopper } from "react-popper"
import { useCombinedRef } from "../utils/refHelpers"

export type { Placement } from "@popperjs/core"

interface PopperContainerContextValue {
  setReferenceElement: (el: null | HTMLElement) => void
  setPopperElement: (el: null | HTMLElement) => void
  popperElStyles: Record<string, CSSProperties>
  popperElAttrs: any
}
const PopperContainerContext =
  createContext<null | PopperContainerContextValue>(null)

export type PopperOptions = Parameters<typeof usePopper>[2]

export const PopperContainer: FC<PopperOptions> = props => {
  const [referenceElement, setReferenceElement] = useState<null | HTMLElement>(
    null,
  )
  const [popperElement, setPopperElement] = useState<null | HTMLElement>(null)

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    props,
  )
  const ctxValue: PopperContainerContextValue = useMemo(
    () => ({
      setReferenceElement,
      setPopperElement,
      popperElStyles: styles,
      popperElAttrs: attributes,
    }),
    [attributes, styles],
  )

  return (
    <PopperContainerContext.Provider value={ctxValue}>
      {props.children}
    </PopperContainerContext.Provider>
  )
}

export const ReferenceElement = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>((props, forwardingRef) => {
  const { setReferenceElement } = useContext(PopperContainerContext) ?? {}

  const ref = useCombinedRef(
    forwardingRef,
    setReferenceElement as Ref<HTMLDivElement>,
  )

  return <div {...props} ref={ref} />
})

export function createPopperElement<ForwardRefT, Props>(
  asComp: string | ComponentType<Props>,
): ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<ForwardRefT>
> {
  return forwardRef<ForwardRefT, Props>((props, forwardingRef) => {
    const {
      setPopperElement,
      popperElStyles: styles,
      popperElAttrs: attributes,
    } = useContext(PopperContainerContext) ?? {}

    const popperElementRef = useCombinedRef(
      forwardingRef,
      setPopperElement as Ref<ForwardRefT>,
    )

    const Comp = asComp

    return (
      <Comp
        {...props}
        ref={popperElementRef}
        style={{ ...styles?.popper, ...(props as any).style }}
        {...attributes.popper}
      >
        {props.children}
      </Comp>
    )
  })
}

export const PopperElement = createPopperElement<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>("div")
