import { FC, Suspense, useEffect } from "react"
import { useAccountStore } from "./useAccountStore"

const TriggerSyncTransactionsOnIdle: FC = () => {
  const { transactions$ } = useAccountStore()
  useEffect(() => {
    if (window.requestIdleCallback == null) {
      const timer = setTimeout(() => {
        void transactions$.sync()
      }, 2000)
      return () => {
        clearTimeout(timer)
      }
    } else {
      window.requestIdleCallback(() => {
        void transactions$.sync()
      })
    }
  }, [transactions$])
  return null
}

const SyncTransactionsOnIdle: FC = () => {
  return (
    <Suspense fallback={null}>
      <TriggerSyncTransactionsOnIdle />
    </Suspense>
  )
}

export default SyncTransactionsOnIdle
