import { IActionRunInfo, _endAction, _startAction } from "mobx"
import { sleep } from "./promiseHelpers"

export const asyncAction: MethodDecorator = (
  target,
  propertyKey,
  descriptor,
) => {
  const originalMethod = descriptor.value
  descriptor.value = async function (this: any, ...args: any[]) {
    const key = propertyKey.toString()
    let currentRun: IActionRunInfo
    let timer: number
    const run = async (
      promise: Promise<any>,
      name = "awaited",
    ): Promise<any> => {
      // we are init the error here to capture the correct stack trace
      const forgetToWrapInRunError = new Error(
        `Did you forget to wrap await in run in ${key}`,
      )
      clearTimeout(timer)
      _endAction(currentRun)
      try {
        const result = await promise
        // without this, mobx will crash with unmatched action
        // when wrapped promise return instantly
        await sleep(0)
        return result
      } finally {
        currentRun = _startAction(
          key.toString() + " " + name,
          false,
          this,
          ...args,
        )
        timer = setTimeout(() => {
          throw forgetToWrapInRunError
        })
      }
    }
    currentRun = _startAction(key, false, this, ...args)
    // @ts-ignore
    return originalMethod.apply(this, [...args, run]).finally(() => {
      clearTimeout(timer)
      _endAction(currentRun)
    })
  } as any
}

// This is used a default value for asyncAction
// Because TS decorator is not able to mutate the signature currently
export function runAsyncAction<T>(i: T): T {
  return i
}
