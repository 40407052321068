import { Popover } from "@headlessui/react"
import clsx from "clsx"
import { FC, ReactNode, useState } from "react"
import { usePopper } from "react-popper"
import { isTouchDevice } from "../../utils/domHelpers"
import { useHover } from "../../utils/reactHelpers/useHover"
import styles from "./Tooltip.module.scss"

export interface TooltipProps {
  className?: string
  titleClassName?: string
  childrenContainerClassName?: string

  title: ReactNode
  visible?: boolean
  mouseEnterDelay?: number
}

export const Tooltip: FC<TooltipProps> = props => {
  const [referenceElement, setReferenceElement] =
    useState<null | HTMLDivElement>(null)
  const [popperElement, setPopperElement] = useState<null | HTMLDivElement>(
    null,
  )
  const [arrowElement, setArrowElement] = useState<null | HTMLDivElement>(null)

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: "top",
      modifiers: [{ name: "arrow", options: { element: arrowElement } }],
    },
  )

  const [hoverVisible, button] = useHover(
    () => (
      <Popover.Button
        as={"div"}
        ref={setReferenceElement}
        className={props.childrenContainerClassName}
      >
        {props.children}
      </Popover.Button>
    ),
    {
      mouseEnterDelay: props.mouseEnterDelay,
    },
  )

  return (
    <Popover className={props.className}>
      {({ open: clickVisible }) => {
        // prettier-ignore
        const open =
          props.visible != null ? props.visible :
          isTouchDevice() ? clickVisible :
          hoverVisible

        return (
          <>
            {button}

            {open && (
              <Popover.Panel
                ref={setPopperElement}
                static={true}
                className={clsx("z-1 drop-shadow-2xl", styles.popper)}
                style={{ ...popperStyles?.popper, ...(props as any).style }}
                {...attributes.popper}
              >
                <div>
                  <div
                    className={clsx(
                      "bg-black bg-opacity-75 px-2 py-1 rounded text-white max-w-[300px] whitespace-pre-line text-xs leading-4 font-normal",
                      props.titleClassName,
                    )}
                  >
                    {props.title}
                  </div>

                  <div
                    ref={setArrowElement}
                    className={clsx("text-black text-opacity-75", styles.arrow)}
                    style={popperStyles.arrow}
                  />
                </div>
              </Popover.Panel>
            )}
          </>
        )
      }}
    </Popover>
  )
}
