import { useRef } from "react"
import { AnyFunc } from "../types"

export function usePersistFn<F extends AnyFunc>(fn: F): F {
  const fnRef = useRef<F>(fn)
  fnRef.current = fn

  const persistFn = useRef<F>()
  if (!persistFn.current) {
    persistFn.current = function (this: any, ...args): any {
      return fnRef.current!.apply(this, args)
    } as F
  }

  return persistFn.current!
}
