import { useConnect } from "@stacks/connect-react"
import { FC, Suspense } from "react"
import { GradientButton } from "../../../../components/button/GradientButton/GradientButton"
import { CardInsetDivider, CardInsetTitle } from "../../../../components/Card"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../../components/NavLink"
import { TokenCount } from "../../../../components/TokenCount"
import { TokenName } from "../../../../components/TokenName"
import { Tooltip } from "../../../../components/Tooltip/Tooltip"
import { useAuthStore } from "../../../../stores/authStore/useAuthStore"
import { TokenInfo } from "../../../../utils/models/TokenInfo"
import { withClassName } from "../../../../utils/reactHelpers/withClassName"
import { TokenInfoPresets } from "../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { assertNever } from "../../../../utils/types"
import { LaunchingStatus } from "../../store/LaunchPadStore"
import { useLaunchPadStore } from "../../store/useLaunchPadStore"
import {
  HorizontalInfoListContainer,
  HorizontalInfoListItem,
} from "../InfoList"
import { NoteParagraph } from "../NoteParagraph/NoteParagraph"
import { MyIDOFrame } from "./components"
import { ReactComponent as CongratsIcon } from "./congrats.svg"
import { ReactComponent as FinishedIcon } from "./finished.svg"
import { ReactComponent as HelpIcon } from "./help.svg"
import { ReactComponent as OopsIcon } from "./oppps.svg"
import { ReactComponent as UpcomingIcon } from "./upcoming.svg"

export const WiredUserIDOStatusSection: FC<{ className?: string }> = (props: {
  className?: string
}) => {
  const authStore = useAuthStore()
  const { doOpenAuth } = useConnect()

  return (
    <MyIDOFrame className={props.className}>
      <Suspense fallback={<LoadingIndicator />}>
        {!authStore.isWalletConnected ? (
          <UserIDOStatusSectionContent$ConnectWallet onConnect={doOpenAuth} />
        ) : (
          <WiredUserIDOStatusSectionContent />
        )}
      </Suspense>
    </MyIDOFrame>
  )
}

const WiredUserIDOStatusSectionContent: FC = () => {
  const store = useLaunchPadStore()
  const authStore = useAuthStore()

  const module = store.userIDO

  if (module.idoStatus === LaunchingStatus.Upcoming) {
    return <UserIDOStatusSectionContent$Upcoming />
  }

  if (module.idoStatus === LaunchingStatus.Registration) {
    if (!module.hasRegistered) {
      return (
        <UserIDOStatusSectionContent$OpenPoolNotRegistered
          lotteryTicketCount={module.ticketCount$}
        />
      )
    } else {
      return (
        <UserIDOStatusSectionContent$OpenPoolAfterRegistered
          registeredLotteryCount={module.remainingRegisteredTicketCount}
          lockedSTXCount={module.lockedSTX$}
        />
      )
    }
  }

  if (module.idoStatus === LaunchingStatus.Claim) {
    if (!module.hasRegistered) {
      return <UserIDOStatusSectionContent$DidNotJoin />
    }

    return (
      <UserIDOStatusSectionContent$Lottery
        token={module.store.tokenProfile.tokenInfo}
        allocatedTokenCount={module.allocatedTokenCount$}
        lockedSTXCount={module.lockedSTX$}
        idleLotteryCount={module.lotteryToClaim$}
        wonLotteryCount={module.lotteryWon$}
        pendingLotteryCount={module.lotteryPending$}
      />
    )
  }

  if (module.idoStatus === LaunchingStatus.Finished) {
    if (!module.hasRegistered) {
      return <UserIDOStatusSectionContent$DidNotJoin />
    }

    return (
      <UserIDOStatusSectionContent$LotteryFinished
        allocatedToken={module.store.tokenProfile.tokenInfo}
        addressExplorerLink={authStore.addressExplorerLink$}
        allocatedTokenCount={module.allocatedTokenCount$}
        costedSTXCount={module.costSTX$}
        refundableSTXCount={module.refundableSTX$}
      />
    )
  }

  assertNever(module.idoStatus)
}

export const UserIDOStatusSectionContent$ConnectWallet: FC<{
  onConnect?: () => void
}> = props => {
  return (
    <>
      <CardInsetTitle>Please connect wallet first</CardInsetTitle>
      <GradientButton className={"min-w-[7.5rem]"} onClick={props.onConnect}>
        Connect Wallet
      </GradientButton>
    </>
  )
}

export const UserIDOStatusSectionContent$Upcoming: FC = () => {
  return (
    <>
      <UpcomingIcon />
      <CardInsetTitle>Validation not Started</CardInsetTitle>
    </>
  )
}

export const UserIDOStatusSectionContent$DidNotJoin: FC = () => {
  return (
    <>
      <FinishedIcon />
      <CardInsetTitle>Validation Finished</CardInsetTitle>
    </>
  )
}

export const UserIDOStatusSectionContent$OpenPoolNotRegistered: FC<{
  lotteryTicketCount: number
}> = props => {
  return (
    <>
      <div className={"w-full"}>
        <CardInsetTitle className={"mb-2"}>Not Validated</CardInsetTitle>

        <p
          className={
            "text-sm leading-5 font-normal text-gray-500 flex items-center justify-center"
          }
        >
          Lottery tickets:&nbsp;
          <TokenCount
            token={TokenInfoPresets.AlexLotteryTicket}
            count={props.lotteryTicketCount}
          />
          <Tooltip title={"Tickets need to get validated before lottery draw"}>
            <HelpIcon className={"ml-2.5 opacity-40"} fill={"#C4C4C4"} />
          </Tooltip>
        </p>
      </div>

      <OpenPoolLotteryInfoGroup registeredLotteryCount={0} lockedSTXCount={0} />
    </>
  )
}

export const UserIDOStatusSectionContent$OpenPoolAfterRegistered: FC<{
  registeredLotteryCount: number
  lockedSTXCount: number
}> = props => {
  return (
    <>
      <div className={"w-full"}>
        <CardInsetTitle className={"mb-2"}>Validated</CardInsetTitle>
      </div>

      <OpenPoolLotteryInfoGroup
        registeredLotteryCount={props.registeredLotteryCount}
        lockedSTXCount={props.lockedSTXCount}
      />
    </>
  )
}

export const UserIDOStatusSectionContent$Lottery: FC<{
  token: TokenInfo
  allocatedTokenCount: number
  lockedSTXCount: number
  idleLotteryCount: number
  wonLotteryCount: number
  pendingLotteryCount: number
}> = props => {
  return (
    <>
      <HorizontalInfoListContainer>
        <HorizontalInfoListItem
          title={"Total Allocation"}
          detail={
            <>
              <TokenCount
                token={props.token}
                count={props.allocatedTokenCount}
              />
              &nbsp;
              <TokenNameInHorizontalInfoList token={props.token} />
            </>
          }
        />
        <HorizontalInfoListItem
          title={"Locked STX"}
          detail={
            <>
              <TokenCount
                token={TokenInfoPresets.STX}
                count={props.lockedSTXCount}
              />
              &nbsp;
              <TokenNameInHorizontalInfoList token={TokenInfoPresets.STX} />
            </>
          }
        />
      </HorizontalInfoListContainer>

      <CardInsetDivider />

      <HorizontalInfoListContainer>
        <HorizontalInfoListItem
          title={"Lottery to Claim"}
          detail={props.idleLotteryCount}
        />
        <HorizontalInfoListItem
          title={"Lottery Won"}
          detail={props.wonLotteryCount}
        />
        <HorizontalInfoListItem
          title={"Lottery in Pending"}
          detail={props.pendingLotteryCount}
        />
      </HorizontalInfoListContainer>

      <NoteParagraph>
        <TokenName token={props.token} /> will be issued for every winning
        lottery ticket. Unlocked <TokenName token={TokenInfoPresets.STX} /> will
        be returned back to your wallet.
        <br />
        Transactions may proceed gradually on STACKS chain, please be patient.
      </NoteParagraph>
    </>
  )
}

export const UserIDOStatusSectionContent$LotteryFinished: FC<{
  allocatedToken: TokenInfo
  addressExplorerLink: string
  allocatedTokenCount: number
  costedSTXCount: number
  refundableSTXCount: number
}> = props => {
  return (
    <>
      <div className={"flex flex-col items-center gap-4"}>
        {props.allocatedTokenCount > 0 ? (
          <>
            <CongratsIcon />
            <CardInsetTitle>Congratulation!</CardInsetTitle>
          </>
        ) : (
          <>
            <OopsIcon />
            <CardInsetTitle>Oops...maybe next time</CardInsetTitle>
          </>
        )}
      </div>

      <CardInsetDivider />

      <HorizontalInfoListContainer>
        <HorizontalInfoListItem
          title={"Total Allocation"}
          detail={
            <>
              <TokenCount
                token={props.allocatedToken}
                count={props.allocatedTokenCount}
              />
              &nbsp;
              <TokenNameInHorizontalInfoList token={props.allocatedToken} />
            </>
          }
        />
        <HorizontalInfoListItem
          title={
            props.allocatedTokenCount > 0
              ? "Cost STX"
              : "Will return to your wallet"
          }
          detail={
            <>
              <TokenCount
                token={TokenInfoPresets.STX}
                count={
                  props.allocatedTokenCount > 0
                    ? props.costedSTXCount
                    : props.refundableSTXCount
                }
              />
              &nbsp;
              <TokenNameInHorizontalInfoList token={TokenInfoPresets.STX} />
            </>
          }
        />
      </HorizontalInfoListContainer>

      <CardInsetDivider />

      <p className={"text-sm leading-5 font-normal text-gray-300 text-center"}>
        <TokenCount
          token={TokenInfoPresets.STX}
          count={props.refundableSTXCount}
        />
        &nbsp;
        <TokenName token={TokenInfoPresets.STX} />
        &nbsp; Unlocked and will return to your wallet
      </p>

      <NoteParagraph className={"text-xs leading-4 font-normal"}>
        Allocated token will sent to your wallet.
        <br />
        If you have any questions, please contact{" "}
        <NavLink to={"mailto:help@alexgo.io"}>help@alexgo.io</NavLink>.{" "}
        <NavLink className={"text-indigo-500"} to={props.addressExplorerLink}>
          View in explorer
        </NavLink>
      </NoteParagraph>
    </>
  )
}

const TokenNameInHorizontalInfoList = withClassName(
  "text-base leading-6 font-medium text-gray-200 ml-2",
  TokenName,
)

interface OpenPoolLotteryInfoGroupProps {
  className?: string
  registeredLotteryCount: number
  lockedSTXCount: number
}
const OpenPoolLotteryInfoGroup: FC<OpenPoolLotteryInfoGroupProps> = props => {
  return (
    <HorizontalInfoListContainer className={props.className}>
      <HorizontalInfoListItem
        title={"Validated Lottery"}
        detail={props.registeredLotteryCount}
      />
      <HorizontalInfoListItem
        title={"Locked STX"}
        detail={props.lockedSTXCount}
      />
    </HorizontalInfoListContainer>
  )
}
