import clsx from "clsx"
import { FC } from "react"
import {
  FinishedBadge,
  ProgressingBadge,
  UpcomingBadge,
} from "../../../../components/Badge"
import { TokenName } from "../../../../components/TokenName"
import { assertNever } from "../../../../utils/types"
import { LaunchingStatus } from "../../store/LaunchPadStore"
import { TokenProfileInfo } from "../types"
import { IDOTokenLinks } from "./IDOTokenLinks/IDOTokenLinks"

export const Header: FC<{
  className?: string
  tokenProfile: TokenProfileInfo
}> = ({ tokenProfile, className }) => (
  <div className={clsx("flex gap-6", className)}>
    <img
      className={"w-24 h-24 rounded-lg"}
      alt={"Token logo"}
      src={tokenProfile.meta.logoSrc}
    />
    <div className={"flex-1"}>
      <div className={"flex items-center justify-between mb-6"}>
        <TokenName
          className={"text-gray-200 text-2xl leading-8 font-medium"}
          token={tokenProfile.tokenInfo}
        />
        <IDOStatusBadge status={tokenProfile.status.type} />
      </div>
      <div className={"flex gap-6"}>
        <IDOTokenLinks links={tokenProfile.meta.links} />
      </div>
    </div>
  </div>
)

const IDOStatusBadge: FC<{ status: LaunchingStatus }> = props => {
  const { status } = props
  switch (status) {
    case LaunchingStatus.Upcoming:
      return <UpcomingBadge>upcoming</UpcomingBadge>
    case LaunchingStatus.Registration:
    case LaunchingStatus.Claim:
      return <ProgressingBadge>pool open</ProgressingBadge>
    case LaunchingStatus.Finished:
      return <FinishedBadge>finished</FinishedBadge>
    default:
      assertNever(status)
  }
}
