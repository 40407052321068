import {
  createClient,
  dedupExchange,
  fetchExchange,
  subscriptionExchange,
} from "@urql/core"
import type { OperationResult } from "@urql/core/dist/types/types"
import { devtoolsExchange } from "@urql/devtools"
import { SubscriptionClient } from "subscriptions-transport-ws"
import * as config from "../config"
import { Result } from "./Result"

const subscriptionClient = new SubscriptionClient(
  "wss://" + config.LAPLACE_GRAPHQL_API_HOST,
  {
    reconnect: true,
  },
)

const gqlClient = createClient({
  url: "https://" + config.LAPLACE_GRAPHQL_API_HOST,
  fetchOptions: {},
  exchanges: [
    devtoolsExchange,

    /**
     * The urql defaultExchanges, except cacheExchange, we have ourselves cache
     * layer
     * https://github.com/FormidableLabs/urql/blob/ef4b5b002d31e17d84fb41e5feb24d2200c9656f/packages/core/src/exchanges/index.ts#L15
     */
    ...[dedupExchange, fetchExchange],

    subscriptionExchange({
      forwardSubscription: operation => subscriptionClient.request(operation),
    }),
  ],
})

export const gqlQuery = gqlClient.query

export const gqlSubscription = gqlClient.subscription

export type OkOperationResult<R extends OperationResult<any, any>> = R & {
  data: NonNullable<R["data"]>
}
export type ErrorOperationResult<
  R extends OperationResult<any, any> = OperationResult<any, any>,
> = R & {
  error: NonNullable<R["error"]>
}
export function operationResultToResult<R extends OperationResult<any, any>>(
  input: R,
): Result<OkOperationResult<R>, ErrorOperationResult<R>> {
  if (input.data != null) {
    return Result.ok(input as any)
  } else {
    return Result.error(input as any)
  }
}
