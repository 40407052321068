import { FC } from "react"
import { TokenInfo } from "../utils/models/TokenInfo"
import { getPrecisionFloor } from "../utils/numberHelpers"

export interface TokenCountProps {
  token: TokenInfo
  count: number
}

export const TokenCount: FC<TokenCountProps> = props => {
  return <>{formatTokenCount(props.token, props.count)}</>
}

export function formatTokenCount(token: TokenInfo, count: number): string {
  const precision = TokenInfo.getPrecision(token)
  const precisionFloor = getPrecisionFloor(precision)

  if (count === 0) return "0"
  if (count < precisionFloor) return `<${precisionFloor}`

  // according to @fiftyeightandeight
  // we need to always round up when display number
  const scale = Math.pow(10, precision)
  const roundedUp = Math.ceil(count * scale) / scale
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: precision,
  }).format(roundedUp)
}
