import { computed, makeObservable } from "mobx"
import { TokenInfo } from "../../../utils/models/TokenInfo"
import {
  TokenIDOSuccessInfo,
  TokenIDOSummaryInfo,
  TokenMetaInfo,
  TokenProfileInfo,
  TokenProfileStatus,
} from "../component/types"
import { launchMeta } from "./launchMata/launchMeta"
import LaunchPadStore, { LaunchingStatus } from "./LaunchPadStore"

export class TokenProfileViewModule
  implements TokenIDOSummaryInfo, TokenProfileInfo, TokenIDOSuccessInfo
{
  constructor(private readonly store: LaunchPadStore) {
    makeObservable(this)
  }

  get meta(): TokenMetaInfo {
    const info = launchMeta[this.store.token]
    return {
      logoSrc: info.logoSrc,
      introHtml: info.introHtml,
      links: {
        website: info.website,
        twitter: info.twitter,
        medium: info.medium,
        discord: info.discord,
      },
    }
  }

  get tokenInfo(): TokenInfo {
    return launchMeta[this.store.token].tokenInfo
  }

  @computed get status(): TokenProfileStatus {
    if (this.store.currentStatus$ === LaunchingStatus.Finished) {
      return {
        type: LaunchingStatus.Finished,
        success: this.store.activationProgress$ >= 1,
      }
    }
    return {
      type: this.store.currentStatus$,
    }
  }

  @computed get activationProgress(): number {
    return this.store.activationProgress$
  }

  @computed get activationThreshold(): number {
    return this.store.activationThreshold$
  }

  @computed get currentActivation(): number {
    return this.store.currentActivation$
  }

  @computed get raiseSize(): number {
    return this.store.detail$["total-tickets"] * this.pricePerTicket
  }

  @computed get fillRate(): number {
    return (
      this.store.detail$["total-subscribed"] /
      this.store.detail$["total-tickets"]
    )
  }

  @computed get totalAllocation(): number {
    return this.totalTicketWon * this.tokenAmountPerTicket
  }

  @computed get totalTicketWon(): number {
    return this.store.detail$["tickets-won"]
  }

  @computed get tokenAmountPerTicket(): number {
    return this.store.detail$["amount-per-ticket"]
  }

  @computed get pricePerToken(): number {
    return this.pricePerTicket / this.tokenAmountPerTicket
  }

  @computed get pricePerTicket(): number {
    return this.store.detail$["wstx-per-ticket-in-fixed"] / 1e8
  }

  @computed get allTicketAllocated(): boolean {
    return this.totalTicketWon >= this.store.detail$["total-tickets"]
  }
}
