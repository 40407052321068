import {
  Address,
  ClarityType,
  ClarityValue,
  contractPrincipalCV,
  FungiblePostCondition,
  listCV,
  noneCV,
  someCV,
  STXPostCondition,
  uintCV,
} from "@stacks/transactions"
import { CONTRACT_DEPLOYER, TOKEN_MAP_OVERWRITES } from "../../config"
import { assertNever } from "../types"
import { GetInputArgs } from "./asSender"
import { errorMsgForCode } from "./errorCode"

export type Encoder<T> = (value: T) => ClarityValue
export type Decoder<T> = (value: ClarityValue) => T

export class ClarityError extends Error {
  constructor(readonly code: number) {
    super(errorMsgForCode(code))
  }
}

export function parseResult<T>(
  success: Decoder<T>,
  failure: Decoder<Error> = v => {
    if (
      v.type === ClarityType.StringASCII ||
      v.type === ClarityType.StringUTF8
    ) {
      return new Error(v.data)
    }
    if (v.type === ClarityType.UInt || v.type === ClarityType.Int) {
      return new ClarityError(Number(v.value))
    }
    return new Error("Unknown error")
  },
): Decoder<T> {
  return (value: ClarityValue) => {
    if (value.type === ClarityType.ResponseOk) {
      return success(value.value)
    } else if (value.type === ClarityType.ResponseErr) {
      const error = failure(value.value)
      console.log(error)
      throw error
    }
    return success(value)
  }
}

export function optionalDecoder<T>(
  decoder: Decoder<T>,
): Decoder<T | undefined> {
  return value => {
    if (value.type === ClarityType.OptionalNone) {
      return undefined
    } else if (value.type === ClarityType.OptionalSome) {
      return decoder(value.value)
    }
    return decoder(value)
  }
}

export const boolResult: Decoder<boolean> = result => {
  if (result.type === ClarityType.BoolTrue) {
    return true
  }
  if (result.type === ClarityType.BoolFalse) {
    return false
  }
  throw new Error(`Expected integer, got ${result.type}`)
}

export const principleResult: Decoder<Address> = result => {
  if (
    result.type === ClarityType.PrincipalStandard ||
    result.type === ClarityType.PrincipalContract
  ) {
    return result.address
  }
  throw new Error(`Expected principal, got ${result.type}`)
}

export const tokenResult: Decoder<FungibleToken> = result => {
  if (result.type === ClarityType.PrincipalContract) {
    const token = Object.values(Currency).find(
      key => key === result.contractName.content,
    )
    if (token == null) {
      throw new Error(`Unknown token ${result.contractName.content}`)
    }
    return token as FungibleToken
  }
  throw new Error(`Expected principal, got ${result.type}`)
}

export const intResult: Decoder<number> = result => {
  if (result.type === ClarityType.Int || result.type === ClarityType.UInt) {
    return Number(result.value)
  }
  throw new Error(`Expected integer, got ${result.type}`)
}

export const intListResult = listDecoder(intResult)

export function tupleDecoder<P extends Record<string, Decoder<any>>>(
  decorators: P,
): Decoder<{
  [K in keyof P]: ReturnType<P[K]>
}> {
  return input => {
    if (input.type !== ClarityType.Tuple) {
      throw new Error(`Expected tuple, got ${input.type}`)
    }
    const result = {} as any
    for (const key of Object.keys(decorators)) {
      result[key] = decorators[key as keyof P]!(input.data[key]!)
    }
    return result
  }
}

export const contractRef: Encoder<string> = c =>
  contractPrincipalCV(CONTRACT_DEPLOYER, c)

export type ReadonlyFunctionDescriptor = {
  mode?: "readonly"
  input: readonly Encoder<any>[]
  output: Decoder<any>
}
export type OpenCallFunctionDescriptor = {
  mode: "public"
  input: readonly Encoder<any>[]
  postConditions?: (
    stxAddress: string,
    input: any,
    extraArgs: any,
  ) => Array<FungiblePostCondition | STXPostCondition>
}

export function definePublicCalls<
  Input extends readonly Encoder<any>[],
  ExtraArgs,
>(
  input: Input,
  postConditions: (
    stxAddress: string,
    args: GetInputArgs<Input>,
    extraArg: ExtraArgs,
  ) => Array<FungiblePostCondition | STXPostCondition>,
): {
  mode: "public"
  input: Input
  postConditions: (
    stxAddress: string,
    args: GetInputArgs<Input>,
    extraArg: ExtraArgs,
  ) => Array<FungiblePostCondition | STXPostCondition>
} {
  return {
    mode: "public",
    input,
    postConditions,
    // postConditions: null as any,
  }
}

export type FunctionDescriptor =
  | ReadonlyFunctionDescriptor
  | OpenCallFunctionDescriptor

export function defineContract<
  T extends {
    [contracts: string]: {
      [func: string]: FunctionDescriptor
    }
  },
>(contracts: T): T {
  return contracts
}

export function optional<T>(encoder: Encoder<T>): Encoder<T | undefined> {
  return value => {
    if (value === undefined) {
      return noneCV()
    }
    return someCV(encoder(value))
  }
}

export function listEncoder<T>(encoder: Encoder<T>): Encoder<T[]> {
  return value => {
    return listCV(value.map(encoder))
  }
}

export function listDecoder<T>(decoder: Decoder<T>): Decoder<T[]> {
  return value => {
    if (value.type === ClarityType.List) {
      return value.list.map(decoder)
    }
    throw new Error(`Expected list, got ${value.type}`)
  }
}

export enum Currency {
  STX = "token-wstx",
  WBTC = "token-wbtc",
  USDA = "token-usda",
  Alex = "age000-governance-token",
  AlexLottery = "lottery-ido-alex",
  APower = "token-apower",
  //LP tokens
  FWP_WSTX_ALEX_50_50_V1_01 = "fwp-wstx-alex-50-50-v1-01",
  FWP_WSTX_WBTC_50_50_V1_01 = "fwp-wstx-wbtc-50-50-v1-01",
}

export const currencyContractMap: {
  [P in Currency]: `${string}.${string}::${string}`
} = {
  [Currency.STX]: `${CONTRACT_DEPLOYER}.token-wstx::wstx`,
  [Currency.USDA]: `${CONTRACT_DEPLOYER}.token-usda::usda`,
  [Currency.Alex]: `${CONTRACT_DEPLOYER}.age000-governance-token::alex`,
  [Currency.AlexLottery]: `${CONTRACT_DEPLOYER}.lottery-ido-alex::lottery-ido-alex`,
  [Currency.APower]: `${CONTRACT_DEPLOYER}.token-apower::apower`,
  [Currency.WBTC]: `${CONTRACT_DEPLOYER}.token-xbtc::xbtc`,
  [Currency.FWP_WSTX_ALEX_50_50_V1_01]: `${CONTRACT_DEPLOYER}.fwp-wstx-alex-50-50-v1-01::fwp-wstx-alex-50-50-v1-01`,
  [Currency.FWP_WSTX_WBTC_50_50_V1_01]: `${CONTRACT_DEPLOYER}.fwp-wstx-wbtc-50-50-v1-01::fwp-wstx-wbtc-50-50-v1-01`,
  ...TOKEN_MAP_OVERWRITES,
}

export type FungibleToken = Currency

export type SwappableCurrency = Currency.STX | Currency.Alex | Currency.WBTC

export const fwpTokens = [
  Currency.FWP_WSTX_ALEX_50_50_V1_01,
  Currency.FWP_WSTX_WBTC_50_50_V1_01,
] as const

export type FWPToken = typeof fwpTokens[number]

export const isFWPToken = (token: string): token is FWPToken => {
  return fwpTokens.includes(token as any)
}

export type LiquidityToken = Currency.STX | Currency.WBTC | Currency.Alex
export type LiquidityPoolToken = FWPToken

export const liquidityTokenPairs = (
  poolToken: LiquidityPoolToken,
): [LiquidityToken, LiquidityToken] => {
  switch (poolToken) {
    case Currency.FWP_WSTX_ALEX_50_50_V1_01:
      return [Currency.STX, Currency.Alex]
    case Currency.FWP_WSTX_WBTC_50_50_V1_01:
      return [Currency.STX, Currency.WBTC]
    default:
      assertNever(poolToken)
  }
}

export const currencyContract: Encoder<Currency> = contractRef

export const numberCV = uintCV as Encoder<number>
