import { memoize } from "lodash"
import { createTransformer } from "mobx-utils"
import { asSender } from "../../utils/alexjs/asSender"
import {
  Currency,
  FungibleToken,
  FWPToken,
} from "../../utils/alexjs/contractBase"
import AuthStore from "../authStore/AuthStore"
import { LazyValue } from "../LazyValue/LazyValue"
import { pMemoizeDecorator } from "../LazyValue/pMemoizeDecorator"
import {
  fetchLatestPrice,
  getDxDyOnFixedWeightPool,
} from "./CurrencyStore.service"

class CurrencyStore {
  constructor(
    readonly authStore: Pick<AuthStore, "stxAddress$" | "currentBlockHeight">,
  ) {}

  private prices = memoize(
    (currency: FungibleToken) =>
      new LazyValue(
        () => null,
        () => fetchLatestPrice(currency),
      ),
  )

  private _fwpPriceBreakdown = memoize(
    (
      currency:
        | Currency.FWP_WSTX_WBTC_50_50_V1_01
        | Currency.FWP_WSTX_ALEX_50_50_V1_01,
    ) =>
      new LazyValue(
        () => [currency, this.authStore.currentBlockHeight] as const,
        ([currency]) => getDxDyOnFixedWeightPool(currency),
        { decorator: pMemoizeDecorator({ persistKey: `fwp-dx-dy` }) },
      ),
  )

  fetchFWPBreakdown = createTransformer((currency: FWPToken) => {
    return this._fwpPriceBreakdown(currency).value$
  })

  getPrice$ = createTransformer((currency: FungibleToken): number => {
    if (
      currency === Currency.FWP_WSTX_WBTC_50_50_V1_01 ||
      currency === Currency.FWP_WSTX_ALEX_50_50_V1_01
    ) {
      const { y, x, dx, dy } = this.fetchFWPBreakdown(currency)
      return this.prices(x).value$ * dx + this.prices(y).value$ * dy
    }
    return this.prices(currency).value$
  })

  // private scales = memoize(
  //   (token: Currency) =>
  //     new LazyValue(
  //       () => token,
  //       t => this.fetchLatestScale(t),
  //       { persistKey: `token-scale-cache` },
  //     ),
  // )

  getScale$ = createTransformer((currency: Currency): number =>
    currency === Currency.STX ? 6 : 8,
  )
  private yieldTokenPrices = memoize(
    (currency: Currency, expiry: number) =>
      new LazyValue(
        () => this.authStore.stxAddress$,
        stxAddress =>
          asSender(stxAddress)
            .contract("yield-token-pool")
            .func("get-price")
            .call([expiry * 1e8, currency]),
      ),
  )

  async updatePrices(token: FungibleToken): Promise<void> {
    await this.prices(token).triggerUpdate()
  }
}

export default CurrencyStore
