import { FinishedTxData, openContractCall } from "@stacks/connect"
import {
  AnchorMode,
  callReadOnlyFunction,
  PostConditionMode,
  ReadOnlyFunctionOptions,
} from "@stacks/transactions"
import {
  ALLOW_CONTRACT_ARGUMENTATION,
  CONTRACT_DEPLOYER,
  STACK_APP_DETAILS,
  STACK_NETWORK,
} from "../../config"
import { CancelError } from "../error"
import { alexContracts } from "./alexContracts"
import {
  Encoder,
  FunctionDescriptor,
  OpenCallFunctionDescriptor,
  parseResult,
  ReadonlyFunctionDescriptor,
} from "./contractBase"

export type AlexContracts = typeof alexContracts

export const contractVariantsForTesting: { [origin: string]: string } = {}

export const currentContractName = (contract: string): string =>
  ALLOW_CONTRACT_ARGUMENTATION
    ? contractVariantsForTesting[contract] ?? contract
    : contract

export type GetInputArgs<Input extends readonly unknown[]> = {
  [P in keyof Input]: Input[P] extends Encoder<infer T> ? T : never
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const asSender = (senderAddress: string, blockHeight?: number) => ({
  contract: <T extends keyof AlexContracts>(
    contractOrType: T,
    actualContractName?: string,
  ) => ({
    func: <F extends keyof AlexContracts[T]>(functionName: F) => ({
      call: async <Descriptor extends AlexContracts[T][F]>(
        args: Descriptor extends { input: infer Input }
          ? Input extends readonly unknown[]
            ? GetInputArgs<Input>
            : never
          : never,
        ...extraArgs: Descriptor extends {
          postConditions: (
            sender: string,
            args: any,
            extraArgs: infer Extra,
          ) => any
        }
          ? Extra extends []
            ? []
            : [Extra]
          : []
      ): Promise<
        Descriptor extends ReadonlyFunctionDescriptor
          ? ReturnType<Descriptor["output"]>
          : Descriptor extends OpenCallFunctionDescriptor
          ? FinishedTxData
          : never
      > => {
        if (senderAddress == null) {
          throw new Error(`senderAddress is not set`)
        }
        const functionDescriptor = alexContracts[contractOrType][
          functionName
        ] as any as FunctionDescriptor

        const clarityArgs = functionDescriptor.input.map((value, index) =>
          value(args[index]),
        )
        const contract = actualContractName ?? contractOrType
        const sharedOptions: ReadOnlyFunctionOptions = {
          network: STACK_NETWORK,
          contractName: currentContractName(contract),
          contractAddress: CONTRACT_DEPLOYER,
          functionName: String(functionName),
          functionArgs: clarityArgs,
          senderAddress,
        } as const
        if (functionDescriptor.mode === "public") {
          console.log(`Calling public ${contract}.${functionName} with: `, args)
          return (await new Promise<FinishedTxData>((resolve, reject) => {
            openContractCall({
              ...sharedOptions,
              network: STACK_NETWORK as any,
              appDetails: STACK_APP_DETAILS,
              anchorMode: AnchorMode.Any,
              //TODO: it's blocking swap function, so I comment it by now
              postConditionMode:
                functionDescriptor.postConditions != null
                  ? PostConditionMode.Deny
                  : PostConditionMode.Allow,
              postConditions: functionDescriptor.postConditions?.(
                senderAddress,
                args,
                extraArgs[0],
              ),
              onFinish: e => {
                // note(zhigang1992): this is a hack to get around the
                // fact hiro updated their API accidentally
                // noinspection SuspiciousTypeOfGuard
                resolve({
                  ...e,
                  txId:
                    typeof e.txId === "string" ? e.txId : (e.txId as any).txid,
                })
              },
              onCancel: () => reject(new CancelError("Cancelled")),
            }).catch(reject)
          })) as any
        } else {
          console.log(
            `Calling readonly ${contract}.${functionName} with: `,
            args,
          )
          const result = await callReadOnlyFunction({
            ...sharedOptions,
            tip: blockHeight,
          })
          const value = parseResult(functionDescriptor.output)(result)
          console.log(
            `Finished readonly ${contract}.${functionName} with: `,
            value,
          )
          return value
        }
      },
    }),
  }),
})
