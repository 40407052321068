import { cloneElement, ReactElement, useEffect, useMemo, useState } from "react"

export type TriggerElement =
  | ((isHover: boolean) => ReactElement<any>)
  | ReactElement<any>

export interface UseHoverOptions {
  mouseEnterDelay?: number
}

const useHandleHoverOptions = (
  options: UseHoverOptions = {},
): {
  isHover: boolean
  setIsHover: (isHover: boolean) => void
  delayedIsHover: boolean
} => {
  const [isHover, setIsHover] = useState(false)
  const [delayedIsHover, setDelayedIsHover] = useState(false)

  useEffect(() => {
    if (isHover) {
      const timer = setTimeout(() => {
        setDelayedIsHover(true)
      }, (options.mouseEnterDelay ?? 0) * 1000)

      return () => {
        clearTimeout(timer)
      }
    } else {
      setDelayedIsHover(false)
    }
  }, [options.mouseEnterDelay, isHover])

  return {
    isHover,
    setIsHover,
    delayedIsHover,
  }
}

export interface UseHoverCallbacks {
  onMouseEnter: () => void
  onMouseLeave: () => void
}
export const useHoverCallbacks = (
  options: UseHoverOptions = {},
): [visible: boolean, listeners: UseHoverCallbacks] => {
  const { delayedIsHover, setIsHover } = useHandleHoverOptions(options)

  const callbacks = useMemo(
    () => ({
      onMouseEnter: () => {
        setIsHover(true)
      },
      onMouseLeave: () => {
        setIsHover(false)
      },
    }),
    [setIsHover],
  )

  return [delayedIsHover, callbacks]
}

export const useHover = (
  trigger: TriggerElement,
  options: UseHoverOptions = {},
): [boolean, ReactElement<any>] => {
  const { isHover, delayedIsHover, setIsHover } = useHandleHoverOptions(options)

  let el: ReactElement<any>
  if (typeof trigger === "function") {
    el = trigger(isHover)
  } else {
    el = trigger
  }

  const retEl = cloneElement(el, {
    onMouseEnter: (e: any) => {
      el.props.onMouseEnter?.(e)
      setIsHover(true)
    },
    onMouseLeave: (e: any) => {
      el.props.onMouseLeave?.(e)
      setIsHover(false)
    },
  })

  return [delayedIsHover, retEl]
}
