import { AddressBalanceResponse } from "@stacks/stacks-blockchain-api-types"
import { API_HOST } from "../../config"

export async function fetchBalanceForAccount(
  stxAddress: string,
): Promise<AddressBalanceResponse> {
  const response = await fetch(
    `${API_HOST}/extended/v1/address/${stxAddress}/balances`,
  )
  return await response.json()
}
