import clsx from "clsx"
import { FC } from "react"
import { FilledButtonVariantProps } from "../FilledButton"
import { BaseFilledButton } from "../_/BaseFilledButton"
import styles from "./GradientButton.module.scss"

export const GradientButton: FC<FilledButtonVariantProps> = props => {
  return (
    <BaseFilledButton
      {...props}
      className={clsx("text-gray-100", props.className)}
      bgClassName={styles.buttonBg}
    >
      {props.children}
    </BaseFilledButton>
  )
}
