import clsx from "clsx"
import { CSSProperties, FC, ReactNode } from "react"
import { FilledButton } from "../../../../../components/button/FilledButton"
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator"
import { NavLink } from "../../../../../components/NavLink"
import { TokenCount } from "../../../../../components/TokenCount"
import { TokenName } from "../../../../../components/TokenName"
import { TokenInfo } from "../../../../../utils/models/TokenInfo"
import { withClassName } from "../../../../../utils/reactHelpers/withClassName"
import { TokenInfoPresets } from "../../../../../utils/TokenInfoPresets/TokenInfoPresets"
import { assertNever } from "../../../../../utils/types"
import { LotteryTicket } from "../../../store/ClaimViewModule"
import { ReactComponent as DefaultIcon } from "./default.svg"
import { ReactComponent as LoseIcon } from "./lose.svg"
import { ReactComponent as ViewInExplorerIcon } from "./viewInExplorer.svg"
import { ReactComponent as WarnIcon } from "./warn.svg"
import { ReactComponent as WonIcon } from "./won.svg"

const LotteryTicketCardContainer: FC<{
  bgClassName?: string
  bgStyle?: CSSProperties
  borderClassName?: string
  mainArea?: ReactNode
  actionArea?: ReactNode
}> = props => (
  <div
    className={clsx(
      "p-2.5 w-40 h-40 rounded overflow-hidden flex flex-col items-center",
      !props.bgClassName && !props.bgStyle && "bg-blue-500 bg-opacity-20",
      props.bgClassName,
      props.borderClassName ?? "border border-blue-300 border-opacity-20",
    )}
    style={props.bgStyle}
  >
    <div className={"flex-1 flex flex-col items-center justify-center"}>
      {props.mainArea}
    </div>

    {props.actionArea}
  </div>
)

const CardNormalText = withClassName("text-xs leading-4 font-normal", "span")

const ExplorerLink: FC<{ link: string }> = props => (
  <NavLink
    className={
      "text-xs leading-4 font-normal text-gray-100 h-[24px] leading-[24px]"
    }
    to={props.link}
  >
    <ViewInExplorerIcon className={"inline align-baseline mr-[5px]"} />
    View in explorer
  </NavLink>
)

const ActionButton: FC<{
  className?: string
  onClick?: () => void
}> = props => (
  <FilledButton
    className={"uppercase w-[100px] h-[24px] leading-[24px]"}
    boxClassName={"px-1"}
    textClassName={"text-gray-900 text-xs leading-4 font-semibold"}
    onClick={props.onClick}
  >
    {props.children}
  </FilledButton>
)

export const LotteryTicketCard$Waiting: FC<{ onOpen?: () => void }> = props => (
  <LotteryTicketCardContainer
    mainArea={
      <>
        <DefaultIcon />
        <CardNormalText className={"mt-2.5 text-white"}>
          Waiting to open
        </CardNormalText>
      </>
    }
    actionArea={<ActionButton onClick={props.onOpen}>Open</ActionButton>}
  />
)

export const LotteryTicketCard$Pending: FC<{
  ticketExplorerLink: string
  telegramSubscribeLink: string
}> = props => (
  <LotteryTicketCardContainer
    mainArea={
      <>
        <DefaultIcon />
        <CardNormalText className={"mt-2.5 text-white"}>
          <LoadingIndicator
            className={"inline mr-1.5 align-bottom"}
            size={16}
          />
          Pending
        </CardNormalText>
      </>
    }
    actionArea={
      <div className={"text-center leading-4"}>
        <p>
          <ExplorerLink link={props.ticketExplorerLink} />
        </p>
        <p>
          <NavLink target={"_blank"} to={props.telegramSubscribeLink}>
            <CardNormalText className={"mt-2.5 text-lime-500"}>
              TG Notification
            </CardNormalText>
          </NavLink>
        </p>
      </div>
    }
  />
)

export const LotteryTicketCard$Failed: FC<{
  onRetry?: () => void
}> = props => (
  <LotteryTicketCardContainer
    mainArea={
      <>
        <DefaultIcon />
        <CardNormalText className={"mt-2.5 text-yellow-500"}>
          <WarnIcon className={"inline mr-1.5 align-bottom"} />
          Transaction failed
        </CardNormalText>
      </>
    }
    actionArea={<ActionButton onClick={props.onRetry}>Retry</ActionButton>}
  />
)

export const LotteryTicketCard$Won: FC<{
  token: TokenInfo
  wonTokenCount: number
  ticketExplorerLink: string
}> = props => (
  <LotteryTicketCardContainer
    bgStyle={{
      background:
        "linear-gradient(0deg, #DBC26A, #DBC26A), radial-gradient(85.31% 57.53% at 125.94% 4.69%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(225.91deg, #755930 0%, #554328 17.44%, #52422B 77.44%, #775D37 98.46%)",
      backgroundBlendMode: "overlay, overlay, normal",
    }}
    borderClassName={"border border-white border-opacity-10"}
    mainArea={
      <>
        <WonIcon />

        <p
          className={
            "text-sm leading-5 font-bold text-gray-200 text-center mt-2"
          }
        >
          WON
          <br />
          <TokenCount token={props.token} count={props.wonTokenCount} />
          &nbsp;
          <TokenName token={props.token} />
        </p>
      </>
    }
    actionArea={<ExplorerLink link={props.ticketExplorerLink} />}
  />
)

export const LotteryTicketCard$Lose: FC<{
  withdrawSTXCount: number
  ticketExplorerLink: string
}> = props => (
  <LotteryTicketCardContainer
    bgClassName={"bg-gray-500 bg-opacity-10"}
    borderClassName={"border border-gray-300 border-opacity-10"}
    mainArea={
      <>
        <LoseIcon />
        <p
          className={
            "text-sm leading-5 font-bold text-gray-200 text-center mt-2"
          }
        >
          Lose
          <br />
          <TokenCount
            token={TokenInfoPresets.STX}
            count={props.withdrawSTXCount}
          />
          &nbsp;
          <TokenName token={TokenInfoPresets.STX} />
          &nbsp; will return
        </p>
      </>
    }
    actionArea={<ExplorerLink link={props.ticketExplorerLink} />}
  />
)

export interface LotteryTicketCardProps {
  token: TokenInfo
  lotteryTicket: LotteryTicket
  onOpenTicket?: (ticket: LotteryTicket) => void
  onRetryTicket?: (ticket: LotteryTicket) => void
}

export const LotteryTicketCard: FC<LotteryTicketCardProps> = props => {
  switch (props.lotteryTicket.type) {
    case LotteryTicket.Type.Waiting:
      return (
        <LotteryTicketCard$Waiting
          onOpen={() => props.onOpenTicket?.(props.lotteryTicket)}
        />
      )
    case LotteryTicket.Type.Pending:
      return (
        <LotteryTicketCard$Pending
          ticketExplorerLink={props.lotteryTicket.explorerLink}
          telegramSubscribeLink={props.lotteryTicket.telegramSubscribeLink}
        />
      )
    case LotteryTicket.Type.TransactionFailed:
      return (
        <LotteryTicketCard$Failed
          onRetry={() => props.onRetryTicket?.(props.lotteryTicket)}
        />
      )
    case LotteryTicket.Type.Won:
      return (
        <LotteryTicketCard$Won
          token={props.token}
          wonTokenCount={props.lotteryTicket.wonTokenCount}
          ticketExplorerLink={props.lotteryTicket.explorerLink}
        />
      )
    case LotteryTicket.Type.Lose:
      return (
        <LotteryTicketCard$Lose
          withdrawSTXCount={props.lotteryTicket.withdrawSTXCount}
          ticketExplorerLink={props.lotteryTicket.explorerLink}
        />
      )
    default:
      assertNever(props.lotteryTicket)
  }
}
