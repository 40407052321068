import clsx from "clsx"
import { FC, ReactNode } from "react"
import { CountNumber } from "../../../../../components/CountNumber"
import { ProgressBar } from "../../../../../components/ProgressBar"
import { TokenCount } from "../../../../../components/TokenCount"
import { LaunchingStatus } from "../../../store/LaunchPadStore"
import { TokenProfileInfo, TokenProfileStatus } from "../../types"
import { ReactComponent as FailedIcon } from "./failed.svg"
import { ReactComponent as SucceedIcon } from "./succeed.svg"

export const IDOProgress: FC<{
  tokenProfile: TokenProfileInfo
}> = ({ tokenProfile }) => {
  const mainColorCls = getMainColorClassName(tokenProfile.status)

  return (
    <div className={"flex flex-wrap justify-between"}>
      <ProgressBar
        className={"w-full h-1.5 mb-2"}
        bgColor={"#6B7280"}
        fgColor={
          tokenProfile.status.type === LaunchingStatus.Finished &&
          !tokenProfile.status.success
            ? "#EC4899"
            : "#2DD4BF"
        }
        progress={tokenProfile.activationProgress}
      />

      <TicketCountSection
        title="Activation Threshold"
        detail={
          <>
            <TokenCount
              count={tokenProfile.activationThreshold}
              token={tokenProfile.tokenInfo}
            />{" "}
            Tickets{" "}
            <span className={"ml-2.5"}>
              {tokenProfile.status.type === LaunchingStatus.Finished &&
                (tokenProfile.status.success ? (
                  <SucceedIcon className={mainColorCls} fill={"currentColor"} />
                ) : (
                  <FailedIcon className={mainColorCls} fill={"currentColor"} />
                ))}
            </span>
          </>
        }
      />

      <TicketCountSection
        titleClassName={"text-right"}
        title="Current"
        detailClassName={clsx(
          mainColorCls,
          "text-right",
          tokenProfile.status.type === LaunchingStatus.Upcoming && "opacity-30",
        )}
        detail={
          <>
            <CountNumber>{tokenProfile.currentActivation}</CountNumber> Tickets
          </>
        }
      />
    </div>
  )
}

const getMainColorClassName = (status: TokenProfileStatus): string => {
  if (status.type === LaunchingStatus.Upcoming) {
    return "text-gray-200"
  }
  if (status.type === LaunchingStatus.Finished && !status.success) {
    return "text-pink-400"
  }
  return "text-green-300"
}

const TicketCountSection: FC<{
  className?: string
  titleClassName?: string
  title: ReactNode
  detailClassName?: string
  detail: ReactNode
}> = props => (
  <dl className={clsx(props.className)}>
    <dt
      className={clsx(
        "text-sm leading-5 font-normal text-gray-400",
        props.titleClassName,
      )}
    >
      {props.title}
    </dt>

    <dd
      className={clsx(
        "text-lg leading-7 font-medium text-gray-200 flex items-center",
        props.detailClassName,
      )}
    >
      {props.detail}
    </dd>
  </dl>
)
