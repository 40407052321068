import { FC } from "react"
import { TransactionState } from "../../utils/models/TransactionState"
import { withClassName } from "../../utils/reactHelpers/withClassName"
import { assertNever } from "../../utils/types"
import { GradientButton } from "../button/GradientButton/GradientButton"
import { CardSubTitle } from "../Card"
import { CardModalContent, CardModalContentProps } from "../CardModal/CardModal"
import { Dialog } from "../Dialog"
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator"
import { NavLink } from "../NavLink"
import { ReactComponent as BroadcastedIcon } from "./broadcasted.svg"
import { ReactComponent as ExplorerIcon } from "./explorer.svg"
import { ReactComponent as FailIcon } from "./fail.svg"
import { ReactComponent as SubscribeIcon } from "./subscribe.svg"

export const TransactionStateDialog: FC<{
  getTransactionState: () => undefined | TransactionState
  onClose?: () => void
}> = props => {
  const state = props.getTransactionState()

  if (!state || state.type === "running") return null

  return (
    <Dialog visible={true} onClose={props.onClose}>
      <TransactionStateDialogContent
        transactionState={state}
        onClose={props.onClose}
      />
    </Dialog>
  )
}

const TransactionStateDialogContent: FC<{
  transactionState: TransactionState
  onClose?: () => void
}> = props => {
  const state = props.transactionState
  switch (state.type) {
    case "running":
      return (
        <TransactionStateDialog$ConfirmTransaction onClose={props.onClose} />
      )
    case "success":
      return (
        <TransactionStateDialog$Broadcasted
          explorerLink={state.explorerLink}
          telegramSubscribeLink={state.telegramSubscribeLink}
          onClose={props.onClose}
        />
      )
    case "error":
      return (
        <TransactionStateDialog$Failed
          message={state.error}
          explorerLink={state.explorerLink}
          onClose={props.onClose}
        />
      )
    default:
      assertNever(state)
  }
}

const ModalContentContainer = withClassName(
  "items-center",
  (props: CardModalContentProps) => <CardModalContent {...props} width={480} />,
)

export const TransactionStateDialog$ConfirmTransaction: FC<{
  onClose?: () => void
}> = props => (
  <ModalContentContainer title={"Confirm Transaction"} onClose={props.onClose}>
    <div className={"flex p-6"}>
      <LoadingIndicator />
    </div>

    <CardSubTitle className={"text-center"}>
      Please confirm the transaction
      <br />
      in your wallet app...
    </CardSubTitle>
  </ModalContentContainer>
)

export const TransactionStateDialog$Broadcasted: FC<{
  explorerLink: string
  telegramSubscribeLink: string
  onClose?: () => void
}> = props => (
  <ModalContentContainer onClose={props.onClose}>
    <BroadcastedIcon />

    <div>
      <CardSubTitle>Transaction broadcasted.</CardSubTitle>
      <p className={"mt-2.5 text-sm leading-5 font-normal text-center"}>
        <ViewInExplorerLink explorerLink={props.explorerLink} />
      </p>
      <p className={"mt-2.5 text-sm leading-5 font-normal text-center"}>
        <TelegramSubscribeLink subscribeLink={props.telegramSubscribeLink} />
      </p>
    </div>

    <GradientButton
      className={"block w-full mt-[30px]"}
      onClick={props.onClose}
    >
      OK
    </GradientButton>
  </ModalContentContainer>
)

export const TransactionStateDialog$Failed: FC<{
  message?: string
  explorerLink?: string
  onClose?: () => void
}> = props => (
  <ModalContentContainer onClose={props.onClose}>
    <FailIcon />

    <div>
      <CardSubTitle>{props.message ?? "Transaction failed."}</CardSubTitle>
      {props.explorerLink != null && (
        <p
          className={
            "mt-2.5 text-sm leading-5 font-normal text-gray-100 text-center"
          }
        >
          <ViewInExplorerLink explorerLink={props.explorerLink} />
        </p>
      )}
    </div>

    <GradientButton
      className={"block w-full mt-[54px]"}
      onClick={props.onClose}
    >
      OK
    </GradientButton>
  </ModalContentContainer>
)

const ViewInExplorerLink: FC<{ explorerLink: string }> = props => (
  <NavLink
    className={"text-blue-600"}
    target={"_blank"}
    to={props.explorerLink}
  >
    <ExplorerIcon className={"inline mr-2"} />
    View in explorer
  </NavLink>
)

const TelegramSubscribeLink: FC<{ subscribeLink: string }> = props => (
  <NavLink
    className={"text-blue-600"}
    target={"_blank"}
    to={props.subscribeLink}
  >
    <SubscribeIcon className={"inline mr-2"} />
    Turn on TG Notification
  </NavLink>
)
