import objectHash from "object-hash"
import { fetchPersistCache, savePersistCache } from "./persistCache"

export type PMemoizeOption = { persistKey?: string; skipCaching?: boolean }

export default function pMemoize<
  F extends (...arguments_: readonly any[]) => Promise<any>,
>(fn: F, options: PMemoizeOption = {}): F {
  const cache = new Map<
    string,
    Promise<Awaited<ReturnType<F>>> | Awaited<ReturnType<F>>
  >()

  return async function (...args: any[]) {
    const key = objectHash(args, { ignoreUnknown: true })
    if (cache.has(key)) {
      return cache.get(key)!
    }
    const persistKey = options.persistKey
      ? `${options.persistKey}_${key}`
      : undefined
    const promise = (async () => {
      if (persistKey) {
        const cached = await fetchPersistCache(persistKey)
        if (cached !== undefined) {
          return cached
        }
      }
      try {
        const result = await fn(...args)
        if (options.skipCaching || result === skipCaching) {
          cache.delete(key)
          return result
        }
        cache.set(key, result)
        if (persistKey) {
          await savePersistCache(persistKey, result)
        }
        return result
      } catch (e) {
        cache.delete(key)
        throw e
      }
    })()
    cache.set(key, promise)
    return promise
  } as any
}

export const skipCaching = Symbol("pMemoizeSkipCache")
