import clsx from "clsx"
import * as React from "react"

export interface DividerProps {
  className?: string
}

export const Divider: React.FC<DividerProps> = props => {
  return (
    <div
      className={clsx(
        `flex clear-both w-full min-w-full border-t`,
        props.className,
      )}
      role="separator"
    />
  )
}
