import type { OperationResult } from "@urql/core/dist/types/types"
import { onEnd, pipe, Source, subscribe } from "wonka"
import { ErrorOperationResult } from "../graphqlHelpers"
import { Result } from "../Result"
import { createObservable } from "./createObservable"
import { Observable } from "./Observable"

export function fromUrqlSource<Data>(
  source: Source<OperationResult<Data> | Result<Data, ErrorOperationResult>>,
): Observable<Data>
export function fromUrqlSource<Data, TOutput>(
  source: Source<OperationResult<Data>>,
  getData: (result: OperationResult<Data> & { data: Data }) => TOutput,
): Observable<TOutput>
export function fromUrqlSource<Data, TOutput>(
  source: Source<OperationResult<Data> | Result<Data, ErrorOperationResult>>,
  getData?: (result: OperationResult<Data> & { data: Data }) => TOutput,
): Observable<Data | TOutput> {
  const _getData = getData ?? (result => result.data as Data)

  return createObservable(ob => {
    const { unsubscribe } = pipe(
      source,
      onEnd(ob.complete),
      subscribe(v => {
        if (Result.isResult(v)) {
          if (v.type === "error") {
            ob.error(v.payload)
          } else {
            ob.next(v.payload)
          }
          return
        }

        if (v.error) {
          ob.error(v.error)
        } else {
          try {
            ob.next(_getData(v as any))
          } catch (e) {
            ob.error(e)
          }
        }
      }),
    )

    return unsubscribe
  })
}
