import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

export interface MessageController {
  show(options: MessageController.ShowOptions): () => void
}
export namespace MessageController {
  export interface ShowOptions {
    /**
     * Message will be replaced by the new message which has the same key
     */
    key?: string

    message: string

    /**
     * Auto dismiss after how many ms
     */
    autoDismiss?: number
  }
}

const MessageContext = createContext<null | MessageController>(null)

type ShowOptions = MessageController.ShowOptions

interface MessageInstance extends MessageController.ShowOptions {
  id: string
}

export const MessageProvider: FC = props => {
  const [messages, setMessages] = useState<MessageInstance[]>([])

  const closedMessageIdsRef = useRef<string[]>([])

  const autoDismissTimersRef = useRef<ReturnType<typeof setTimeout>[]>([])

  const closeMsg = useCallback((msgId: string) => {
    closedMessageIdsRef.current.push(msgId)
    setMessages(msgs => msgs.filter(m => m.id !== msgId))
  }, [])

  const addMsg = useCallback(
    (opts: ShowOptions) => {
      const id = getGuid()

      setMessages(messages => {
        if (opts.key != null) {
          messages = messages.filter(m => m.key !== opts.key)
        }

        return messages.concat({ id, ...opts })
      })

      if (opts.autoDismiss != null) {
        autoDismissTimersRef.current.push(
          setTimeout(() => {
            closeMsg(id)
          }, opts.autoDismiss),
        )
      }

      return () => {
        closeMsg(id)
      }
    },
    [closeMsg],
  )

  const ctrl: MessageController = useMemo(
    () => ({
      show(opts) {
        return addMsg(opts)
      },
    }),
    [addMsg],
  )

  useEffect(
    () => () => {
      autoDismissTimersRef.current.forEach(timer => {
        clearTimeout(timer)
      })
    },
    [],
  )

  return (
    <>
      <MessageContext.Provider value={ctrl}>
        {props.children}
      </MessageContext.Provider>
      <div className="fixed z-1 bottom-2 left-1/2">
        <AnimatePresence>
          {messages.slice(-5).map((m, idx) => {
            const yOriginPos = -(100 * (idx + 1))
            const yMargin = -(idx * 10)
            const yPos = yOriginPos + yMargin
            const animateVariants = {
              leave: {
                opacity: 0,
                translateX: `-50%`,
                translateY: `${yPos + 100}%`,
              },
              close: {
                opacity: 0,
                translateX: `-50%`,
                translateY: `${yPos - 100}%`,
              },
            }

            return (
              <motion.div
                key={m.id}
                className="mt-1 absolute"
                variants={animateVariants}
                initial={{
                  opacity: 0,
                  translateX: `-50%`,
                  translateY: `${yPos - 100}%`,
                }}
                animate={{
                  opacity: 1,
                  translateX: `-50%`,
                  translateY: `${yPos}%`,
                }}
                exit={
                  closedMessageIdsRef.current.includes(m.id) ? "leave" : "close"
                }
                data-id={m.id}
              >
                <MessageItem message={m} onClose={() => closeMsg(m.id)} />
              </motion.div>
            )
          })}
        </AnimatePresence>
      </div>
    </>
  )
}

export const useMessage = (): MessageController => {
  const ctrl = useContext(MessageContext)
  if (!ctrl) {
    throw new Error("[useMessage] must be used in MessageProvider subtree")
  }
  return ctrl
}

interface MessageItemProps {
  className?: string
  message: ShowOptions
  onClose?: () => void
}
const MessageItem: FC<MessageItemProps> = props => {
  return (
    <div
      className={clsx(
        `flex items-center px-2 py-1 bg-indigo-200 rounded border border-indigo-400 text-indigo-500`,
        props.className,
      )}
    >
      <span className={"whitespace-nowrap max-w-[80vw] truncate"}>
        {props.message.message}
      </span>
      <span className="ml-1.5 text-xl cursor-pointer" onClick={props.onClose}>
        &times;
      </span>
    </div>
  )
}

const getGuid = (() => {
  let id = 0
  return (): string => {
    return "" + id++
  }
})()
