import clsx from "clsx"
import { FC } from "react"
import { TokenInfo } from "../utils/models/TokenInfo"

export interface TokenNameProps {
  className?: string
  token: TokenInfo
}

export const TokenName: FC<TokenNameProps> = props => {
  if (props.token.displayName) {
    return <span className={props.className}>{props.token.displayName}</span>
  }

  return props.token.type === "combined" ? (
    <MultiTokenName tokens={props.token.combinedTokens} />
  ) : (
    <SingleTokenName token={props.token} />
  )
}

const SingleTokenName: FC<{
  className?: string
  token: TokenInfo.SingleTokenInfo
}> = props => {
  if (props.token.displayName) {
    return <span className={props.className}>{props.token.displayName}</span>
  }

  return (
    <span className={`uppercase ${props.className ?? ""}`}>
      {props.token.name}
    </span>
  )
}

const MultiTokenName: FC<{
  className?: string
  tokens: TokenInfo.SingleTokenInfo[]
}> = props => {
  return (
    <ul className={clsx("inline-flex flex-row", props.className)}>
      {props.tokens.map((token, idx) => (
        <li
          key={idx}
          className={"before:content-[attr(data-separator)]"}
          data-separator={idx === 0 ? "" : "/"}
        >
          <SingleTokenName token={token} />
        </li>
      ))}
    </ul>
  )
}
