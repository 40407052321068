export class CancelError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = "CancelError"
  }
}

export class DisplayableError extends Error {
  constructor(public message: string, options?: ErrorConstructorOptions) {
    super(message, options)
  }
}
