import { FC, useEffect, useState } from "react"
import { GradientButton } from "../../../components/button/GradientButton/GradientButton"
import { CardModalContent } from "../../../components/CardModal/CardModal"
import { withClassName } from "../../../utils/reactHelpers/withClassName"

export const NoMoreTicketAlertModalContent: FC<{
  onConfirm?: () => void
}> = props => {
  const [countdown, setCountdown] = useState(5)
  useEffect(() => {
    if (countdown <= 0) {
      return
    }
    const timer = setTimeout(() => {
      setCountdown(c => c - 1)
    }, 1000)
    return () => clearTimeout(timer)
  }, [countdown])
  return (
    <CardModalContent
      className={"text-gray-200"}
      title={"IMPORTANT!"}
      width={480}
    >
      <p className={"mb-2"}>
        <YellowStrong>
          All winning tickets of the $ALEX IDO have been successfully
          distributed.
        </YellowStrong>
      </p>
      <p className={"mb-3"}>If you have any unopened tickets, you can:</p>
      <p>
        1) wait until block-height 46,601 when a &quot;refund&quot; button will
        allow all unopened tickets to be refunded in a single transaction;
      </p>
      <p className="text-center">or</p>
      <p className={"mb-3"}>
        2) open the tickets individually to receive the refund after every
        transaction.
      </p>
      <GradientButton
        disabled={countdown > 0}
        className={"w-full"}
        onClick={props.onConfirm}
      >
        {countdown > 0 ? countdown : "Confirm"}
      </GradientButton>
    </CardModalContent>
  )
}

// const WhiteStrong = withClassName("text-gray-200 font-bold", "span")

const YellowStrong = withClassName("text-yellow-400 font-bold", "span")
