import { defineContract } from "./contractBase"
import fixedWeightPoolContracts from "./fixedWeightPoolContracts"
import { launchPadContracts } from "./launchPadContracts"
import { liquidityMiningContracts } from "./liquidityMiningContracts"
import { stakingContracts } from "./stakingContracts"

export const alexContracts = defineContract({
  ...launchPadContracts,
  ...stakingContracts,
  ...liquidityMiningContracts,
  ...fixedWeightPoolContracts,
} as const)

export type AlexContractNames = keyof typeof alexContracts
