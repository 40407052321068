import clsx from "clsx"
import { FC, Suspense } from "react"
import { OpacityButton } from "../../../../components/button/OpacityButton"
import { Card } from "../../../../components/Card"
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator"
import { useLaunchPadStore } from "../../store/useLaunchPadStore"
import { TokenIDOSummary } from "../TokenIDOSummary"
import { TokenIDOSummaryInfo, TokenProfileInfo } from "../types"
import { ReactComponent as DownIcon } from "./down.svg"
import { Header } from "./Header"
import { IDOProgress } from "./IDOProgress/IDOProgress"

export interface TokenProfileSectionProps {
  className?: string
  tokenProfile: TokenProfileInfo
  tokenIDOSummary: TokenIDOSummaryInfo
  onScrollToTokenIntro?: () => void
}

export const TokenProfileSection: FC<TokenProfileSectionProps> = props => {
  const { tokenProfile } = props

  return (
    <Card className={clsx("flex flex-col gap-6", props.className)}>
      <Header tokenProfile={tokenProfile} />
      <IDOProgress tokenProfile={tokenProfile} />
      <TokenIDOSummary
        token={tokenProfile.tokenInfo}
        tokenIDOSummary={props.tokenIDOSummary}
      />
      <div>
        <OpacityButton
          className={"flex items-center justify-center !font-medium"}
          onClick={props.onScrollToTokenIntro}
        >
          Token introduction
          <DownIcon className={"ml-3"} fill={"currentColor"} />
        </OpacityButton>
      </div>
    </Card>
  )
}

export const WiredTokenProfileSection: FC<{
  className?: string
  onScrollToTokenIntro?: TokenProfileSectionProps["onScrollToTokenIntro"]
}> = props => {
  const store = useLaunchPadStore()

  return (
    <Suspense
      fallback={
        <Card
          className={clsx("flex items-center justify-center", props.className)}
        >
          <LoadingIndicator />
        </Card>
      }
    >
      <TokenProfileSection
        className={props.className}
        tokenProfile={store.tokenProfile}
        tokenIDOSummary={store.tokenProfile}
        onScrollToTokenIntro={props.onScrollToTokenIntro}
      />
    </Suspense>
  )
}
