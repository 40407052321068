import clsx from "clsx"
import {
  cloneElement,
  ComponentType,
  createElement,
  isValidElement,
  ReactElement,
  SVGProps,
} from "react"
import { assertNever } from "../../utils/types"

export function PressableSvg<T>(props: {
  svg: ReactElement | ComponentType<SVGProps<T>>
  svgProps?: SVGProps<T>
  state?: "idle" | "hover" | "active" | "visited"
  onClick?: () => void
}): JSX.Element {
  const { svg, state } = props

  let className = "opacity-40"
  if (props.onClick != null) {
    if (state == null) {
      className = `opacity-40 hover:opacity-100 active:opacity-40 visited:opacity-40`
    } else {
      if (state === "hover") {
        className = "opacity-100"
      } else if (
        state === "idle" ||
        state === "active" ||
        state === "visited"
      ) {
        className = "opacity-40"
      } else {
        assertNever(state)
      }
    }

    className = clsx(className, "cursor-pointer")
  }

  const events: SVGProps<T> = {}
  if (props.onClick != null) {
    events.onClick = props.onClick
  }

  if (isValidElement(svg)) {
    return cloneElement(svg, {
      ...svg.props,
      ...props.svgProps,
      ...events,
      className: clsx(svg.props.className, className),
    })
  } else {
    return createElement(svg, {
      ...props.svgProps,
      ...events,
      className,
    })
  }
}
